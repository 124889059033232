import React from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../../../modules/Auth/auth.reducer'
export const Logout = () => {
    const dispatch = useDispatch()
    dispatch(actions.login(null))
    window.location.reload()
    return (
        <div>

        </div>
    )
}