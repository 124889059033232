const formatTelephone = (phone) => {
    if (phone) {
        const x = phone.slice(3)
        if (!x) {
            return null
        }
        let res = [...x].map((d, i) => (i) % 2 === 0 ? ' ' + d : d).join('').trim()
        if (!res) {
            return null            
        } else {
            const phoneConverted = `+${phone[0]}${phone[1]} ${phone[2]} ${res}`        
            return phoneConverted
        }
    }
    return null
}
export const addSpaceInNUmber = (val) => {
    if (val) {
        const num = String(val)
        let numConverted = String(val)
        if (numConverted.includes('.')) {
            numConverted = numConverted.split('.')[0]
        }
        if (numConverted.length === 4) {
            numConverted = num[0] + ' ' + num.slice(1)
        } else if (numConverted.length === 5) {
            numConverted = num[0] + '' + num[1] + ' ' + num.slice(2)
        } else if (numConverted.length === 6) {
            numConverted = num[0] + '' + num[1] + num[2] + ' ' + num.slice(3)
        }
        if (numConverted.includes('.')) {
            numConverted = numConverted.replace('.', ',')
        }

        return numConverted
    }
    return ''
}

export default formatTelephone