import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SageTable, InlineBtn } from '../Common'
import { CreateUser } from './CreateUser'
import * as actions from '../../../modules/Sessions/sessions.actions'
import { Link } from 'react-router-dom'
class UserSessions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModel: false,
            initUser: null
        }
        props.fetchSessions()
        this.handleModel = this.handleModel.bind(this)
        this.asyncList = this.asyncList.bind(this)
    }
    asyncList() {
        this.state.showModel = false
        this.props.fetchSessions()
    }
    handleModel() {
        let prevShow = this.state.showModel
        this.setState({
            showModel: !prevShow,
            initUser: null
        })
    }
    handleEditUser(user) {
        this.setState({
            showModel: true,
            initUser: user
        })
    }
    render() {
        return (
            <div className="h-100 mx-auto mt-10" style={{ width: "90%" }}>
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        {/* <Link to="/account" className="btn me-3 btn-white shadow-lg d-flex align-items-center justify-content-center rounded-circle" style={{ width: 42, height: 42 }}>
                            <span className="svg-icon m-0">
                                <svg width="24" height="24" viewBox="0 0 171 171" >
                                    <g>
                                        <g fill="#000000">
                                            <path d="M60.5625,28.5c-1.05152,-0.0011 -2.0498,0.46239 -2.72754,1.26636l-48.52515,50.59863c-0.0093,0.00925 -0.01858,0.01853 -0.02783,0.02783l-0.22266,0.23657c-1.24012,1.31884 -1.93173,3.06029 -1.93433,4.87061c0.0026,1.81032 0.6942,3.55176 1.93433,4.87061c0.06368,0.06619 0.12863,0.13115 0.19482,0.19483l48.71997,50.82129c0.02287,0.02351 0.04607,0.04671 0.06958,0.06958c0.66799,0.66813 1.57402,1.04356 2.5188,1.0437c1.96751,0 3.5625,-1.59499 3.5625,-3.5625v-32.0625h78.375c7.87313,0 14.25,-6.37687 14.25,-14.25v-7.125v-7.125c0,-7.87313 -6.37687,-14.25 -14.25,-14.25h-78.375v-32.0625c0,-1.96751 -1.59499,-3.5625 -3.5625,-3.5625z"></path>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </Link> */}
                        <h2 className="fw-bolder text-dark mb-0">Mes Sessions</h2>
                    </div>
                    <div>
                        <strong className="pe-4 fs-6">{`Nb d'access ${this.props.user.usedAccess}/${this.props.user.totalAccess}`}</strong>
                        {/* <button type="button" onClick={this.handleModel} className="btn  btn-sm btn-white fs-5 fw-bold shadow-lg">Ajouter utilisateur</button> */}
                    </div>
                </div>
                <div className="my-4 bg-white shadow-sm rounded px-7 pt-4">
                    <SageTable
                        columns={[
                            {
                                title: global._trans("sessions", "actions"), dataIndex: "id_user", render: (id_user, row) => <InlineBtn bottuns={[
                                    { action: () => this.handleEditUser(row), icon: 'Edit', type: 'primary' }
                                ]} />
                            },
                            { title: global._trans("sessions", "firstName"), dataIndex: "nom", sorter: true },
                            { title: global._trans("sessions", "lastName"), dataIndex: "prenom", sorter: true },
                            { title: global._trans("sessions", "userName"), dataIndex: "username", sorter: true },
                            { title: global._trans('sessions', 'initPassword'), dataIndex: "init_password", render: init_password => init_password ? init_password : <span>MdP modifié</span> },
                            { title: global._trans("sessions", "email"), dataIndex: "email", sorter: true },
                            { title: global._trans("sessions", "phone"), dataIndex: "phone", sorter: true },
                        ]}
                        data={this.props.listSessions}
                        IterableProps={{
                            loading: this.props.loadingList === 0,
                            rowKey: "id_user",
                        }}
                    />
                </div>
                {this.state.showModel && <CreateUser user={this.props.user} initUser={this.state.initUser} asyncList={this.asyncList} show={this.state.showModel} createSession={this.props.createSession} updateSession={this.props.updateSession} handleModel={this.handleModel} />}
            </div>
        )
    }
}
function mapState(state) {
    const { listSessions, loadingList, errors } = state.sessions
    const { user } = state.auth
    return { listSessions, loadingList, errors, user }
}
const connectedClass = connect(mapState, actions)(UserSessions)
export { connectedClass as UserSessions }