import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listShared:null,
    loadingList:null,
    errors:null,
    shared:null
};
export const callTypesS = {
    list:"loadingList",
}
export const sharedSlice = createSlice({
    name: "shared",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            const {errors,callType}=action.payload
            state[callType] = 2
            state.errors = errors;
        },
        sharedFetched: (state, action) => {
            const { data } = action.payload
            state.loadingList=1
            state.shared=null
            state.errors=null
            state.listShared = data
        },
        startCall: (state, action) => {
            const {callType}=action.payload
            state.errors = null;
            state[callType] = 0;
        }
    }
});