import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Link, NavLink, Route, Switch } from 'react-router-dom'
// import { User, Password } from '../../../assets/icons'
import { UserInfo, PasswordInfo } from './component'
import { updatePicture } from '../../../modules/Auth/auth.crud'
import { actions } from '../../../modules/Auth/auth.reducer'
class UserProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModel: false,
            pageToshow: 1,
            initUser: null
        }
        this.updatePicture = this.updatePicture.bind(this)
    }
    updatePicture(e) {
        let data = new FormData()
        data.append('file', e.target.files[0])
        updatePicture(data)
            .then(res => {
                if (res && res.data && res.data.ok && res.data.picture) {
                    try {
                        this.props.setUser({ ...this.props.user, picture: res.data.picture })
                    } catch (e) {
                        console.log(e)
                    }
                }
            })
            .catch(({ message, errors }) => [

            ])
    }
    render() {
        return (
            <div className="h-100 mx-auto mt-10" style={{ width: "90%" }}>
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        {/* <Link to="/account" className="btn me-3 btn-white shadow d-flex align-items-center justify-content-center rounded-circle" style={{ width: 42, height: 42 }}>
                            <span className="svg-icon m-0">
                                <svg width="24" height="24" viewBox="0 0 171 171" >
                                    <g>
                                        <g fill="#000000">
                                            <path d="M60.5625,28.5c-1.05152,-0.0011 -2.0498,0.46239 -2.72754,1.26636l-48.52515,50.59863c-0.0093,0.00925 -0.01858,0.01853 -0.02783,0.02783l-0.22266,0.23657c-1.24012,1.31884 -1.93173,3.06029 -1.93433,4.87061c0.0026,1.81032 0.6942,3.55176 1.93433,4.87061c0.06368,0.06619 0.12863,0.13115 0.19482,0.19483l48.71997,50.82129c0.02287,0.02351 0.04607,0.04671 0.06958,0.06958c0.66799,0.66813 1.57402,1.04356 2.5188,1.0437c1.96751,0 3.5625,-1.59499 3.5625,-3.5625v-32.0625h78.375c7.87313,0 14.25,-6.37687 14.25,-14.25v-7.125v-7.125c0,-7.87313 -6.37687,-14.25 -14.25,-14.25h-78.375v-32.0625c0,-1.96751 -1.59499,-3.5625 -3.5625,-3.5625z"></path>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </Link> */}
                        <h2 className="fw-bolder text-dark mb-0">Infos personnelles </h2>
                    </div>
                </div>
                <div className="my-5 mx-0 d-flexs">
                    {/* <div className="bg-white d-flex flex-column align-items-center rounded shadow-sm px-4 py-3 me-5" style={{ width: 270 }}>
                        <div className="image-input image-input-outline my-5" data-kt-image-input="true">
                            <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: `url(${this.props.user.picture ? this.props.user.picture : '/media/images/blank.png'})` }}></div>
                            <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="" data-bs-original-title="Change avatar">
                                <span className="material-icons text-gray-600 fs-7">edit</span>
                                <input type="file" name="avatar" onChange={this.updatePicture} accept=".png, .jpg, .jpeg" />
                            </label>
                        </div>
                        <div className="w-100 mt-2">
                            <button onClick={() => { this.setState({ pageToshow: 1 }) }} className={`btn ${this.state.pageToshow === 1 ? 'btn-info' : 'btn-light'} d-flex align-items-center justify-content-start w-100 py-4 mb-4`}>
                                <span className="svg-icon svg-icon-1">
                                    {User}
                                </span>
                                <span className="fs-5 fw-bold">{global._trans("profile", "personalInfo")}</span>
                            </button>
                            <button onClick={() => { this.setState({ pageToshow: 2 }) }} className={`btn d-flex align-items-center justify-content-start w-100 ${this.state.pageToshow === 2 ? 'btn-info' : 'btn-light'}  py-4 mb-2`} >
                                <span className="svg-icon svg-icon-1">
                                    {Password}
                                </span>
                                <span className="fs-5 fw-bold">{global._trans("profile", "changePassword")}</span>
                            </button>
                        </div>
                    </div>
                    <div className="bg-white rounded shadow-sm px-4 py-3 flex-row-fluid">
                        {this.state.pageToshow === 1 ?
                        <>
                            <UserInfo user={this.props.user} />
                            <PasswordInfo user={this.props.user} />
                        </>
                            :
                            <PasswordInfo user={this.props.user} />
                        }
                    </div> */}
                    <UserInfo user={this.props.user} />
                    <br />
                    <hr />
                    <br />
                    <PasswordInfo user={this.props.user} />
                </div>
            </div >
        )
    }
}
function mapState(state) {
    const { user } = state.auth
    return { user }
}
const connectedClass = connect(mapState, { setUser: actions.setUser })(UserProfile)
export { connectedClass as UserProfile }