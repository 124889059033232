import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { getUserByToken } from '../../modules/Auth/auth.crud'
import { actions } from '../../modules/Auth/auth.reducer'
let sended = false
export const CheckUser = (props) => {
    const dispatch = useDispatch()
    if (!sended) {
        sended = true
        getUserByToken()
            .then(res => {
                if (res && res.data && res.data.ok) {
                    dispatch(actions.fulfillUser(res.data.user))
                } else {
                    dispatch(actions.login(null))
                }
            })
            .catch(err => {
                dispatch(actions.login(null))
            })
    }

    return (
        <div className="position-fixed h-100 w-100 d-flex justify-content-center flex-column align-items-center">
            <Spinner animation="border" variant="primary" />
            <strong className="fs-4 fw-bolder pt-4">Chargement...</strong>
        </div>
    )
}