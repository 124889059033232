export const actionTypes = {
    Login: "[Login] Action",
    Logout: "[Logout] Action",
    Register: "[Register] Action",
    UserRequested: "[Request User] Action",
    UserLoaded: "[Load User] Auth API",
    SetUser: "[Set User] Action",
}
export const authUrls = {
    LOGIN_URL: 'login',
    USER_URL: "info/me",
    UPDATE_PICTURE_URL:"user/update/picture",
    UPDATE_USER_URL:"user/update",
    UPDATE_PASSWORD_URL:"password/update",
    OTP_URL: 'otp'
}