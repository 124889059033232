import { sharedSlice,callTypesS as callTypes } from "./shared.reducer";
import * as SharedRequests from "./shared.crud";
const { actions } = sharedSlice;
export const fetchShared = QueryPramas => dispatch => {
    dispatch(actions.startCall({callType:callTypes.list}))
    return SharedRequests
        .fetchShared(QueryPramas)
        .then(res => {
            if(res && res.data){
                dispatch(actions.sharedFetched({data:res.data}))
            }else{
                dispatch(actions.catchError({errors:"fail to load shared",callType:callTypes.list}))
            }
        })
        .catch(err => {
            dispatch(actions.catchError({errors:"fail to load shared",callType:callTypes.list}))
        });
};
