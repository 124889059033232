import React from 'react'
import { Link } from 'react-router-dom'
export const ItemCard = (props) => {
    return (
        <div className="col p-5 m-3 rounded bg-white shadow-sm cursor-pointer">
            <Link to={props.action}>
                <div className="d-flex justify-content-between">
                    <span className="">
                        {props.icon}
                    </span>
                    <span className="material-icons" style={{ fontSize: "29px" }}>east</span>
                </div>
                <div className="mx-2 mt-4">
                    <h2 className="fw-bolder mb-2">{props.title}</h2>
                    <p className="fs-5">{props.description}</p>
                </div>
            </Link>
        </div>
    )
}