import React, { lazy, Suspense } from 'react'
import { Link } from 'react-router-dom'
export const InlineBtn = (props) => {
    let TagName
    return (
        <div div className="d-flex" >
            {props.bottuns.map((bottun, key) => {
                const Icon = lazy(() => import('../../../assets/icons/' + bottun.icon))
                if (bottun) {
                    TagName = Link
                    if (typeof bottun.action === 'function') {
                        TagName = 'button'
                    }
                    return <TagName role="button" type="button" {...(typeof bottun.action === 'function' ? { onClick: bottun.action } : { to: bottun.action })} key={key} className={"btn btn-icon btn-warning mx-1 btn-sm p-1 btn-hover-" + bottun.type}>
                        <span className={"svg-icon svg-icon-md svg-icon-" + bottun.type}>
                            <Suspense fallback={'...'}>
                                <Icon />
                            </Suspense>
                        </span>
                    </TagName>
                }
            })}
        </div >
    )
}