import React from "react";
import { Provider } from "react-redux";
import { Router ,Route} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "./App";
import { Translate } from './services'
import {history} from './config'
import './index.scss'
global._trans = Translate;
export default function App({ store, persistor, basename }) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={"loadding..."}>
        <Router history={history}>
          <Route render={props => (<Routes {...props} />)} />
        </Router>
      </PersistGate>
    </Provider>
  );
}