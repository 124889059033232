import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    regions:[],
    departments:[],
    errors:null,
    dataFetched:false
};
export const commonSlice = createSlice({
    name: "common",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            const {errors}=action.payload
            state.errors = errors;
        },
        localesFetched: (state, action) => {
            const { regions,departments } = action.payload
            state.regions = regions
            state.departments = departments
            state.dataFetched=true
        }
    }
});