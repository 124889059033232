import { createSlice } from "@reduxjs/toolkit";
import qs from 'qs'
const initialState = {
    laodingSitesMap:null,
    laodingDetail:null,
    listDepDetail:null,
    listSitesMap:{},
    siteAlll:null,
    detailShare:null,
    departementListed:null,
    regionstListed:null,
    sitesMapErrors:null,
    filterMap:qs.parse(window.location.search.replace('?','')),
    lang:null,
    lat:null,
    groupsList:null,
    typeEquipement:null,
    traitementFumee:null,
    modeGestion:null
};
export const callTypesP ={
    listSites:"laodingSitesMap",
    detailShare:"laodingDetail",
    departementList:"listDepDetail"
}
export const mapSlice = createSlice({
    name: "map",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            const {errors,callType}=action.payload
            if (callType) {
                state[callType] = 2;
            }
            state.sitesMapErrors = errors;
        },
        sitesFetched: (state, action) => {
            const { listSites,configs } = action.payload
            state.sitesMapErrors = null
            state.lang=configs.lang
            state.lat=configs.lat
            state.filterMap=configs.QueryParams
            state.listSitesMap = listSites.data
            state.siteAlll = listSites.siteAlll
            state.laodingSitesMap=1
        },
        detailFetched:(state, action) => {
            const { detail, share } = action.payload
            state.sitesMapErrors = null
            state.detailShare = detail
            state.share = share
            state.laodingDetail=1
        },
        departementListed:(state, action) => {
            const { detail } = action.payload
            state.sitesMapErrors = null
            state.regionstListed = detail.listReg
            state.departementListed = detail.listDep
            // state.share = share
            state.listDepDetail=1
        },
        groupsListed:(state, action) => {
            state.groupsList = action.payload.groupsList
            state.traitementFumee = action.payload.traitementFumee
            state.typeEquipement = action.payload.typeEquipement
            state.modeGestion = action.payload.modeGestion
            state.amobe = action.payload.amobe
        },
        startCall: (state, action) => {
            const {callType}=action.payload
            state.sitesMapErrors = null;
            if (callType) {
                state[callType] = 0;
            }
        },
    }
});