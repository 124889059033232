import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateUser } from '../../../../modules/Auth/auth.crud'
import { actions } from '../../../../modules/Auth/auth.reducer'
import { Spinner } from 'react-bootstrap'
export const UserInfo = (props) => {
    const dispatch = useDispatch();
    const [state, setState] = useState(props.user || {})
    const [errors, setErrors] = useState({})
    const [loadingCreate, setLoading] = useState(null)
    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(0);
        updateUser(state)
            .then(res => {
                if (res && res.data && res.data.ok && res.data.data === "async") {
                    dispatch(actions.setUser(state))
                    setLoading(1);
                } else {
                    setLoading(2);
                }
            })
            .catch(({ message, errors }) => {
                setLoading(2)
                setErrors(errors)
            })
    }
    return (
        <div className="px-3 position-relative">
            {(loadingCreate === 0) && <div className="position-absolute t-0 l-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: 10, background: "#ffffff40" }}>
                <Spinner animation="border" variant="primary" />
            </div>}
            <div className="py-3">
                {/* <div className="align-items-start flex-column py-2">
                    <h3 className="fw-bolder text-dark m-0">Modifier Votre Information</h3>
                    <span className="text-muted fs-md mt-1">Vous pouvez visualiser votre informations personnelles et appliquer des modifications, dans le cadre ci-dessous.</span>
                </div> */}
            </div>
            <div className="separator separator-solid"></div>
            <div className="form my-5 py-3">
                {(loadingCreate === 1) && <div className="alert alert-success" role="alert">
                    <span className="fs-4 fw-bolder">Information modifier avec success</span>
                </div>}
                <form method="post" onSubmit={handleSubmit}>
                    <div className="row mb-6">
                        <div className="form-group col">
                            <label className="fs-5 fw-bold mb-1">{global._trans("sessions", "firstName")}</label>
                            <input className="form-control form-control-lg form-control-solid" type="text" value={state.nom} onChange={handleChange} name="nom" placeholder={global._trans("sessions", "firstName")} />
                            {(loadingCreate === 2 && errors) && <span className="form-text fs-6 ps-2 d-block text-danger">{errors.nom}</span>}
                        </div>
                        <div className="form-group col">
                            <label className="fs-5 fw-bold mb-1">{global._trans("sessions", "lastName")}</label>
                            <input className="form-control form-control-lg form-control-solid" type="text" value={state.prenom} onChange={handleChange} name="prenom" placeholder={global._trans("sessions", "lastName")} />
                            {(loadingCreate === 2 && errors) && <span className="form-text fs-6 ps-2 d-block text-danger">{errors.prenom}</span>}
                        </div>
                    </div>
                    <div className="row mb-6">
                        <div className="form-group col">
                            <label className="fs-5 fw-bold mb-1">{global._trans("sessions", "userName")}</label>
                            <input className="form-control form-control-lg form-control-solid" type="text" value={state.username} onChange={handleChange} name="username" placeholder={global._trans("sessions", "userName")} />
                            {(loadingCreate === 2 && errors) && <span className="form-text fs-6 ps-2 d-block text-danger">{errors.username}</span>}
                        </div>
                        <div className="form-group col">
                            <label className="fs-5 fw-bold mb-1">{global._trans("sessions", "email")}</label>
                            <input className="form-control form-control-lg form-control-solid" type="text" value={state.email} onChange={handleChange} name="email" placeholder={global._trans("sessions", "email")} />
                            {(loadingCreate === 2 && errors) && <span className="form-text fs-6 ps-2 d-block text-danger">{errors.email}</span>}
                        </div>
                    </div>
                    <div className="row mb-6">
                        <div className="form-group col">
                            <label className="fs-5 fw-bold mb-1">{global._trans("sessions", "phone")}</label>
                            <input className="form-control form-control-lg form-control-solid" type="text" value={state.phone} onChange={handleChange} name="phone" placeholder={global._trans("sessions", "phone")} />
                            {(loadingCreate === 2 && errors) && <span className="form-text fs-6 ps-2 d-block text-danger">{errors.phone}</span>}
                        </div>
                    </div>
                    <div className="d-flex justify-content-start pr-0">
                        <button className="btn btn-primary fw-bold">Enregistrer</button>
                    </div>
                </form>
            </div>
        </div>

    )
}