import React from 'react'
// import { Svg } from '../Common'
// import {Svg,TagsList} from '../Common'
import { useSelector } from 'react-redux'
// // import DateRangePicker from 'react-bootstrap-daterangepicker';
// import moment from 'moment'
import { RegionSearch } from '../Common';
import closeBtn from '../../../assets/icons/closeRed.png'

export const FiltersContainer = (props) => {
    // const { departments, regions } = useSelector((state) => state.common);
    return (
        <form id="filter_global_map" onSubmit={props.handleSubmit}>
            <div onClick={props.closeFilter} className="position-absolute fw-bolder cursor-pointer p-1 border border-2 rounded bg-light hoverable me-2" style={{ right: 20, marginTop: 10 }}>
                {/* <Svg name="Close" style={{ width: 25, height: "100%", color: 'red'}} /> */}
                <img src={closeBtn} alt="close pic" width='20' />
            </div>
            <div className="position-absolute fw-bolder cursor-pointer py-2 px-4 rounded bg-success bg-opacity-20 hoverable me-2" style={{ left: 20, marginTop: 10 }}>                
            <h6 className='m-0'>Paramètres des filtres</h6>
            </div>
            {/* <div className="separator separator-dashed m-0"></div> */}
            <div className="p-4 mx-auto" style={{ width: "100%" }}>
                <hr style={{'marginRight': '40px', 'marginBottom': '40px', 'backgroundColor': '#277cc1','marginLeft': '190px'}}/>
                <div className="row g-8 mb-8">
                    <div className="col-12">
                        <div className="row g-8">
                            <div className="col-md-2">
                                <label className="fs-6 form-label fw-bolder text-dark">{global._trans("header", "searchFields")}</label>
                                <select onChange={props.handleChange} name="field" defaultValue={props.filters.field} className="form-select form-select-solid">
                                    <option value="denomination">Dénomination</option>
                                    <option value="adresse">Adresse site</option>
                                    <option value="telephoneStandrad">Telephone standard</option>
                                    <option value="siteIntrnet">Site internet</option>
                                    <option value="client">Client</option>
                                    <option value="exploitant">Exploitant</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <label className="fs-6 form-label fw-bolder text-dark">{global._trans("fields", "categorieSite")}</label>
                                <select onChange={props.handleChange} name="categorie" defaultValue={props.filters.categorie} className="form-select form-select-solid">
                                    <option value="all">Tout Les Catégories</option>
                                    <option value="UVE">UVE</option>
                                    <option value="TRI">TRI</option>
                                    <option value="TMB">TMB</option>
                                    <option value="ISDND">ISDND</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <label className="fs-6 form-label fw-bolder text-dark">{global._trans("fields", "modeGestion")}</label>
                                <select onChange={props.handleChange} name="modeGest" defaultValue={props.filters.modeGest} className="form-select form-select-solid">
                                    <option value="all">Tout Les Modes</option>
                                    <option value="Gestion privée">Gestion privée</option>
                                    <option value="Prestation de service">Prestation de service</option>
                                    <option value="Regie">Regie</option>
                                    <option value="DSP">DSP</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <RegionSearch placeholder='Département' onChange={props.handleChange} value={props.filters.department} type='dep' />
                            </div>
                            <div className="col-md-2">
                                <RegionSearch placeholder='Région' onChange={props.handleChange} value={props.filters.region} type='reg' />
                            </div>
                            <div className="d-flex justify-content-center align-items-center flex-column mt-8 col-md-2">
                                <button type="submit" className="btn fw-bolder btn-primary mb-4 py-2">{global._trans("header", "actionsSearch")}</button>
                                <button type="button" onClick={props.handleReset} className="btn py-2 fw-bold btn-danger">{global._trans("header", "resetSearch")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    )
}