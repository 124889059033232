import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import './dist/ag-grid.css';
import './dist/ag-theme-balham.css';
import './dist/ag-grid-styles.css';

class AgGrid extends Component {
    constructor(props) {
        super(props)
        this.state = {
            defaultColDef: {
                resizable: true,
                filter: true,
                sortable: true
            },
        }
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    
        this.gridApi.sizeColumnsToFit({});
    };
    getRowStyle = (params) => {
        return {
          display: 'flex',
          alignItems: 'center',
        };
    }
    render() {
        return (
            <div className="ag-theme-balham" style={{height: 650, width: "100%"}}>
                <AgGridReact
                    rowData={this.props.rows || []}
                    columnDefs={this.props.columns || []}
                    onGridReady={this.onGridReady}
                    defaultColDef={this.state.defaultColDef}
                    pagination={true}
                    paginationAutoPageSize={true}
                    rowHeight={40}
                    getRowStyle={this.props.getRowStyle ? this.props.getRowStyle : this.getRowStyle}>
                </AgGridReact>
            </div>
        )
    }
}
export { AgGrid }