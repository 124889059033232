export const Map =(
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 171 171">
        <g>
            <g>
                <path
                    d="M142.5,153.1875h-64.125v-128.25h64.125c3.93656,0 7.125,3.18844 7.125,7.125v114c0,3.93656 -3.18844,7.125 -7.125,7.125z"
                    fill="#35c1f1"></path>
                <path
                    d="M51.46744,11.4855c-0.50231,-0.25294 -1.04737,-0.37763 -1.59244,-0.37763v128.25c0.54506,0 1.09013,0.12469 1.59244,0.37762l26.90756,13.452v-128.25z"
                    fill="#199be2"></path>
                <path
                    d="M48.28256,11.4855l-22.96744,11.48194c-2.41537,1.20769 -3.94012,3.6765 -3.94012,6.37331v118.08263c0,2.64694 2.78588,4.37119 5.15494,3.18487l21.75263,-10.87631c0.50231,-0.24937 1.04738,-0.37762 1.59244,-0.37762v-128.25c-0.54506,0.00356 -1.09013,0.12825 -1.59244,0.38119z"
                    fill="#50e6ff"></path>
            </g>
        </g>
    </svg>
)
