// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-theme-balham {
    font-size: 14px !important;
}

.ag-theme-balham .ag-header {
    font-size: 14px !important;
}

.ag-cell-with-height {
    height: initial
}`, "",{"version":3,"sources":["webpack://./src/App/Pages/Common/dist/ag-grid-styles.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI;AACJ","sourcesContent":[".ag-theme-balham {\n    font-size: 14px !important;\n}\n\n.ag-theme-balham .ag-header {\n    font-size: 14px !important;\n}\n\n.ag-cell-with-height {\n    height: initial\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
