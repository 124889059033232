export const loadLMaps = (callback) => {
    const existingScript = document.getElementById('mapboxapi');
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://api.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.js';
        script.id = 'mapboxapi';
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) callback();
        };
    }
    if (existingScript && callback) callback();
};