import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listSessions:null,
    loadingList:null,
    loadingCreate:null,
    errors:null,
    session:null
};
export const callTypes={
    list:"loadingList",
    create:"loadingCreate"
}
export const sessionsSlice = createSlice({
    name: "sessions",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            const {errors,callType}=action.payload
            state[callType] = 2
            state.errors = errors;
        },
        sessionsFetched: (state, action) => {
            const { listSessions } = action.payload
            state.loadingList=1
            state.session=null
            state.errors=null
            state.loadingCreate=null
            state.listSessions = listSessions
        },
        sessionsCreated: (state, action) => {
            const { session } = action.payload
            state.loadingCreate=1
            state.session = session
        },
        startCall: (state, action) => {
            const {callType}=action.payload
            state.errors = null;
            state[callType] = 0;
        }
    }
});