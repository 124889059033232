
import React, { Component } from 'react';
import { sendEmail } from '../../../modules/Common/common.crud';
import { ToastContainer, toast } from 'react-toastify';

class ContactPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            object: null,
            email: null,
            message: null
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleChange(e) {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        if (!this.state.loading) {
            this.setState({
                loading: true
            });
            sendEmail({ object: this.state.object, email: this.state.email, message: this.state.message })
            .then(res => {
                if (res.data?.success) {
                    this.setState({
                        loading: false,
                        object: '',
                        email: '',
                        message: '',
                    });
                    toast.success(global._trans("contact", "Your request has been sent. We will get back to you as soon as possible."), {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    throw new Error();
                }
            })
            .catch(err => {
                this.setState({
                    loading: false
                });
                toast.error(global._trans("contact", "Failed to send the message, please try again"), {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
        }
    }
    render() {
        return (
            <>
                <div className="w-100 h-100 d-flex justify-content-between align-items-center" style={{ backgroundColor: 'white' }}>
                    <div className="loginLeft">
                        <img alt="image_login" src="/media/images/sage2.png" className='sage2Logo' />
                        <img alt="image_login" src="/media/images/sage3.png" className='sage1Logo' />
                    </div>
                    <div className="loginRight" style={{ zIndex: 10, flex: 1 }}>
                        <div className="d-flex flex-column px-5">
                            <form className="form mt-3 header_shadow user-select-none" onSubmit={this.handleSubmit}>
                                <br />
                                <a href="/" style={{display: 'inline-block'}}>
                                    <div className="d-flex gap-2 align-items-center ms-4 mb-4 fs-5 form-label fw-bold" style={{fontWeight: '400', color: '#3699ff', cursor: 'pointer', transition: 'all 0.3s'}}>
                                        <span className="material-icons">west</span>
                                        <span>{global._trans("contact", "To home page")}</span>
                                    </div>
                                </a>
                                <br />
                                <h1 className="mt-2 mb-8 fw-bolder ms-4">{global._trans("contact", "Contact us")}</h1>
                                <div className="form-group mb-7 col mt-6">
                                    <label className="fs-5 form-label fw-bold">{global._trans("contact", "Object")}</label>
                                    <select name="object" defaultValue={""} value={this.state.object} className={`form-control form-control-lg form-control-solid fw-normal border`} required onChange={this.handleChange}>
                                        <option value="" disabled hidden>{global._trans("contact", "Select an object")}</option>
                                        <option value="corrections">{global._trans("contact", "Informations to correct")}</option>
                                        <option value="problems">{global._trans("contact", "Problems")}</option>
                                        <option value="suggestions">{global._trans("contact", "Suggestions")}</option>
                                        <option value="others">{global._trans("contact", "Others")}</option>
                                    </select>
                                </div>
                                <div className="form-group mb-7 col mt-6">
                                    <label className="fs-5 form-label fw-bold">{global._trans("contact", "Email address")}</label>
                                    <input className={`form-control form-control-lg form-control-solid fw-normal border ${!this.state.errors ? 'border-secondary' : 'border-danger'} `} type="email" onChange={this.handleChange} name="email" required value={this.state.email} placeholder={global._trans("contact", "Email address")} />
                                </div>
                                <div className="form-group mb-7 col mt-6">
                                    <label className="fs-5 form-label fw-bold">{global._trans("contact", "Message")}</label>
                                    <textarea rows={4} className={`form-control form-control-lg form-control-solid fw-normal border ${!this.state.errors ? 'border-secondary' : 'border-danger'} `} onChange={this.handleChange} name="message" required value={this.state.message} placeholder={global._trans("contact", "Message")}></textarea>
                                </div>
                                <div className="form-group mb-8 col text-center">
                                    <button type="submit" className="btn px-20 fs-6 fw-bolder btn-primary" disabled={this.state.loading}>
                                        {this.state.loading ? global._trans("contact", "Sending...") : global._trans("contact", "Send")}
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                    <ToastContainer
                        position="top-right"
                        autoClose={4000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                    />
                </div>
            </>
        )
    }
}
export { ContactPage }