import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AgGrid } from '../Common'
import * as actions from '../../../modules/Shared/shared.actions'
import { Link } from 'react-router-dom'
class SharedDataList extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            showModel: false,
            initUser: null,
            selectedSite:this.props.listShared?.selected,
            columnDefs: [
                {headerName: global._trans("shared", "field_name"), field: 'name', valueGetter: (params) => global._trans("fields", params.data.name)},
                {headerName: global._trans('shared', 'field_category'), field: 'category'},
                {headerName: global._trans('shared', 'shared'), field: 'is_shared', valueGetter: params => params.data.is_shared ? "Oui" : "Non" /*, cellRendererFramework: (params) => params.data.is_shared ? "Oui" : <span style={{color: "red"}}>Non</span>*/},
            ]
        }
        props.fetchShared()
            .then(res => {
                this.setState({
                    loading: false,
                })
            })
            .catch(err => {
                this.setState({
                    loading: false,
                })
            })
        this.handleModel = this.handleModel.bind(this)
        this.handleChangeSite = this.handleChangeSite.bind(this)
    }
    
    handleModel() {
        this.setState({
            loading: true,
        })
        this.props.fetchShared({id:this.state.selectedSite})
            .then(res => {
                this.setState({
                    loading: false,
                })
            })
            .catch(err => {
                this.setState({
                    loading: false,
                })
            })
    }
    handleChangeSite(e) {
        this.state.selectedSite = e.target.value
    }
    getRowStyle(params) {
        if (params.data.is_shared)
            return { display: 'flex', alignItems: 'center' };
        else
            return { display: 'flex', alignItems: 'center', background: '#f2dddd' };
    }
    render() {
        return (
            <div className="h-100 mx-auto mt-10" style={{ width: "90%" }}>
                <div className="d-flex justify-content-between flex-wrap">
                    <div className="d-flex align-items-center">
                        {/* <Link to="/account" className="btn me-3 btn-white shadow-lg d-flex align-items-center justify-content-center rounded-circle" style={{ width: 42, height: 42 }}>
                            <span className="svg-icon m-0">
                                <svg width="24" height="24" viewBox="0 0 171 171" >
                                    <g>
                                        <g fill="#000000">
                                            <path d="M60.5625,28.5c-1.05152,-0.0011 -2.0498,0.46239 -2.72754,1.26636l-48.52515,50.59863c-0.0093,0.00925 -0.01858,0.01853 -0.02783,0.02783l-0.22266,0.23657c-1.24012,1.31884 -1.93173,3.06029 -1.93433,4.87061c0.0026,1.81032 0.6942,3.55176 1.93433,4.87061c0.06368,0.06619 0.12863,0.13115 0.19482,0.19483l48.71997,50.82129c0.02287,0.02351 0.04607,0.04671 0.06958,0.06958c0.66799,0.66813 1.57402,1.04356 2.5188,1.0437c1.96751,0 3.5625,-1.59499 3.5625,-3.5625v-32.0625h78.375c7.87313,0 14.25,-6.37687 14.25,-14.25v-7.125v-7.125c0,-7.87313 -6.37687,-14.25 -14.25,-14.25h-78.375v-32.0625c0,-1.96751 -1.59499,-3.5625 -3.5625,-3.5625z"></path>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </Link> */}
                        <h2 className="fw-bolder text-dark mb-0">Données partagées</h2>
                    </div>
                    {this.props.listShared?.sites && Object.keys(this.props.listShared?.sites).length > 0 &&
                    <div className="d-flex flex-row" style={{gap: "10px"}}>
                        <select onChange={this.handleChangeSite} name={'site'} defaultValue={''} className="form-select form-select-solid ">
                            {this.props.listShared?.sites && Object.values(this.props.listShared.sites).map((el) =>{
                                return <option selected={this.props.listShared.selected === el.value} value={el.value} key={el.value}>{el.label}</option>
                            })}
                        </select>
                        <button type="button" onClick={this.handleModel} className="btn btn-sm btn-dark fs-7  fw-bold shadow-lg">Changer</button>
                    </div>}
                </div>
                <div className="my-4 bg-white shadow-sm rounded px-7 py-5">
                    {this.state.loading && <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Chargement...</span>
                        </div>
                    </div>}
                    {!this.state.loading && 
                        <div className="card-body py-4" style={{ minHeight: "200px", width: '100%' }}>
                            <AgGrid rows={this.props.listShared?.shared ? this.props.listShared.shared : []} columns={this.state.columnDefs} getRowStyle={this.getRowStyle} />
                        </div>
                    }
                </div>
            </div>
        )
    }
}
function mapState(state) {
    const { listShared, loadingList, errors } = state.shared
    const { user } = state.auth
    return { listShared, loadingList, errors, user }
}
const connectedClass = connect(mapState, actions)(SharedDataList)
export { connectedClass as SharedDataList }