import React, { Component } from 'react';
import { connect } from 'react-redux'
import { actions } from '../../../modules/Auth/auth.reducer'
import { login, otp } from '../../../modules/Auth/auth.crud'
import "../../../assets/sass/pages/login.sass"
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
class LoginPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: "",
            password: "",
            loading: false,
            Email: "",
            // usernameForPasswordForgotten: "",
            forgattenPassword: false,
            showPassword: false,
            showPasswordLoading: false,
            forgattenPasswordError: '',
            errors: false,
            otpShow: false,
            otpEmail: '',
            otpId: '',
            otpCode: '',
            otpError: '',
            otpLoading: false,
            otpResent: false,
            otpResentLoading: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleForgettenPassword = this.handleForgettenPassword.bind(this)
        this.handleOtp = this.handleOtp.bind(this)
        this.handleResend = this.handleResend.bind(this)
    }
    handleChange(e) {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        if (!this.state.loading) {
            this.setState({
                loading: true,
                errors: false
            })
            login({ username: this.state.username, password: this.state.password })
                .then(res => {
                    if (res && res.data && res.data.ok && res.data.otp) {
                        this.setState({
                            otpEmail: res.data.email,
                            otpId: res.data.id,
                            otpShow: true,
                            loading: false,
                            errors: false
                        })
                    } else if (res && res.data && res.data.ok && (res.data.typeuser === "UserPremieume" || res.data.typeuser === "UserSimple")) {
                        this.setState({
                            loading: false
                        })
                        this.props.login(res.data.token)
                        window.location.reload()
                    } else {
                        this.setState({
                            loading: false,
                            errors: true
                        })
                    }
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        errors: true
                    })
                })
        }
    }
    async handleForgettenPassword() {
        if (this.state.Email) {
            this.setState({ showPasswordLoading: true })
            const URL_ROOT = process.env.REACT_APP_HOST_API
            const url = 'users/send-email-user'
            const dtToSend = { email: this.state.Email }
            await axios.post(`${URL_ROOT}${url}`, dtToSend)
                .then(res => {
                    this.setState({
                        Email: "",
                        forgattenPassword: false,
                        showPasswordLoading: false,
                        forgattenPasswordError: '',
                    })
                    toast.success("L'email envoyé avec succès", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                })
                .catch(err => {
                    this.setState({
                        showPasswordLoading: false,
                        forgattenPasswordError: err.message,
                    })
                })
        }
    }
    handleOtp() {
        if (this.state.otpCode) {
            this.setState({ otpLoading: true, otpError: '' })
            otp({ id: this.state.otpId, otp_code: this.state.otpCode })
                .then(res => {
                    if (res && res.data && res.data.ok && (res.data.typeuser === "UserPremieume" || res.data.typeuser === "UserSimple")) {
                        this.props.login(res.data.token)
                        window.location.reload()
                    } else {
                        this.setState({
                            otpLoading: false,
                            otpError: 'Le code de vérification est incorrect ou expiré'
                        })
                    }
                })
                .catch(err => {
                    this.setState({
                        otpLoading: false,
                        otpError: err?.message == 'Too Many Attempts.' ? 'Trop de tentatives, veuillez réessayer après quelques secondes.' : 'Une erreur s\'est produite, veuillez réessayer'
                    })
                })
        }
    }
    handleResend() {
        this.setState({
            otpResentLoading: true
        });
        login({ username: this.state.username, password: this.state.password })
            .then(res => {
                if (res && res.data && res.data.ok && res.data.otp) {
                    this.setState({
                        otpEmail: res.data.email,
                        otpId: res.data.id,
                        otpCode: '',
                        otpError: '',
                        otpResent: true
                    })
                } else {
                    this.setState({
                        otpError: 'Une erreur s\'est produite, veuillez réessayer',
                        otpResentLoading: false
                    })
                }
            })
            .catch(err => {
                this.setState({
                    otpError: 'Une erreur s\'est produite, veuillez réessayer',
                    otpResentLoading: false
                })
            })
    }
    render() {
        return (
            <>
                <div className="w-100 h-100 d-flex justify-content-between align-items-center" style={{ backgroundColor: 'white' }}>
                    <div className="loginLeft">
                        <img alt="image_login" src="/media/images/sage2.png" className='sage2Logo' />
                        <img alt="image_login" src="/media/images/sage3.png" className='sage1Logo' />
                    </div>
                    <div className="loginRight" style={{ zIndex: 10, flex: 1 }}>
                        <div className="d-flex flex-column">
                            <form className="form mt-3 header_shadow user-select-none" onSubmit={this.handleSubmit}>
                                <h1 className="mt-2 mb-10 fw-bolder ms-4" style={{fontSize: 24, fontWeight: 900}}>Connexion à RINA</h1>
                                <div className="form-group mb-7 col mt-6">
                                    <label className="fs-5 form-label fw-bold">{global._trans("login", "username")}</label>
                                    <input className={`form-control form-control-lg form-control-solid fw-normal border ${!this.state.errors ? 'border-secondary' : 'border-danger'} `} type="text" onChange={this.handleChange} name="username" required value={this.state.username} placeholder={global._trans("login", "username")} />
                                </div>
                                <div className="form-group mb-6 col position-relative">
                                    <label className="fs-5 form-label fw-bold">Mot de Passe</label>
                                    <h2 className='passwordForgetten' onClick={() => {
                                        this.setState({ forgattenPassword: true })
                                    }}>Mot de passe oublié ?</h2>
                                    <div className={`inputPassowrdLoGIN border ${!this.state.errors ? 'border-secondary' : 'border-danger'}`}>
                                        <input className={`mr-3 form-control form-control-lg form-control-solid fw-normal `}
                                            type={`${this.state.showPassword ? 'text' : 'password'}`} onChange={this.handleChange} name="password" required value={this.state.password} placeholder='Mot de Passe' />
                                        {this.state.showPassword ?
                                            <BsEyeSlash size={23} onClick={() => { this.setState({ showPassword: false }) }} />
                                            :
                                            <BsEye size={23} onClick={() => { this.setState({ showPassword: true }) }} />
                                        }
                                    </div>
                                </div>
                                <div className='my-6 text-center'>
                                    {this.state.errors && <strong className="text-danger fs-6">{global._trans("login", "checkYourInfo")}</strong>}
                                </div>
                                <div className="form-group mb-8 col text-center">
                                    <button type="submit" className="btn px-20 fs-6 fw-bolder btn-primary">
                                        {this.state.loading ? "Chargement..." : "Connexion"}
                                    </button>
                                </div>
                                <h4 className="my-5 ms-4 noAccount">Vous n’avez pas de compte ? <a href='/contact/' target='_black' className='contactUs'>Contactez-nous</a></h4>
                            </form>

                        </div>
                    </div>
                    <ToastContainer
                        position="top-right"
                        autoClose={4000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                    />
                    {this.state.forgattenPassword && (
                        <div className='modalForgattenPassword'>
                            <div className='content'>
                                <h1 className='py-3'>Mot de passe oublié</h1>
                                <hr />
                                <br />
                                {/* <div className="form-group col">
                                    <label className="fs-5 form-label fw-bold">{global._trans("login", "username")}</label>
                                    <input className={`form-control form-control-lg form-control-solid fw-normal border border-secondary' `} type="text"
                                        onChange={(e) => {
                                            this.setState({ usernameForPasswordForgotten: e.target.value })
                                        }} name="username" required value={this.state.usernameForPasswordForgotten} placeholder={global._trans("login", "username")} />
                                </div> */}
                                <div className="form-group col">
                                    <label className="fs-5 form-label fw-bold">Email</label>
                                    <input className={`form-control form-control-lg form-control-solid fw-normal border border-secondary'`} type="email"
                                        onChange={(e) => {
                                            this.setState({ Email: e.target.value })
                                        }} name="emailFP" required value={this.state.Email} placeholder='Email' />
                                    <p className='mt-6 ms-1'>Un mail sera envoyé à cette adresse email : <strong>{this.state.Email}</strong></p>
                                    {this.state.forgattenPasswordError && (
                                        <div className='my-5 text-white bg-danger text-center rounded py-3'>
                                            {this.state.forgattenPasswordError}
                                        </div>
                                    )}
                                </div>
                                <br />
                                <hr />
                                <div className="form-group mb-1 col text-center pt-2 d-flex justify-content-center align-items-center">
                                    <button type="button" className="btn px-8 fs-6 me-8 fw-bolder btn-danger"
                                        onClick={() => {
                                            this.setState({ forgattenPassword: false, Email: '', forgattenPasswordError: "" })
                                        }}> Annuler </button>
                                    <button type="button"
                                        onClick={this.handleForgettenPassword}
                                        disabled={!this.state.Email || this.state.showPasswordLoading}
                                        className="btn px-8 fs-6 fw-bolder btn-success d-flex justify-content-between align-items-center">
                                        {this.state.showPasswordLoading ?
                                            <>
                                                <AiOutlineLoading3Quarters size={24} className='me-4 loadingAnimation' />
                                                <span>Envoi en cours</span>
                                            </>
                                            :
                                            'Envoyer'
                                        }
                                    </button>
                                </div>

                            </div>
                        </div>
                    )}
                    {this.state.otpShow && (
                        <div className='modalForgattenPassword'>
                            <div className='content'>
                                <h1 className='py-3'>Vérification requise</h1>
                                <hr />
                                <br />
                                <div className="form-group col">
                                    <label className="fs-5 form-label fw-bold">Code de vérification</label>
                                    <input className={`form-control form-control-lg form-control-solid fw-normal border border-secondary'`} type="number"
                                         name="otpCode" required onChange={this.handleChange} placeholder='Veuillez saisir le code de vérification' />
                                    {this.state.otpResent ?
                                        <p className='mt-6 ms-1'>Le code de vérification a été renvoyé à cette adresse email : <strong>{this.state.otpEmail}</strong></p>
                                        :
                                        this.state.otpResentLoading ?
                                        <p className='mt-6 ms-1'>Renvoi du code de vérification en cours...</p>
                                        :
                                        <p className='mt-6 ms-1'>Un code de vérification a été envoyé à cette adresse email : <strong>{this.state.otpEmail}</strong><br/>Si vous ne l'avez pas reçu, <a disabled={this.state.otpResentLoading} style={{fontWeight: '400', color: '#3699ff', cursor: 'pointer', transition: 'all 0.3s'}} onClick={this.handleResend}>cliquez ici pour le renvoyer.</a></p>
                                    }
                                    {(this.state.otpError && (
                                        <div className='text-danger text-center rounded' style={{fontSize: '14px', fontWeight: '500'}}>
                                            {this.state.otpError}
                                        </div>
                                    )) || <br/>}
                                </div>
                                <hr />
                                <div className="form-group mb-1 col text-center pt-2 d-flex justify-content-center align-items-center">
                                    <button type="button" className="btn px-8 fs-6 me-8 fw-bolder btn-danger"
                                        onClick={() => {
                                            this.setState({ otpShow: false, otpId: '', otpEmail: '', otpCode: '', otpError: '' })
                                        }}> Annuler </button>
                                    <button type="button"
                                        onClick={this.handleOtp}
                                        disabled={!this.state.otpCode || this.state.otpLoading}
                                        className="btn px-8 fs-6 fw-bolder btn-success d-flex justify-content-between align-items-center">
                                        {this.state.otpLoading ?
                                            <>
                                                <AiOutlineLoading3Quarters size={24} className='me-4 loadingAnimation' />
                                                <span>Validation en cours</span>
                                            </>
                                            :
                                            'Valider'
                                        }
                                    </button>
                                </div>

                            </div>
                        </div>
                    )}
                </div>
            </>
        )
    }
}
const mapDispatchProps = (dispatch) => {
    return {
        login: (token) => dispatch(actions.login(token))
    }
}
const connectedClass = connect(null, mapDispatchProps)(LoginPage)
export { connectedClass as LoginPage }