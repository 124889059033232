import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Link, NavLink, Route, Switch } from 'react-router-dom'
// import { User, Password } from '../../../assets/icons'
// import { UserInfo, PasswordInfo } from './component'
import { updatePicture } from '../../../modules/Auth/auth.crud'
import { actions } from '../../../modules/Auth/auth.reducer'
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { GrMapLocation } from 'react-icons/gr';
import { Tabs } from 'antd';
import { UserProfile } from './UserProfile'
import { UserSessions } from '../UserSessions'
import { SharedDataList } from '../SharedDataList'
import { CreateUser } from '../UserSessions/CreateUser'
import * as actionsUser from '../../../modules/Sessions/sessions.actions'
// import { createSession } from '../../../modules/Sessions/sessions.actions'



console.log(' 🚀  => actionsUser:', actionsUser)
class SageUserProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModel: false,
            initUser: null
        }
        this.items = [
            {
                key: '1',
                label: `Infos personnelles`,
                children: <UserProfile />,
            },
            {
                key: '2',
                label: `Mes Sessions`,
                children: <UserSessions />,
            },
            {
                key: '3',
                label: `Données partagées`,
                children: <SharedDataList />,
            },
        ];
        this.asyncList = this.asyncList.bind(this)
        this.updatePicture = this.updatePicture.bind(this)
        this.handleModel = this.handleModel.bind(this)
        // props.fetchSessions()

    }
    asyncList() {
        this.state.showModel = false
        this.props.fetchSessions()
    }
    updatePicture(e) {
        let data = new FormData()
        data.append('file', e.target.files[0])
        updatePicture(data)
            .then(res => {
                if (res && res.data && res.data.ok && res.data.picture) {
                    try {
                        this.props.setUser({ ...this.props.user, picture: res.data.picture })
                    } catch (e) {
                        console.log(e)
                    }
                }
            })
            .catch(({ message, errors }) => [

            ])
    }
    handleModel() {
        let prevShow = this.state.showModel
        this.setState({
            showModel: !prevShow,
            initUser: null
        })
    }
    render() {
        const { user: userInfo } = this.props
        return (
            <div className="hlpp-100 mx-auto" style={{ width: "70%" }}>
                <div className="sageUserProfile" style={{ position: 'relative' }}>
                    <div className='SUPheader'>
                        <div className="image-input image-input-outline SUPuserPhoto" data-kt-image-input="true">
                            <div className="image-input-wrapper img" style={{ backgroundImage: `url(${this.props.user.picture ? this.props.user.picture : '/media/images/blank.png'})` }}></div>
                            <label className="btn btn-icon btn-circle btn-active-color-primary w-35px h-35px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="" data-bs-original-title="Change avatar">
                                <span className="material-icons text-gray-600 fs-3">edit</span>
                                <input type="file" name="avatar" onChange={this.updatePicture} accept=".png, .jpg, .jpeg" />
                            </label>
                        </div>
                        <div className='SUPuserData'>
                            <div className='SUPuserData-head'>
                                <div className='' style={{ fontWeight: 600, fontSize: '16px' }}>
                                    <div className='d-flex justify-content-start align-items-center gap-3' style={{ fontWeight: 600, fontSize: '18px' }}>
                                        <p style={{ color: '#3F4254' }}>{userInfo.prenom} {userInfo.nom}</p>
                                        <img src='/media/images/2023imgs/userCheck.png' alt="user profile check" width='21' height='21' />
                                    </div>
                                    <div className='d-flex justify-content-start align-items-center littleINfo gap-18 mt-2'>
                                        <div className='d-flex justify-content-start align-items-center gap-2'>
                                            <AiOutlineMail size={18} />
                                            <p>{userInfo.email}</p>
                                        </div>
                                        <div className='d-flex justify-content-start align-items-center gap-1'>
                                            <AiOutlinePhone size={18} />
                                            <p>{userInfo.phone}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button
                                        disabled={Number(userInfo.usedAccess) === Number(userInfo.totalAccess)}
                                        type="button" onClick={this.handleModel} className="btn btn-sm fw-bolder btn-success">Ajouter un utilisateur</button>
                                    <button type="button" onClick={() => {
                                        this.props.history.push('/logout')
                                    }} className="btn btn-sm fw-bolder btn-danger ms-5">Déconnexion</button>                            </div>
                            </div>
                            <div className='d-flex justify-content-start align-items-center gap-10 mt-7 ms-2'>
                                <div className='d-flex justify-content-center align-items-center flex-column border-thckk'>
                                    <p className='m-0' style={{ fontWeight: '800', fontSize: '20px', color: '#3F4254' }}>{userInfo.usedAccess}/{userInfo.totalAccess}</p>
                                    <p className='m-0' style={{ fontWeight: '500', fontSize: '13px', color: '#B5B5C3' }}>Comptes Partagés</p>
                                </div>
                                <div className='d-flex justify-content-center align-items-center flex-column border-thckk'>
                                    <p className='m-0' style={{ fontWeight: '800', fontSize: '20px', color: '#3F4254' }}>{userInfo.sites_count}</p>
                                    <p className='m-0' style={{ fontWeight: '500', fontSize: '13px', color: '#B5B5C3' }}>Sites Partagés</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button onClick={() => { this.props.history.push('/') }} className='btn btn-sm fw-bolder btn-primary btn d-flex align-items-center justify-content-center' style={{ position: 'absolute', bottom: 15, right: 20 }}>
                        {/* <span className="svg-icon svg-icon-1">
                            {User}
                        </span> */}
                        <img src='/media/images/2023imgs/map.png' alt="user profile check" width='25' height='25' />
                        <span className="fs-7 fw-bold ms-2">Map Sites</span>
                    </button>
                </div>
                <div className='userTabs py-2'>
                    <Tabs defaultActiveKey="1" items={this.items} className='userTabsItems' />
                </div>
                <br />
                {this.state.showModel && <CreateUser user={this.props.user}
                    initUser={this.state.initUser}
                    asyncList={this.asyncList}
                    show={this.state.showModel}
                    createSession={this.props.createSession}
                    updateSession={this.props.updateSession} handleModel={this.handleModel} />}

            </div>
        )
    }
}
function mapState(state) {
    const { user } = state.auth
    return { user }
}
const connectedClass = connect(mapState, { setUser: actions.setUser, createSession: actionsUser.createSession, fetchSessions: actionsUser.fetchSessions })(SageUserProfile)
export { connectedClass as SageUserProfile }

// const connectedClass = connect(mapState, actions)(UserSessions)
// export { connectedClass as UserSessions }