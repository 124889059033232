import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { actionTypes } from "./auth.constants";

const initialAuthState = {
    user: null,
    authToken:null,
};
export const Authreducer = persistReducer(
    { storage, key: "sage_users", whitelist: ["authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {
            case actionTypes.Login: {
                const { authToken } = action.payload;
                return { authToken, user: undefined };
            }
            case actionTypes.Logout: {
                return initialAuthState;
            }
            case actionTypes.UserLoaded: {
                const { user } = action.payload;
                return { ...state, user };
            }
            case actionTypes.SetUser: {
                const { user } = action.payload;
                return { ...state, user };
            }
            default:
                return state;
        }
    }
);

export const actions = {
    login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
    logout: () => ({ type: actionTypes.Logout }),
    requestUser: (user) => ({
        type: actionTypes.UserRequested,
        payload: { user },
    }),
    fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
    setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
};

export function* saga() {
    
}