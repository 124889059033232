export const UserGroupe =(
<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 171 171">
    <g>
        <g>
            <path
                d="M0,124.6875c0,-13.77263 12.76087,-24.9375 28.5,-24.9375c15.73912,0 28.5,11.16487 28.5,24.9375zM114,124.6875c0,-13.77263 12.76087,-24.9375 28.5,-24.9375c15.73913,0 28.5,11.16487 28.5,24.9375z"
                fill="#2196f3"></path>
            <path
                d="M149.62856,92.625h-14.25v10.6875c0,0 1.1685,3.5625 6.97894,3.5625c5.81044,0 7.27106,-3.5625 7.27106,-3.5625zM154.96875,78.375c-2.95127,0 -5.34375,2.39248 -5.34375,5.34375c0,2.95127 2.39248,5.34375 5.34375,5.34375c2.95127,0 5.34375,-2.39248 5.34375,-5.34375c0,-2.95127 -2.39248,-5.34375 -5.34375,-5.34375zM130.03125,78.375c-2.95127,0 -5.34375,2.39248 -5.34375,5.34375c0,2.95127 2.39248,5.34375 5.34375,5.34375c2.95127,0 5.34375,-2.39248 5.34375,-5.34375c0,-2.95127 -2.39248,-5.34375 -5.34375,-5.34375z"
                fill="#d19d05"></path>
            <path
                d="M156.75,72.0195c0,-7.85887 -6.39112,-7.8945 -14.25,-7.8945c-7.85888,0 -14.25,0.03563 -14.25,7.8945c0,1.23619 0,12.24075 0,13.4805c0,7.85887 6.39112,14.25 14.25,14.25c7.85888,0 14.25,-6.39113 14.25,-14.25c0,-1.23619 0,-12.24075 0,-13.4805z"
                fill="#ffca28"></path>
            <path
                d="M141.71981,57c-8.68181,0.78019 -17.03231,6.87563 -17.03231,19.85738v5.08012l3.5625,3.5625v-10.6875l22.47938,-7.84106l6.02062,7.84106v10.6875l3.5625,-3.5625v-4.28569c0,-8.59988 -0.48094,-14.98031 -10.6875,-17.08931l-1.72781,-3.5625z"
                fill="#424242"></path>
            <path
                d="M35.62856,92.625h-14.25v10.6875c0,0 1.1685,3.5625 6.97894,3.5625c5.81044,0 7.27106,-3.5625 7.27106,-3.5625zM40.96875,78.375c-2.95127,0 -5.34375,2.39248 -5.34375,5.34375c0,2.95127 2.39248,5.34375 5.34375,5.34375c2.95127,0 5.34375,-2.39248 5.34375,-5.34375c0,-2.95127 -2.39248,-5.34375 -5.34375,-5.34375zM16.03125,78.375c-2.95127,0 -5.34375,2.39248 -5.34375,5.34375c0,2.95127 2.39248,5.34375 5.34375,5.34375c2.95127,0 5.34375,-2.39248 5.34375,-5.34375c0,-2.95127 -2.39248,-5.34375 -5.34375,-5.34375z"
                fill="#d19d05"></path>
            <path
                d="M42.75,72.0195c0,-7.85887 -6.39113,-7.8945 -14.25,-7.8945c-7.85887,0 -14.25,0.03563 -14.25,7.8945c0,1.23619 0,12.24075 0,13.4805c0,7.85887 6.39113,14.25 14.25,14.25c7.85887,0 14.25,-6.39113 14.25,-14.25c0,-1.23619 0,-12.24075 0,-13.4805z"
                fill="#ffca28"></path>
            <path
                d="M27.71981,57c-8.68181,0.78019 -17.03231,6.87563 -17.03231,19.85738v5.08012l3.5625,3.5625v-10.6875l22.47937,-7.84106l6.02063,7.84106v10.6875l3.5625,-3.5625v-4.28569c0,-8.59988 -0.48094,-14.98031 -10.6875,-17.08931l-1.72781,-3.5625z"
                fill="#424242"></path>
            <path
                d="M114,73.03125c0,2.94975 -2.394,5.34375 -5.34375,5.34375c-2.95331,0 -5.34375,-2.394 -5.34375,-5.34375c0,-2.94975 2.39044,-5.34375 5.34375,-5.34375c2.94975,0 5.34375,2.394 5.34375,5.34375M67.6875,73.03125c0,-2.94975 -2.394,-5.34375 -5.34375,-5.34375c-2.94975,0 -5.34375,2.394 -5.34375,5.34375c0,2.94975 2.394,5.34375 5.34375,5.34375c2.94975,0 5.34375,-2.394 5.34375,-5.34375"
                fill="#edbd28"></path>
            <path d="M99.75,103.3125c0,0 0,14.25 -14.25,14.25c-14.25,0 -14.25,-14.25 -14.25,-14.25" fill="#edb405">
            </path>
            <path
                d="M98.85938,103.3125v0l-13.35937,2.74312l-13.35937,-2.74312c0,0 -29.39062,5.43994 -29.39062,35.625h85.5c0,-30.07106 -29.39062,-35.625 -29.39062,-35.625"
                fill="#4fc3f7"></path>
            <path d="M85.5,117.5625c-14.25,0 -14.25,-14.25 -14.25,-14.25v-14.25h28.5v14.25c0,0 0,14.25 -14.25,14.25z"
                fill="#edb405"></path>
            <path
                d="M110.4375,59.4225c0,-20.94394 -49.875,-13.63725 -49.875,0v15.64294c0,13.623 11.16131,24.68456 24.9375,24.68456c13.77263,0 24.9375,-11.06156 24.9375,-24.68812z"
                fill="#ffca28"></path>
            <path
                d="M85.5,124.6875c10.89769,0 19.87162,-8.16525 21.18975,-18.70312c-3.14925,-1.41787 -5.71069,-2.16244 -6.96113,-2.46881c-0.11044,7.76981 -6.42675,14.04694 -14.22862,14.04694c-7.80188,0 -14.12175,-6.27712 -14.22862,-14.05406c-1.25756,0.30637 -3.81544,1.04381 -6.96469,2.451c1.30387,10.55213 10.2885,18.72806 21.19331,18.72806z"
                fill="#01579b"></path>
            <path
                d="M85.5,32.0625c-17.31375,0 -28.5,15.36506 -28.5,29.11275v6.51225l7.125,7.125v-14.25l32.775,-10.6875l9.975,10.6875v14.25l7.125,-7.125v-2.88206c0,-11.46413 -2.96044,-24.23569 -17.1,-27.0465l-2.85356,-5.69644z"
                fill="#795548"></path>
            <path
                d="M92.625,71.25c0,-1.96294 1.596,-3.5625 3.5625,-3.5625c1.9665,0 3.5625,1.59956 3.5625,3.5625c0,1.96294 -1.596,3.5625 -3.5625,3.5625c-1.9665,0 -3.5625,-1.59956 -3.5625,-3.5625M71.25,71.25c0,1.96294 1.596,3.5625 3.5625,3.5625c1.9665,0 3.5625,-1.59956 3.5625,-3.5625c0,-1.96294 -1.596,-3.5625 -3.5625,-3.5625c-1.9665,0 -3.5625,1.59956 -3.5625,3.5625"
                fill="#784719"></path>
        </g>
    </g>
</svg>
)