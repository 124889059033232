import { CrudService } from "../../services";
import {sessionsUrls} from './sessions.constants'
export function fetchSessions(QueryParams) {
    return CrudService.Get(sessionsUrls.FETCH_SESSIONS_URL,QueryParams);
}
export function createSession(session) {
    return CrudService.Post(sessionsUrls.CREATE_SESSION_URL,session);
}
export function updateSession(session) {
    return CrudService.Patch(sessionsUrls.UPDATE_SESSION_URL,session);
}