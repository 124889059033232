export const UserAccount = (
<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 171 171">
    <defs>
        <linearGradient x1="117.26681" y1="71.25" x2="122.01919" y2="71.25" gradientUnits="userSpaceOnUse"
            id="color-1_Ry7mumEprV9w_gr1">
            <stop offset="0" stopColor="#c48f0c"></stop>
            <stop offset="0.251" stopColor="#d19b16"></stop>
            <stop offset="0.619" stopColor="#dca51f"></stop>
            <stop offset="1" stopColor="#e0a922"></stop>
        </linearGradient>
        <linearGradient x1="53.73319" y1="71.25" x2="48.98081" y2="71.25" gradientUnits="userSpaceOnUse"
            id="color-2_Ry7mumEprV9w_gr2">
            <stop offset="0" stopColor="#c48f0c"></stop>
            <stop offset="0.251" stopColor="#d19b16"></stop>
            <stop offset="0.619" stopColor="#dca51f"></stop>
            <stop offset="1" stopColor="#e0a922"></stop>
        </linearGradient>
        <radialGradient cx="85.50397" cy="66.6181" r="38.39192" gradientUnits="userSpaceOnUse"
            id="color-3_Ry7mumEprV9w_gr3">
            <stop offset="0" stopColor="#ffcf54"></stop>
            <stop offset="0.261" stopColor="#fdcb4d"></stop>
            <stop offset="0.639" stopColor="#f7c13a"></stop>
            <stop offset="1" stopColor="#f0b421"></stop>
        </radialGradient>
        <radialGradient cx="55.0329" cy="23.73812" r="90.81334" gradientUnits="userSpaceOnUse"
            id="color-4_Ry7mumEprV9w_gr4">
            <stop offset="0" stopColor="#c26715"></stop>
            <stop offset="0.508" stopColor="#b85515"></stop>
            <stop offset="1" stopColor="#ad3f16"></stop>
        </radialGradient>
        <radialGradient cx="80.39945" cy="29.81852" r="100.31235" gradientUnits="userSpaceOnUse"
            id="color-5_Ry7mumEprV9w_gr5">
            <stop offset="0" stopColor="#c26715"></stop>
            <stop offset="0.508" stopColor="#b85515"></stop>
            <stop offset="1" stopColor="#ad3f16"></stop>
        </radialGradient>
    </defs>
    <g>
        <g>
            <path
                d="M85.5,108.90919c-31.48181,0 -57,17.53106 -57,36.64388v4.07194c0,3.93656 3.18844,7.125 7.125,7.125h99.75c3.93656,0 7.125,-3.18844 7.125,-7.125v-4.07194c0,-19.11281 -25.51819,-36.64388 -57,-36.64388z"
                fill="#33c481"></path>
            <path
                d="M106.875,116.1375c0,-1.31812 0,-2.80725 0,-4.389c-6.60487,-1.80263 -13.80825,-2.83575 -21.375,-2.83575c-7.56675,0 -14.77013,1.03312 -21.375,2.83575c0,1.58531 0,3.07444 0,4.389c0,8.11181 21.375,29.925 21.375,29.925c0,0 21.375,-21.81675 21.375,-29.925z"
                fill="#21a366"></path>
            <path
                d="M103.3125,114c0,6.75806 -17.8125,24.9375 -17.8125,24.9375c0,0 -17.8125,-18.17944 -17.8125,-24.9375c0,-9.39431 0,-28.62469 0,-28.62469h35.625c0,0 0,19.23038 0,28.62469z"
                fill="#d6a121"></path>
            <path
                d="M103.3125,114c0,6.75806 -17.8125,24.9375 -17.8125,24.9375c0,0 -17.8125,-18.17944 -17.8125,-24.9375c0,0 7.125,7.125 17.8125,7.125c10.6875,0 17.8125,-7.125 17.8125,-7.125z"
                fill="#ffffff"></path>
            <path
                d="M117.26325,64.125h-1.87744v14.25h2.50444c1.83469,0 3.39863,-1.1115 3.70856,-2.63625l1.2255,-6.06694c0.58781,-2.89988 -2.06625,-5.54681 -5.56106,-5.54681z"
                fill="url(#color-1_Ry7mumEprV9w_gr1)"></path>
            <path
                d="M53.73675,64.125h1.87744v14.25h-2.50444c-1.83469,0 -3.39863,-1.1115 -3.70856,-2.63625l-1.2255,-6.06694c-0.58781,-2.89988 2.06625,-5.54681 5.56106,-5.54681z"
                fill="url(#color-2_Ry7mumEprV9w_gr2)"></path>
            <path
                d="M85.5,22.8c-15.82106,0 -32.0625,2.40469 -32.0625,36.60469c0,2.736 0,20.93681 0,23.86162c0,12.20869 19.2375,27.17119 32.0625,27.17119c12.825,0 32.0625,-14.9625 32.0625,-27.17119c0,-2.92481 0,-21.12206 0,-23.86162c0,-34.2 -16.24144,-36.60469 -32.0625,-36.60469z"
                fill="url(#color-3_Ry7mumEprV9w_gr3)"></path>
            <path
                d="M85.5,19.75406c-15.51112,0 -17.8125,5.82825 -17.8125,5.82825c-6.30562,0.92981 -17.8125,10.16738 -17.8125,20.72662c0,5.89237 0.94406,10.24575 3.5625,24.9375c1.94156,-23.31656 8.01206,-32.0625 14.25,-32.0625c4.51369,0 8.09756,3.5625 17.8125,3.5625c8.20444,0 10.24219,-3.5625 17.8125,-3.5625c10.6875,0 14.25,28.386 14.25,32.0625c2.14106,-10.72313 3.5625,-19.78969 3.5625,-24.9375c0,-12.21581 -13.50544,-26.55487 -35.625,-26.55487z"
                fill="url(#color-4_Ry7mumEprV9w_gr4)"></path>
            <path
                d="M86.28019,17.8125c-14.83425,0 -18.582,7.77338 -18.582,7.77338c-0.14963,4.12894 1.85962,7.77338 1.85962,7.77338c0,0 2.7075,5.82825 18.16875,5.82825c9.33375,0 22.71094,-5.82469 22.71094,-21.375c0,0 -3.00319,0 -24.15731,0z"
                fill="url(#color-5_Ry7mumEprV9w_gr5)"></path>
            <rect x="23" y="39" transform="scale(3.5625,3.5625)" width="2" height="5" fill="#21a366"></rect>
        </g>
    </g>
</svg>
)