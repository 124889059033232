import React, { Component } from 'react'
// import { Pagination } from 'antd'
import { Spinner } from 'react-bootstrap'
import { EmptyState } from '../../../assets/icons'
class SageTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allchecked: 0,
            sorter: {
                value: props.defaultSorter && props.defaultSorter.sort,
                current: props.defaultSorter && props.defaultSorter.sorter
            },
            selectedRow: (props.rowSelect && props.rowSelect.selectedRow) || []
        }
        this.handleSelectRow = this.handleSelectRow.bind(this)
    }
    handleSelectRow = (e) => {
        const { rowKey, rowSelection } = this.props.IterableProps
        const { dataset: { rowtable } } = e.target
        let prevRow = this.state.selectedRow
        let allCheck = this.state.allchecked
        if (rowtable === "all") {
            if (allCheck !== 0) {
                prevRow = []
                allCheck = 0
            } else {
                prevRow = this.props.data.map(row => row[rowKey])
                allCheck = 1
            }
        } else {
            allCheck = 2
            let intvalRow = parseInt(rowtable)
            if (rowSelection.type === "radio") {
                prevRow = [intvalRow]
            } else {
                let indexValu = prevRow.indexOf(intvalRow)
                if (indexValu > -1) {
                    prevRow = [...prevRow.filter(elem => elem != intvalRow)]
                    allCheck = prevRow.length === 0 ? 0 : 2
                } else {
                    prevRow = [...prevRow, intvalRow]
                }
            }

        }
        this.setState({
            allchecked: allCheck,
            selectedRow: prevRow
        })
        if (rowSelection.onChange) {
            rowSelection.onChange(prevRow)
        }
    }
    handleSorter(name) {
        let sorter = {
            value: null,
            current: null
        }
        if (!this.state.sorter.current) {
            sorter = {
                value: "ASC",
                current: name
            }
        } else if (this.state.sorter.current === name) {
            if (this.state.sorter.value === "ASC") {
                sorter = {
                    value: "DESC",
                    current: name
                }
            }
        } else {
            sorter = {
                value: "ASC",
                current: name
            }
        }

        this.setState({
            sorter: sorter
        })
        if (this.props.callBackSort) {
            this.props.callBackSort(sorter)
        }
    }
    render() {
        const { rowKey, pagination, loading, onChange, rowSelection, emptyText } = this.props.IterableProps
        return (
            <div className="position-relative">
                {loading && <div className="position-absolute t-0 l-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: 10, background: "#ffffff40" }}>
                    <Spinner animation="border" variant="primary" />
                </div>}
                <div className="table-responsive" style={this.props.style}>
                    <table className="table" style={{ maxHeight: "100%", ...this.props.tableStyle, fontSize: '13px' }}>
                        <thead className="border-bottom">
                            <tr className={`fix_item ${rowSelection && 'fix_secend_item'} ${this.state.allchecked && 'active_ligne'}`}>
                                {rowSelection && <th>{rowSelection.type !== "radio" && <label htmlFor="select_all_items" className="checkbox">
                                    <input type="checkbox" name="row_table" id="select_all_items" checked={this.state.allchecked} onChange={this.handleSelectRow} data-rowtable="all" /><span></span></label>}</th>}
                                {this.props.columns.map((head, key) => {
                                    return <th className={`fw-bold text-gray-800 ${head.sorter ? 'sortable' : ''}`} key={key}>
                                        {head.sorter ? <div className="d-flex justify-content-between" onClick={() => this.handleSorter(head.dataIndex)}>
                                            <span className="">{head.title}</span>
                                            <span className={`svg-icon svg-icon-md svg-icon-primary ml-1 ${this.state.sorter.current !== head.dataIndex ? 'svg-icon-sort' : ''}`}>
                                                {/*<SVG src={toAbsoluteUrl(`/media/svg/icons/Costum/${this.state.sorter.current === head.dataIndex ? this.state.sorter.value : 'Sort1'}.svg`)} />*/}
                                            </span>
                                        </div> : head.title}
                                    </th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {(this.props.data || []).map((tr, key1) => {
                                let isactive = this.state.selectedRow.indexOf(tr[rowKey]) > -1
                                return <tr key={key1} className={`fix_item ${rowSelection && 'fix_secend_item'} ${isactive && 'active_ligne'}`}>
                                    {rowSelection && <td>
                                        <label htmlFor={"select_all_items" + tr[rowKey]} className={rowSelection.type === "radio" ? "radio" : "checkbox"}>
                                            <input type={rowSelection.type === "radio" ? "radio" : "checkbox"} name="row_table" id={"select_all_items" + tr[rowKey]} checked={isactive} onChange={this.handleSelectRow} data-rowtable={tr[rowKey]} /><span></span></label>
                                    </td>}
                                    {tr && this.props.columns.map((clmn, key2) => {
                                        if (clmn.render) {
                                            return <td key={key2}>{clmn.render(tr[clmn.dataIndex], tr)}</td>
                                        } else {
                                            if (tr[clmn.dataIndex]) {
                                                return <td key={key2} title={tr[clmn.dataIndex]}>{tr[clmn.dataIndex]}</td>
                                            }
                                            return <td key={key2}></td>
                                        }
                                    })}
                                </tr>
                            })}
                        </tbody>
                    </table>
                    {(this.props.data || []).length === 0 &&
                        <div className="d-flex mt-4 left-0 justify-content-center flex-column align-items-center w-100 h-100">
                            <span className="pt-5">{EmptyState}</span>
                            <strong className="font-size-h4 pb-8">Aucune données</strong>
                        </div>}
                </div>
                {/* {pagination && <div className="d-flex justify-content-end mt-3">
                    <Pagination {...pagination} onChange={(page, size) => onChange({ current: page, pageSize: size })} />
                </div>} */}
            </div>
        )
    }
}
export { SageTable }
