import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modal, Spinner } from 'react-bootstrap'
export const CreateUser = (props) => {
    const [state, setState] = useState(props.initUser || { nom: '', prenom: '', username: '', email: '', phone: '', init_password: '' })
    const { loadingCreate, errors = {}, session } = useSelector((state) => state.sessions);
    useEffect(() => {
        if (session && loadingCreate === 1) {
            props.asyncList()
        }
    }, [session]);
    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (props.initUser) {
            props.updateSession(state)
        } else {
            props.createSession(state)
            // props.handleModel()
        }
    }
    return (
        <Modal
            onHide={props.handleModel}
            show={props.show}
            size="lg"
            className="p-2"
            centered
        >
            {(loadingCreate === 0) && <div className="position-absolute t-0 l-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: 10, background: "#ffffff40" }}>
                <Spinner animation="border" variant="primary" />
            </div>}
            <div className="px-6 py-5">
                <div>
                    <h3 className="fw-bolder">Ajouter un(e) utilisateur</h3>
                </div>
                <div className="separator separator-solid mt-4"></div>
                <form className="pt-4 form fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit}>
                    <div className="row mb-6">
                        <div className="form-group col">
                            <label className="fs-5 fw-bold mb-1">{global._trans("sessions", "firstName")}</label>
                            <input className="form-control form-control-lg form-control-solid" type="text" value={state.nom} onChange={handleChange} name="nom" placeholder={global._trans("sessions", "firstName")} />
                            {(loadingCreate === 2 && errors) && <span className="form-text fs-6 ps-2 d-block text-danger">{errors.nom}</span>}
                        </div>
                        <div className="form-group col">
                            <label className="fs-5 fw-bold mb-1">{global._trans("sessions", "lastName")}</label>
                            <input className="form-control form-control-lg form-control-solid" type="text" value={state.prenom} onChange={handleChange} name="prenom" placeholder={global._trans("sessions", "lastName")} />
                            {(loadingCreate === 2 && errors) && <span className="form-text fs-6 ps-2 d-block text-danger">{errors.prenom}</span>}
                        </div>
                    </div>
                    <div className="row mb-6">
                        <div className="form-group col">
                            <label className="fs-5 fw-bold mb-1">{global._trans("sessions", "userName")}</label>
                            <input className="form-control form-control-lg form-control-solid" type="text" value={state.username} onChange={handleChange} name="username" placeholder={global._trans("sessions", "userName")} />
                            {(loadingCreate === 2 && errors) && <span className="form-text fs-6 ps-2 d-block text-danger">{errors.username}</span>}
                        </div>
                        <div className="form-group col">
                            <label className="fs-5 fw-bold mb-1">{global._trans("sessions", "email")}</label>
                            <input className="form-control form-control-lg form-control-solid" type="text" value={state.email} onChange={handleChange} name="email" placeholder={global._trans("sessions", "email")} />
                            {(loadingCreate === 2 && errors) && <span className="form-text fs-6 ps-2 d-block text-danger">{errors.email}</span>}
                        </div>
                    </div>
                    <div className="row mb-6">
                        <div className="form-group col">
                            <label className="fs-5 fw-bold mb-1">{global._trans("sessions", "phone")}</label>
                            <input className="form-control form-control-lg form-control-solid" type="text" value={state.phone} onChange={handleChange} name="phone" placeholder={global._trans("sessions", "phone")} />
                            {(loadingCreate === 2 && errors) && <span className="form-text fs-6 ps-2 d-block text-danger">{errors.phone}</span>}
                        </div>
                        <div className="form-group col">
                            <label className="fs-5 fw-bold mb-1">{global._trans('sessions', 'initPassword')}</label>
                            <input className="form-control form-control-lg form-control-solid" value={state.init_password} type="text" onChange={handleChange} name="init_password" placeholder={global._trans('sessions', 'initPassword')} disabled={state.init_password === null} />
                            {(loadingCreate === 2 && errors) && <span className="form-text fs-6 ps-2 d-block text-danger">{errors.init_password}</span>}
                        </div>
                    </div>
                    <div className="d-flex justify-content-end pr-5">
                        <button type="button" onClick={props.handleModel} className="btn btn-light me-3 fw-bold">Annuler</button>
                        <button className="btn btn-primary fw-bold">{props.initUser ? 'Enregistrer' : 'Ajouter utilisateur'}</button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}