import { commonSlice } from "./common.reducer";
import * as CommonRequests from "./common.crud";
const { actions } = commonSlice;
export const AllLocaleInfo = QueryPramas => dispatch => {
    return CommonRequests
        .allLocaleInfo(QueryPramas)
        .then(res => {
            if(res && res.data && res.data.ok){
                dispatch(actions.localesFetched({regions:res.data.regions || [],departments:res.data.departments || []}))
            }else{
                dispatch(actions.catchError({errors:"fail to load sites"}))
            }
        })
        .catch(err => {
            dispatch(actions.catchError({errors:"fail to load sites"}))
        });
};
