import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { FiltersContainer } from './FiltersContainer'
import { fetchSitesMap } from '../../../modules/Map/map.actions'
import { Svg } from '../Common'
import qs from 'qs'
import filtersBtn from '../../../assets/icons/Buttonfilters.png'
// import { FilterFilled } from '@ant-design/icons'

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showFilters: false,
        }
        this.refsFilter = [createRef(), createRef(), createRef()]
        var searchQuery = this.props.location.search.replace('?', '')
        this.filters = {
            categorie: 'all',
            modeGest: 'all',
            department: '',
            region: '',
            search: '',
            field: 'denomination',
            ...qs.parse(searchQuery)
            // fields:[],
            // dateRange:{
            //     startDate:null,
            //     endDate:null
            // },
        }
        this.name = props.user.prenom[0] + '.' + props.user.nom
        this.headerRef = React.createRef()
        this._handleFilters = this._handleFilters.bind(this)
        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleResetForm = this.handleResetForm.bind(this)
        this.handleCallSearch = this.handleCallSearch.bind(this)
        this.fetchData = this.fetchData.bind(this)
        this.handleChangeDates = this.handleChangeDates.bind(this)
    }
    _handleFilters() {
        let prevState = this.state.showFilters
        this.setState({
            showFilters: !prevState
        })
    }
    handleCallSearch(e) {
        e.preventDefault()
        this.fetchData()
    }
    fetchData() {
        if (this.filters.categorie === 'all' && this.filters.modeGest === 'all' && !this.filters.department
            && !this.filters.region && this.filters.search === '') {
            this.props.history.push({
                search: ''
            })
        } else {
            this.props.history.push({
                search: qs.stringify(this.filters)
            })
        }
        this.props.fetchSitesMap(this.props.lat, this.props.lang, this.filters)
        this.state.showFilters = false
    }
    handleChange(e) {
        const { name, value } = e.target
        this.filters = {
            ...this.filters,
            [name]: value
        }
    }
    handleChangeName(name, value) {
        this.filters = {
            ...this.filters,
            [name]: value
        }
    }
    handleChangeDates(start, end) {
        this.filters = {
            ...this.filters,
            dateRange: {
                startDate: start && start.toISOString().split('T')[0],
                endDate: end && end.toISOString().split('T')[0]
            }
        }
    }
    handleResetForm() {
        let form = document.getElementById('filter_global_map')
        if (form) {
            form.reset()
            this.filters = {
                categorie: 'all',
                modeGest: 'all',
                department: '',
                region: '',
                search: '',
                field: 'denomination',
                // fields:[],
                // modeGest:null,
                // categorie:null,
                // regions:[],
                // departments:[],
                // dateRange:{
                // startDate:null,
                // endDate:null
                // },
                // search:''
            }
            let input_search = document.getElementById('input_search')
            if (input_search) {
                input_search.value = ''
            }
            this.refsFilter.forEach(item => {
                if (item && item.current) {
                    item.current.removeAllTags()
                }
            })
            this._handleFilters()
            setTimeout(() => {
                this._handleFilters()
            }, 10);
        }
    }
    render() {
        return (
            <div className={`fixed-top ${this.state.showFilters ? 'h-100' : ''}`} style={{ zIndex: 1035, background: '#000619', left:0, right:0 }}>
                <div ref={this.headerRef} className="p-relative header_shadow px-5 border-bottom transition-1 py-2" style={{ background: '#000619' }}>
                    <div className={`p-relative w-100 d-flex h-header align-items-center border-box px-2`} style={{ zIndex: 1035, background: '#000619' }}>
                        <Link to="/" className="position-absolute">
                            <img src="/sageLogoSideBar.png" alt="Logo sage" height='45' width={120} />
                        </Link>
                        <div className="position-absolute d-flex align-items-center gap-2" style={{ right: "20px" }}>
                            <div className="shadow_bg cursor-pointer bdg-light py-2" style={{ color:'white' }}>
                                <NavLink target="_blank" to="/contact" className="d-flex justify-space-between align-items-center py-0 px-4" style={{ fontSize: "1.2em" }}>
                                    <div className="user_icon">
                                        <i className="d-flex"><span className="material-icons text-white">contact_support</span></i>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="shadow_bg cursor-pointer bdg-light py-2" style={{ borderRadius: "20px", border: '1px solid white', color:'white' }}>
                                <NavLink to="/account" className="d-flex justify-space-between align-items-center py-0 px-4" style={{ fontSize: "1.2em" }}>
                                    <strong className="pr-2 pl-1 text-capitalize text-white mx-2">{this.name}</strong>
                                    <div className="user_icon">
                                        {!this.props.user.picture ?
                                            <i className="d-flex"><span className="material-icons text-white">account_circle</span></i> :
                                            <img alt="User profile" className="rounded rounded-circle ms-3" style={{ width: 30, height: 30 }} src={this.props.user.picture} />}
                                    </div>
                                </NavLink>
                                
                            </div>
                        </div>
                    </div>
                    {this.state.showFilters && <FiltersContainer handleChangeDates={this.handleChangeDates} refs={this.refsFilter} handleReset={this.handleResetForm} filters={this.filters} handleChange={this.handleChange} handleSubmit={this.handleCallSearch} handleChangeName={this.handleChangeName} closeFilter={this._handleFilters} />}
                    {/* {this.props.location.pathname === "/" && (
                        <FiltersContainer handleChangeDates={this.handleChangeDates} refs={this.refsFilter} handleReset={this.handleResetForm}filters={this.filters}
                        handleChange={this.handleChange} handleSubmit={this.handleCallSearch} handleChangeName={this.handleChangeName} closeFilter={this._handleFilters}/>
                    )} */}
                </div>
            </div>
        )
    }
}
function mapState(state) {
    const { lat, lang } = state.map
    return { lat, lang }
}
const connectedClass = connect(mapState, { fetchSitesMap })(Header)
export { connectedClass as Header }