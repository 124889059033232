export const EmptyState =(
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="80" height="80" viewBox="0 0 171 171">
        <g>
            <g>
                <path d="M35.625,24.9375h99.75v24.9375h-99.75z" fill="#3498db"></path>
                <path d="M35.625,74.8125h-14.25v-28.5l14.25,-21.375zM135.375,74.8125h14.25v-28.5l-14.25,-21.375z"
                    fill="#61a8d8"></path>
                <path
                    d="M142.5,146.0625h-114c-3.91875,0 -7.125,-3.20625 -7.125,-7.125v-92.625h128.25v92.625c0,3.91875 -3.20625,7.125 -7.125,7.125z"
                    fill="#83cafd"></path>
                <path
                    d="M97.96875,71.25h-24.9375c-2.85,0 -5.34375,-2.49375 -5.34375,-5.34375v0c0,-2.85 2.49375,-5.34375 5.34375,-5.34375h24.9375c2.85,0 5.34375,2.49375 5.34375,5.34375v0c0,2.85 -2.49375,5.34375 -5.34375,5.34375z"
                    fill="#61a8d8"></path>
            </g>
        </g>
    </svg>
)
