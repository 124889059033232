import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import { Authreducer, saga,mapSlice,sessionsSlice ,commonSlice, sharedSlice} from "../../modules";

export const rootReducer = combineReducers({
  auth: Authreducer,
  map:mapSlice.reducer,
  sessions:sessionsSlice.reducer,
  shared:sharedSlice.reducer,
  common:commonSlice.reducer
});

export function* rootSaga() {
  yield all([saga()]);
}
