import { CrudService } from "../../services";
import { authUrls } from './auth.constants'
export function login(user) {
    return CrudService.Post(authUrls.LOGIN_URL, user);
}
export function getUserByToken() {
    return CrudService.Get(authUrls.USER_URL);
}
export function updatePicture(Picture) {
    return CrudService.Post(authUrls.UPDATE_PICTURE_URL,Picture);
}
export function updateUser(user) {
    return CrudService.Patch(authUrls.UPDATE_USER_URL,user);
}
export function updatePassword(passwordObject) {
    return CrudService.Patch(authUrls.UPDATE_PASSWORD_URL,passwordObject);
}
export function otp(data) {
    return CrudService.Post(authUrls.OTP_URL, data);
}