import { Select } from 'antd';
import React, { useState } from 'react';

import { connect } from "react-redux";
const RegionSearch = (props) => {
    // const [loading, setLoading] = useState(false)
    // const [data, setData] = useState(null)
    // const handleSearch = (search) => {

    //     // if (!!search && search != '') {
    //     //     if (props.type === 'dep') {
    //     //         setData(props.departementListed.filter((region) => region.name_region.toUpperCase().includes(search.toUpperCase())));                
    //     //     } else {
    //     //         setData(props.regionstListed.filter((region) => region.name_region.toUpperCase().includes(search.toUpperCase())));                                
    //     //     }
    //     // } else {
    //     //     if (props.type === 'dep') {
    //     //         setData(props.departementListed);
    //     //     } else {
    //     //         setData(props.regionstListed);
    //     //     }
    //     // }
    // }

    const options = props.type === 'dep' ? props.departementListed : props.regionstListed;
    // const options = !!data ? data : props.type === 'dep' ? props.departementListed : props.regionstListed;
    const regs = !!options && Array.isArray(options) && options.length > 0 ? options.map((region) => ({ label: `${region.region_code} - ${region.name_region}`, value: region.id_region })) : []
    const deps = !!options && Array.isArray(options) && options.length > 0 ? options.map((region) => ({ label: `${region.departement_code} - ${region.name_departement}`, value: region.id_departement })) : []
    const opts = props.type === 'dep' ? deps : regs
    return (
        <>
            <label className="fs-6 form-label text-white">{props.placeholder}</label>
            <Select
                mode="multiple"
                showSearch
                allowClear
                placeholder={'select ... '}
                style={{ width: '100%', overflow: 'auto', padding: '0', outline: 'none', backgroundColor: '#202531' }}
                optionFilterProp="label"
                size="small"
                value={props.value}
                filterOption={(input, Option) =>
                    Option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={props.onChange}
            >
                <Select.Option value='all'>Tout</Select.Option>
                {opts.map((el) => {
                    return <Select.Option value={el.value} key={el.value}>{el.label}</Select.Option>
                })}
            </Select>
            {/* <select onChange={props.onChange} name={props.type === 'dep' ? 'department' : 'region'} defaultValue={props.value} className="form-select form-select-solid">
                <option value="">Tout</option>
                {opts.map((el) => {
                    return <option value={el.value} key={el.value}>{el.label}</option>
                })}
            </select> */}
            {/* <Select
                disabled={props.disabled}
                showSearch
                showArrow
                className="inputSizeSelect"
                placeholder={props.placeholder}
                style={{ width: '100%' }}
                optionFilterProp="label"
                value={props.value}
                notFoundContent={global._trans("common", "noThingToShow")}
                loading={loading}
                options={opts}
                onSearch={handleSearch}
                onSelect={props.onChange}
            /> */}
        </>
    )
}

function mapState(state) {
    const { departementListed, regionstListed } = state.map;
    return { departementListed, regionstListed };
}
const connectedClass = connect(mapState, {})(RegionSearch)
export { connectedClass as RegionSearch }