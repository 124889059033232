import React from 'react';
import ReactDOM from 'react-dom';
import App from './Root';
import { store, persistor } from './config'
import { CrudService } from './services'
CrudService.setAuthHeader(store)
ReactDOM.render(
  <React.StrictMode>
    <App store={store} persistor={persistor} basename="/" />
  </React.StrictMode>,
  document.getElementById('sage_users_1')
);
