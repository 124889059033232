import { CrudService } from "../../services";
import {mapUrls} from './map.constants'
export function fetchSitesMap(lat,lang,QueryParams) {
    return CrudService.Get(mapUrls.FETCH_SITETS_URL+lat+"/"+lang,QueryParams);
}
export function fetchSitesMapNew(QueryParams) {
    return CrudService.Post(mapUrls.FETCH_SITETS_URL_NEW,QueryParams);
}
export function getGroupes(QueryParams) {
    return CrudService.Get(mapUrls.FETCH_URL,QueryParams);
}
export function fetchDetailShare(idAccess,idSite) {
    return CrudService.Get(mapUrls.FETCH_DETAIL_URL+idAccess+"/"+idSite);
}
export function fetchAllDepartemnts(idAccess,idSite) {
    return CrudService.Get(mapUrls.FETCH_REGION_URL);
}