/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadLMaps } from './LoadMapScript'
import qs from 'qs'
import { LuSettings2 } from 'react-icons/lu';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { fetchSitesMap, fetchDetailShare, fetchAllDepartemnts, fetchSitesMapNew, getGroupes } from '../../../modules/Map/map.actions'
import { AllLocaleInfo } from '../../../modules/Common/common.actions'
import { HeartOutlined } from '@ant-design/icons'
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { RegionSearch, Svg } from '../Common';
import { Checkbox, Select, Slider } from 'antd';
import { Form, Col } from 'react-bootstrap';
import { addSpaceInNUmber } from '../../../services/formatTelephone';
class Map extends Component {
    constructor(props) {
        super(props)
        var searchQuery = this.props.location.search.replace('?', '')
        this.state = {
            showDrawer: false,
            listSide: false,
            currentStyle: 2,
            orderBY: qs.parse(searchQuery).orderBY || 'denomination',
            showFIlter: 1,
            sitesArray: [],
            groupsListData: [],
            typeEquipementData: [],
            modeGestionData: [],
            amobeData: [],
            traitementFumeeData: [],
            asCategory: [],
            asModeGestion: ['all'],
            asAmobe: ['all'],
            asGroupeExploitant: ['all'],
            asDepartement: ['all'],
            asRegion: ['all'],
            asNombreDeLignes: ['all'],
            asCapacitereglementaire: [0, 1000000],
            asTechnologieDeTurbine: ['all'],
            asVoieDeTraitementDesFumees: ['all'],
            asTypeDeValorisation: '',
            asChaleurVendueSite: [0, 1000000],
            asChaleurVendueSiteCheck: false,
            asElectriciteVendueSite: [0, 1000000],
            asElectriciteVendueSiteCheck: false,
            asContractAvantApres: 'all',
            asContractDate: null,
            asContractDateEnd: null,
            asContractDateCheck: false,
            search: '',
            ...qs.parse(searchQuery),
        }
        this.scopeMap = null
        this.map = null
        this.markers = []
        this.isPopupOpened = false
        this.colors = {
            tmb: "#01b26b",
            isdnd: "#cd0000",
            uve: "#2a244e",
            tri: "#187DE4"
        }
        this.orderByList = [
            {
                value: 'categorieSite',
                label: 'Catégorie'
            },
            {
                value: 'modeGestion',
                label: 'M.Gestion'
            },
            {
                value: 'city',
                label: 'Ville'
            },
            {
                value: 'region_siege',
                label: 'Région'
            },
            {
                value: 'departement_siege',
                label: 'Département'
            },
        ]
        this.callback = this.callback.bind(this)
        this.handleDrawer = this.handleDrawer.bind(this)
        this.handleFetchBound = this.handleFetchBound.bind(this)
        this.handleCallSearch = this.handleCallSearch.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.asUser = this.asUser.bind(this)
        this.justUve = this.justUve.bind(this)
        this.justGestionPrev = this.justGestionPrev.bind(this)
        this.openMarker = this.openMarker.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        const { listSitesMap, laodingSitesMap, siteAlll, groupsList, typeEquipement, traitementFumee, modeGestion, amobe } = nextProps
        let sitesArrayhere = []
        if (listSitesMap) {
            this.setState({ sitesArray: listSitesMap })
            sitesArrayhere = listSitesMap
        }
        if (groupsList) {
            this.setState({ groupsListData: groupsList })
        }
        if (traitementFumee) {
            this.setState({ traitementFumeeData: traitementFumee })
        }
        if (typeEquipement) {
            this.setState({ typeEquipementData: typeEquipement })
        }
        if (modeGestion) {
            this.setState({ modeGestionData: modeGestion })
        }
        if (amobe) {
            this.setState({ amobeData: amobe })
        }

        if (this.props.laodingSitesMap === 0 && laodingSitesMap === 1) {
            if (this.scopeMap && this.map) {
                this.markers.forEach(mark => {
                    mark.marker.remove()
                })
                this.markers = []
                sitesArrayhere.forEach(site => {
                    var popup = new this.scopeMap.Popup({
                        closeButton: true,
                        closeOnClick: true,
                        closeOnMove: false,
                    })
                        .setHTML(`
                        <div class='text-left bgCard${site.iconType}' style='z-index: 2' id='btnPlusDtsMarkerCard' data-idAccess=${site.id_access} data-idSite=${site.id_site}>
                             <div class='mb-0' style='text-align: center'> 
                                <img src=${siteAlll.find((el) => el.id_site === site.id_site).default_image
                                ? siteAlll.find((el) => el.id_site === site.id_site).default_image.url :
                                siteAlll.find((el) => el.id_site === site.id_site).photos.length ?
                                    siteAlll.find((el) => el.id_site === site.id_site).photos[siteAlll.find((el) => el.id_site === site.id_site).photos.length - 1].url
                                    : "/media/factory.png"
                            }
                                     alt="a picture" width="300px" height="160" style="border-radius: 6px">
                            </div>
                            <div class='text-dark font-weight-bold text-left' style='font-family: Poppins, Helvetica;' > 
                            <div class="d-flex justify-content-center align-items-center w-100"><p style='font-size: 14px; font-weight: 600' class='NameSitePosition ${site.iconType} '>${site.denomination}</p></div>
                            <p class='iconTypePosition ${site.iconType}'>${site.iconType}</p>
                            ${siteAlll.find((el) => el.id_site === site.id_site).logo_exploitant ?
                                `<div class='logoSitePosition ${site.iconType}'>
                                <img src=${siteAlll.find((el) => el.id_site === site.id_site).logo_exploitant}
                                 alt="a picture" width="110" height="28" style="border-radius: 8px;object-fit: contain; padding: 0 5px;">
                                 </div>`
                                : ''}
                            <p title="${global._trans('map', 'client')}" class='${site.iconType} linearGray' style='font-weight: 600; font-size: 14px; line-height: 18px; word-break: break-word; color: black; margin:5px auto 5px auto; width: max-content; max-width:220px; text-align:center; '>${siteAlll.find((el) => el.id_site === site.id_site).client?.client ? siteAlll.find((el) => el.id_site === site.id_site).client.client[siteAlll.find((el) => el.id_site === site.id_site).client.client.dataIndex] || '' : "-"}</p>                                                                                      
                            <p title="${global._trans('map', 'exploitant')}" class='${site.iconType} linearGray' style='font-weight: 600; font-size: 14px; line-height: 18px; word-break: break-word; color: black; margin: 0 auto 5px auto; width: max-content; max-width:220px; text-align:center; '>
                            ${siteAlll.find((el) => el.id_site === site.id_site).exploitant?.client ? siteAlll.find((el) => el.id_site === site.id_site).exploitant.client[siteAlll.find((el) => el.id_site === site.id_site).exploitant.client.dataIndex] || siteAlll.find((el) => el.id_site === site.id_site).exploitant.client.denomination || '' : "-"}
                            </p>
                            ${site.iconType === 'UVE' ?
                                `<div class='d-flex justify-content-between align-items-start'>
                                     <div class='d-flex justify-content-between align-items-center'>
                                        <p title='${global._trans('map', 'capaciteReglementaire')}' class='${site.iconType} py-1 linearGray' style='color: black; font-weight: 600'>
                                        ${addSpaceInNUmber(siteAlll.find((el) => el.id_site === site.id_site).tonnage)} T
                                        </p>
                                        <p class='UVENBRLIGNE mx-4 linearGray' style='color: black; font-weight: 700' title='${global._trans('map', 'lines')}'>
                                            ${siteAlll.find((el) => el.id_site === site.id_site).nb_lines}
                                        </p>
                                    </div>
                                    <div class='d-flex justify-content-between align-items-center'>
                                    ${siteAlll.find((el) => el.id_site === site.id_site).types_valorisation
                                    && siteAlll.find((el) => el.id_site === site.id_site).types_valorisation.includes('hydrogene')
                                    ?
                                    `<img title='${global._trans('map', 'hydrogeneValorisation')}' src='/media/images/2023imgs/hydro.png' alt="a picture" style="border-radius: 8px;object-fit: cover; margin-right:10px;"/>`
                                    :
                                    ''
                                }
                                ${siteAlll.find((el) => el.id_site === site.id_site).types_valorisation
                                    && siteAlll.find((el) => el.id_site === site.id_site).types_valorisation.includes('thermique')
                                    ?
                                    `<img title='${global._trans('map', 'thermicValorisation')}' src='/media/images/2023imgs/thermique.png' alt="a picture" width="34" height="34" style="border-radius: 8px;object-fit: cover;margin-right:10px;"/>`
                                    :
                                    ''
                                }
                                ${siteAlll.find((el) => el.id_site === site.id_site).types_valorisation
                                    && siteAlll.find((el) => el.id_site === site.id_site).types_valorisation.includes('electric')
                                    ?
                                    `<img title='${global._trans('map', 'electricValorisation')}' src='/media/images/2023imgs/elec.png' alt="a picture" style="border-radius: 8px;object-fit: cover;"/>`
                                    :
                                    ''
                                }
                                    </div>
                                </div>`
                                : ''}
                        </div>
                        `)

                    popup.on('open', () => {
                        let button = document.querySelector('div[id="btnPlusDtsMarkerCard"][data-idAccess="' + site.id_access + '"][data-idSite="' + site.id_site + '"]');
                        button.onclick = () => {
                            // popup.remove()
                            this.onMapClick(site.id_access, site.id_site)
                        }
                    })
                    popup.on('close', () => {
                        this.isPopupOpened = false
                    });
                    const el = document.createElement('div');
                    if (site.iconType === 'TMB') {
                        el.className = 'marker markerTmb';
                    }
                    if (site.iconType === 'ISDND') {
                        el.className = 'marker markerIsdnd';
                    }
                    if (site.iconType === 'UVE') {
                        let text = document.createTextNode(siteAlll.find((el) => el.id_site === site.id_site).nb_lines || 0);
                        el.className = `marker markerUve-${siteAlll.find((el) => el.id_site === site.id_site).types_valorisation_code}`;
                        el.appendChild(text);
                    }
                    if (site.iconType === 'TRI') {
                        el.className = 'marker markerTri';
                    }
                    el.addEventListener('click', (event) => {
                        event.stopPropagation()
                        this.openMarker(site)
                    });
                    el.addEventListener('mouseenter', () => {
                        if (!this.isPopupOpened) {
                            this.openMarkerImmediatly(site)
                        }
                    });
                    el.addEventListener('mouseleave', () => {
                        if (!this.isPopupOpened) {
                            this.markers.forEach(mark => {
                                if (mark.marker._popup.isOpen()) {
                                    mark.marker.togglePopup()
                                }
                            })
                        }
                    });
                    var marker = new this.scopeMap.Marker(el)
                        .setLngLat([site.lang, site.lat])
                        .setPopup(popup)
                        .addTo(this.map);
                    this.markers.push({ marker: marker })
                    // var marker = new this.scopeMap.Marker({
                    // color: this.colors[site.iconType.toLowerCase()]
                    // })
                })
            }
        }
    }
    componentDidMount() {
        if (!this.props.dataFetched) {
            this.props.AllLocaleInfo()
        }
        this.props.fetchAllDepartemnts('', '')
        loadLMaps(this.callback)
    }
    callback() {
        this.scopeMap = window.mapboxgl
        this.scopeMap.accessToken = process.env.REACT_APP_MAP_KEY
        this.map = new this.scopeMap.Map({
            container: 'map_sites', // container ID
            style: 'mapbox://styles/mapbox/streets-v11', // style URL
            center: [6, 46.89],
            zoom: 4.5,
            maxZoom: 20,
            minZoom: 1.7,
            minPitch: 10
        });
        this.map.addControl(new MapboxLanguage({
            defaultLanguage: 'fr'
        }));
        // this.map.on('load', () => {
        //     this.map.addSource('country-boundaries-simplified', {
        //         type: 'vector',
        //         url: 'mapbox://examples.countries-simplification'
        //     });
        //     this.map.addLayer({
        //         'id': 'countries-simplification-data',
        //         'type': 'line',
        //         'source': 'country-boundaries-simplified',
        //         'source-layer': 'countries_polygons',
        //         'layout': {
        //             'line-join': 'round',
        //             'line-cap': 'round'
        //         },
        //         'paint': {
        //             'line-color': '#ff69b4',
        //             'line-width': 1
        //         }
        //     });
        // });
        // this.map.on('zoomend', this.handleFetchBound);
        //this.map.on('moveend', this.handleFetchBound);
        this.handleFetchBound()
    }
    openMarker(siteInfo, fly = false) {
        this.markers.forEach(mark => {
            if (mark.marker._popup.isOpen()) {
                mark.marker.togglePopup()
            }
        })
        const mrk = this.markers.find((el) => el.marker._lngLat.lat === Number(siteInfo.lat) && el.marker._lngLat.lng === Number(siteInfo.lang)).marker
        if (mrk) {
            this.isPopupOpened = true
            if (fly) {
                this.map.flyTo({
                    center: [Number(siteInfo.lang), Number(siteInfo.lat)],
                    essential: true
                })
                setTimeout(() => {
                    mrk.togglePopup();
                }, 400);
            } else {
                mrk.togglePopup();
            }
        }
    }
    openMarkerImmediatly(siteInfo) {
        this.markers.forEach(mark => {
            if (mark.marker._popup.isOpen()) {
                mark.marker.togglePopup()
            }
        })
        const mrk = this.markers.find((el) => el?.marker?._lngLat?.lat === Number(siteInfo?.lat) && el?.marker?._lngLat?.lng === Number(siteInfo?.lang))?.marker
        if (mrk) {
            mrk.togglePopup()
        }
    }
    handleCallSearch(val) {
        if (val) {
            this.setState({
                orderBY: val.target.value
            })
        }
        const dt = { ...this.state }
        delete dt.sitesArray
        delete dt.showFIlter
        delete dt.listSide
        delete dt.currentStyle
        delete dt.groupsListData
        delete dt.showDrawer
        delete dt.modeGestionData
        delete dt.amobeData
        delete dt.traitementFumeeData
        delete dt.typeEquipementData
        if (this.state.search) {
            dt.search = this.state.search
        } else {
            delete dt.search
        }
        if (dt.asCapacitereglementaire[0] === 0 && dt.asCapacitereglementaire[1] === 1000000) {
            delete dt.asCapacitereglementaire
        }
        if (!dt.asCategory.length) {
            delete dt.asCategory
            delete dt.asCapacitereglementaire
        } else {
            if (!dt.asCategory.includes('TRI') && !dt.asCategory.includes('ISDND') && !dt.asCategory.includes('UVE')) {
                delete dt.asCapacitereglementaire
            }
        }
        if (!dt.asChaleurVendueSiteCheck) {
            delete dt.asChaleurVendueSiteCheck
        }
        if (dt.asContractAvantApres === 'all') {
            delete dt.asContractAvantApres
            delete dt.asContractDate
            delete dt.asContractDateEnd
            delete dt.asContractDateCheck
        }
        if (dt.asContractAvantApres !== 'entre') {
            delete dt.asContractDateEnd
        }
        if (!dt.asContractDateCheck) {
            delete dt.asContractDateCheck
        }
        if (!dt.asContractDate) {
            delete dt.asContractAvantApres
            delete dt.asContractDate
            delete dt.asContractDateEnd
            delete dt.asContractDateCheck
        }
        if (!dt.asElectriciteVendueSiteCheck) {
            delete dt.asElectriciteVendueSiteCheck
        }
        if (dt.asRegion.includes('all') || !dt.asRegion.length) {
            delete dt.asRegion
        }
        if (dt.asDepartement.includes('all') || !dt.asDepartement.length) {
            delete dt.asDepartement
        }
        if (dt.asGroupeExploitant.includes('all') || !dt.asGroupeExploitant.length) {
            delete dt.asGroupeExploitant
        }
        if (dt.asModeGestion.includes('all') || !dt.asModeGestion.length) {
            delete dt.asModeGestion
        }
        if (dt.asAmobe.includes('all') || !dt.asAmobe.length) {
            delete dt.asAmobe
        }
        if (dt.asNombreDeLignes.includes('all') || !dt.asNombreDeLignes.length) {
            delete dt.asNombreDeLignes
        }
        if (dt.asTechnologieDeTurbine.includes('all') || !dt.asTechnologieDeTurbine.length) {
            delete dt.asTechnologieDeTurbine
        }
        if (dt.asVoieDeTraitementDesFumees.includes('all') || !dt.asVoieDeTraitementDesFumees.length) {
            delete dt.asVoieDeTraitementDesFumees
        }
        // if (dt.asChaleurVendueSite[0] === 0 && dt.asChaleurVendueSite[1] === 1000) {
        //     delete dt.asChaleurVendueSite
        // }
        // if (dt.asElectriciteVendueSite[0] === 0 && dt.asElectriciteVendueSite[1] === 1000) {
        //     delete dt.asElectriciteVendueSite
        // }
        if (!dt.asTypeDeValorisation) {
            delete dt.asTypeDeValorisation

            delete dt.asChaleurVendueSite
            delete dt.asChaleurVendueSiteCheck

            delete dt.asElectriciteVendueSite
            delete dt.asElectriciteVendueSiteCheck
        } else {
            if (dt.asTypeDeValorisation === 'electric') {
                delete dt.asChaleurVendueSite
                delete dt.asChaleurVendueSiteCheck
            }
            if (dt.asTypeDeValorisation === 'hydrogene') {
                delete dt.asElectriciteVendueSite
                delete dt.asElectriciteVendueSiteCheck
            }
            if (dt.asTypeDeValorisation === 'thermique') {
                delete dt.asElectriciteVendueSite
                delete dt.asElectriciteVendueSiteCheck
            }
            // if (dt.asTypeDeValorisation === 'thermiqueANDelectric') {
            //     // delete dt.asElectriciteVendueSite
            //     // delete dt.asElectriciteVendueSiteCheck
            // }
        }
        if (val) {
            dt.orderBY = val.target.value
        }
        if (dt.orderBY === 'denomination') {
            delete dt.orderBY
        }
        this.props.history.push({ search: qs.stringify(dt) })
        this.callSearchFun(dt)
    }
    callSearchFun(dt) {

        this.props.fetchSitesMapNew(dt)
        this.setState({
            showFIlter: 1
        })
    }
    handleFetchBound() {
        let bounds = this.map.getBounds()
        if (bounds && bounds._ne && bounds._sw) {
            // const { lat: latmax, lng: lngmax } = bounds._ne
            // const { lat: latmin, lng: lngmin } = bounds._sw
            // this.props.fetchSitesMapNew(latmin + "," + latmax, lngmin + "," + lngmax, this.props.filterMap)
            this.props.getGroupes()
            this.handleCallSearch()

        }
    }
    handleDrawer() {
        let prevState = this.state.showDrawer
        let prevStatelefstsidebar = this.state.listSide
        this.setState({
            listSide: !prevState,
            showDrawer: !prevStatelefstsidebar
        })
    }
    onMapClick(idAccess, idSite) {
        // this.setState({
        //     showDrawer: true
        // })
        // this.props.fetchDetailShare(idAccess, idSite)
        window.open(`/site/${idSite}/${idAccess}`, '_blank');
    }
    changeMapStyle = (style) => {
        if (this.state.currentStyle !== style) {
            if (style === 1) {
                this.map.setStyle('mapbox://styles/mapbox/satellite-streets-v12');
            }
            if (style === 2) {
                this.map.setStyle('mapbox://styles/mapbox/streets-v11');
            }
            if (style === 3) {
                this.map.setStyle('mapbox://styles/mapbox/outdoors-v12');
            }
            if (style === 4) {
                this.map.setStyle('mapbox://styles/mapbox/streets-v12');
            }
            if (style === 5) {
                this.map.setStyle('mapbox://styles/mapbox/dark-v11');
            }
            this.setState({
                currentStyle: style
            })
        }
    }
    changeZoom = (zoomSize) => {
        const curZoom = this.map.getZoom()
        if (zoomSize === 'in') {
            this.map.setZoom((curZoom + 0.5))
        } else {
            this.map.setZoom((curZoom - 0.5))
        }
    }

    handleReset(e) {
        this.setState({
            orderBY: 'denomination',
            search: '',
            asCategory: [],
            asModeGestion: ['all'],
            asAmobe: ['all'],
            asGroupeExploitant: ['all'],
            asDepartement: ['all'],
            asRegion: ['all'],
            asNombreDeLignes: ['all'],
            asTechnologieDeTurbine: ['all'],
            asVoieDeTraitementDesFumees: ['all'],
            asTypeDeValorisation: '',
            asChaleurVendueSite: [0, 1000000],
            asChaleurVendueSiteCheck: false,
            asElectriciteVendueSite: [0, 1000000],
            asElectriciteVendueSiteCheck: false,
            asCapacitereglementaire: [0, 1000000],
            asContractAvantApres: 'all',
            asContractDate: null,
            asContractDateCheck: false,
        })

        this.props.history.push({ search: '' })
        this.props.fetchSitesMapNew({})
        // this.setState({
        //     showFIlter: 1
        // })
    }
    justUve() {
        this.props.fetchSitesMapNew({ asCategory: ['UVE'] })
        this.props.history.push({ search: '' })
    }
    justGestionPrev() {
        this.props.fetchSitesMapNew({ asModeGestion: ['Gestion privée'] })
        this.props.history.push({ search: '' })
    }
    asUser() {
        return (
            <>
                <div className="px-3 mt-5" style={{ overflowY: 'auto', height: 'calc(100vh - 230px)', borderBottom: '0px solid white' }}>
                    {/* SITE CATEGORY */}
                    <div className="row row-sage py-8">
                        {/* <label className="fs-5 mb-5 form-label text-white">{global._trans("fields", "categorieSite")}</label> */}
                        <div className="col-md-12 d-flex justify-content-start align-items-center gap-10">
                            <div className={`d-flex justify-content-center align-items-center flex-column gap-4 px-7 py-2 rounded
                            `}
                                style={{
                                    backgroundColor: this.state.asCategory.includes('UVE') ? '#f6ff001f' : 'transparent',
                                    border: this.state.asCategory.includes('UVE') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (!this.state.asCategory.includes('UVE')) {
                                        this.setState(state => {
                                            const asCategory = [...state.asCategory, 'UVE']
                                            return {
                                                asCategory,
                                            }
                                        })
                                    } else {
                                        this.setState(state => {
                                            const asCategory = state.asCategory.filter((el) => el !== 'UVE')
                                            return {
                                                asCategory,
                                            }
                                        })

                                    }
                                }}>
                                <div className='markerSearch markerUve'></div>
                                <h4 className='text-white m-0'>UVE</h4>
                            </div>
                            <div className={`d-flex justify-content-center align-items-center flex-column gap-4 px-7 py-2 rounded
                            ${this.state.asCategory === 'TRI' && 'border-warning'}
                            `}
                                style={{
                                    backgroundColor: this.state.asCategory.includes('TRI') ? '#f6ff001f' : 'transparent',
                                    border: this.state.asCategory.includes('TRI') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (!this.state.asCategory.includes('TRI')) {
                                        this.setState(state => {
                                            const asCategory = [...state.asCategory, 'TRI']
                                            return {
                                                asCategory,
                                            }
                                        })
                                    } else {
                                        this.setState(state => {
                                            const asCategory = state.asCategory.filter((el) => el !== 'TRI')
                                            return {
                                                asCategory,
                                            }
                                        })

                                    }
                                }}>
                                <div className='markerSearch markerTri' ></div>
                                <h4 className='text-white m-0'>TRI</h4>
                            </div>
                            <div className={`d-flex justify-content-center align-items-center flex-column gap-4 px-7 py-2 rounded
                            ${this.state.asCategory === 'TMB' && 'border-warning'}
                            `}
                                style={{
                                    backgroundColor: this.state.asCategory.includes('TMB') ? '#f6ff001f' : 'transparent',
                                    border: this.state.asCategory.includes('TMB') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (!this.state.asCategory.includes('TMB')) {
                                        this.setState(state => {
                                            const asCategory = [...state.asCategory, 'TMB']
                                            return {
                                                asCategory,
                                            }
                                        })
                                    } else {
                                        this.setState(state => {
                                            const asCategory = state.asCategory.filter((el) => el !== 'TMB')
                                            return {
                                                asCategory,
                                            }
                                        })

                                    }
                                }}>
                                <div className='markerSearch markerTmb' ></div>
                                <h4 className='text-white m-0'>TMB</h4>
                            </div>
                            <div className={`d-flex justify-content-center align-items-center flex-column gap-4 px-5 py-2 rounded
                            ${this.state.asCategory === 'ISDND' && 'border-warning'}
                            `}
                                style={{
                                    backgroundColor: this.state.asCategory.includes('ISDND') ? '#f6ff001f' : 'transparent',
                                    border: this.state.asCategory.includes('ISDND') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (!this.state.asCategory.includes('ISDND')) {
                                        this.setState(state => {
                                            const asCategory = [...state.asCategory, 'ISDND']
                                            return {
                                                asCategory,
                                            }
                                        })
                                    } else {
                                        this.setState(state => {
                                            const asCategory = state.asCategory.filter((el) => el !== 'ISDND')
                                            return {
                                                asCategory,
                                            }
                                        })

                                    }
                                }}>                               <div className='markerSearch markerIsdnd' ></div>
                                <h4 className='text-white m-0'>ISDND</h4>
                            </div>
                        </div>
                    </div>
                    {this.state.asCategory.length ?
                        <>

                            <div className='row-sage'>
                                <div className="row g-8 mb-3">
                                    <div className="col-md-12 select-sage">
                                        <label className="fs-6 form-label text-white">{global._trans("fields", "modeGestion")}</label>
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            allowClear
                                            placeholder={global._trans("fields", "modeGestion")}
                                            style={{ width: '100%', overflow: 'auto', padding: '0', outline: 'none', backgroundColor: '#202531' }}
                                            optionFilterProp="label"
                                            size="small"
                                            filterOption={(input, Option) =>
                                                Option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                            value={this.state.asModeGestion}
                                            onChange={(selectedValue) => {
                                                if (selectedValue[selectedValue.length - 1] === 'all') {
                                                    this.setState({ asModeGestion: ['all'] })
                                                } else {
                                                    this.setState({ asModeGestion: selectedValue.filter((el) => el !== 'all') })
                                                }
                                            }}
                                        >
                                            <Select.Option value='all' disabled={false}>Tout</Select.Option>
                                            {this.state.modeGestionData.map((el, ind) => (
                                                <Select.Option key={ind} value={el.label} disabled={false}>{el.label}</Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                <div className="row g-8 mb-3">
                                    <div className="col-md-12 select-sage">
                                        <label className="fs-6 form-label text-white">Groupe exploitant</label>
                                        <Select
                                            mode="multiple"
                                            filterOption={(input, Option) =>
                                                Option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                            showSearch
                                            size="small"
                                            allowClear
                                            placeholder='Groupe exploitant'
                                            style={{ width: '100%', overflow: 'auto', padding: '0', outline: 'none', backgroundColor: '#202531' }}
                                            optionFilterProp="label"
                                            value={this.state.asGroupeExploitant}
                                            onChange={(selectedValue) => {
                                                if (selectedValue[selectedValue.length - 1] === 'all') {
                                                    this.setState({ asGroupeExploitant: ['all'] })
                                                } else {
                                                    this.setState({ asGroupeExploitant: selectedValue.filter((el) => el !== 'all') })
                                                }
                                            }}
                                        >
                                            <Select.Option value='all' disabled={false}>Tout</Select.Option>
                                            {this.state.groupsListData.map((el, ind) => (
                                                <Select.Option key={ind} value={el.value} disabled={false}>{el.label}</Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                <div className="row g-8 mb-3">
                                    <div className="col-md-12 select-sage">
                                        <RegionSearch placeholder='Région' onChange={(selectedValue) => {
                                            if (selectedValue[selectedValue.length - 1] === 'all') {
                                                this.setState({ asRegion: ['all'] })
                                            } else {
                                                this.setState({ asRegion: selectedValue.filter((el) => el !== 'all') })
                                            }
                                        }} value={this.state.asRegion} type='reg' />
                                    </div>
                                </div>
                                <div className="row g-8 mb-5">
                                    <div className="col-md-12 select-sage">
                                        <RegionSearch placeholder='Département' onChange={(selectedValue) => {
                                            if (selectedValue[selectedValue.length - 1] === 'all') {
                                                this.setState({ asDepartement: ['all'] })
                                            } else {
                                                this.setState({ asDepartement: selectedValue.filter((el) => el !== 'all') })
                                            }
                                        }} value={this.state.asDepartement} type='dep' />
                                    </div>
                                </div>
                                {(this.state.asCategory.includes("ISDND") || this.state.asCategory.includes("TRI") || this.state.asCategory.includes("UVE")) && (
                                    <div className="row g-8 ">
                                        <div className="col-md-12 select-sage">
                                            <label className="fs-6 form-label text-white">Capacité règlementaire</label>
                                            <div className='d-flex justify-content-between align-items-center gap-6 mb-0 mx-6'>
                                                <input type="number" onChange={(e) => {
                                                    this.setState({ asCapacitereglementaire: [e.target.value, this.state.asCapacitereglementaire[1]] })

                                                }} className="form-control form-control-solid border border-2 shadow-xs ps-3 inputGeneralSearchOK"
                                                    min={0}
                                                    step={10000}
                                                    max={this.state.asCapacitereglementaire[1]}
                                                    name="asCapacitereglementaire[0]" value={this.state.asCapacitereglementaire[0]} id='' />
                                                <input
                                                    type="number"
                                                    min={this.state.asCapacitereglementaire[0]}
                                                    max={1000000}
                                                    step={10000}
                                                    onChange={(e) => {
                                                        this.setState({ asCapacitereglementaire: [this.state.asCapacitereglementaire[0], e.target.value] })

                                                    }} className="form-control form-control-solid border border-2 shadow-xs ps-3 inputGeneralSearchOK" name="asCapacitereglementaire[1]" value={this.state.asCapacitereglementaire[1]} id='' />
                                                {/* <p className='text-white m-0' style={{ fontWeight: '600', fontSize: '15px' }}>{this.state.asCapacitereglementaire ? this.state.asCapacitereglementaire[0] : ''}</p> */}
                                                {/* <p className='text-white m-0' style={{ fontWeight: '600', fontSize: '15px' }}>{this.state.asCapacitereglementaire ? this.state.asCapacitereglementaire[1] : ''}</p> */}
                                            </div>
                                            <div className='d-flex justify-content-center align-items-center w-100 mb-2'>
                                                <Slider
                                                    min={0}
                                                    max={1000000}
                                                    style={{ width: '90%' }}
                                                    range={{
                                                        draggableTrack: true,
                                                    }}
                                                    onChange={(value) => {
                                                        this.setState({ asCapacitereglementaire: value })

                                                    }}
                                                    value={this.state.asCapacitereglementaire}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='row-sage'>
                                <div className="row g-8 mb-3">
                                    <div className="col-md-12 select-sage">
                                        <label className="fs-6 form-label text-white">{global._trans("fields", "amo")}</label>
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            allowClear
                                            placeholder={global._trans("fields", "amo")}
                                            style={{ width: '100%', overflow: 'auto', padding: '0', outline: 'none', backgroundColor: '#202531' }}
                                            optionFilterProp="label"
                                            size="small"
                                            filterOption={(input, Option) =>
                                                Option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                            value={this.state.asAmobe}
                                            onChange={(selectedValue) => {
                                                if (selectedValue[selectedValue.length - 1] === 'all') {
                                                    this.setState({ asAmobe: ['all'] })
                                                } else {
                                                    this.setState({ asAmobe: selectedValue.filter((el) => el !== 'all') })
                                                }
                                            }}
                                        >
                                            <Select.Option value='all' disabled={false}>Tout</Select.Option>
                                            {this.state.amobeData.map((el, ind) => (
                                                <Select.Option key={ind} value={el.label} disabled={false}>{el.label}</Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="row-sage">
                                <div className="select-sage m-0 mb-3">
                                    <label className="fs-6 form-label text-white">Date de fin de contrat</label>
                                    <Select
                                        // mode="multiple"
                                        showSearch
                                        allowClear
                                        placeholder={'avant/après'}
                                        style={{ width: '100%', padding: '0', outline: 'none', backgroundColor: '#202531' }}
                                        optionFilterProp="label"
                                        size="small"
                                        filterOption={(input, Option) =>
                                            Option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                        value={this.state.asContractAvantApres}
                                        onChange={(selectedValue) => {
                                            this.setState({ asContractAvantApres: selectedValue })
                                        }}
                                    >
                                        <Select.Option value='all' disabled={false}>Choisir avant/après</Select.Option>
                                        <Select.Option value='avant' disabled={false}>Avant</Select.Option>
                                        <Select.Option value='entre' disabled={false}>Entre</Select.Option>
                                        <Select.Option value='apres' disabled={false}>Après</Select.Option>
                                    </Select>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-12 select-sage m-0">
                                        <div className="row" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                            <div className={`${this.state.asContractAvantApres === 'entre' ? 'col-md-12' : 'col-md-12'} select-sage`}>
                                                <Form.Group as={Col} className="mb-3">
                                                    <label className="fs-6 form-label text-white">
                                                        {this.state.asContractAvantApres !== 'avant' && this.state.asContractAvantApres !== 'apres' && "Date"}
                                                    </label>
                                                    <Form.Control type="date" name="asContractDate" className='sage-date'
                                                        onChange={(selectedValue) => {
                                                            this.setState({ asContractDate: selectedValue.target.value })
                                                        }}
                                                        value={this.state.asContractDate ? this.state.asContractDate : ''} placeholder={'choisir une date'} />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">

                                            {this.state.asContractAvantApres === 'entre' && (
                                                <div className="col-md-12 select-sage">
                                                    <Form.Group as={Col} className="mb-3">
                                                        <label className="fs-6 form-label text-white">Et</label>
                                                        <Form.Control type="date" name="asContractDate" className='sage-date'
                                                            onChange={(selectedValue) => {
                                                                this.setState({ asContractDateEnd: selectedValue.target.value })
                                                            }}
                                                            value={this.state.asContractDateEnd ? this.state.asContractDateEnd : ''} placeholder={'choisir une date'} />
                                                    </Form.Group>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Checkbox
                                            style={{ color: 'white', userSelect: 'none' }}
                                            checked={this.state.asContractDateCheck} onChange={(e) => {
                                                this.setState({ asContractDateCheck: e.target.checked })

                                            }}>Inclure les sites sans date de fin contrat</Checkbox>
                                    </div>
                                </div>
                            </div>

                            {this.state.asCategory.includes("UVE") && (
                                <div className="mb-6 row-sage-uve">
                                    <h3 style={{ color: '#56befb', textAlign: 'center', marginBottom: '15px' }}>Filtres pour Les sites UVE</h3>
                                    <div className="row row-sage-uve-inside">
                                        <label className="fs-5 mb-4 form-label text-white p-0">Nombre de lignes</label>
                                        <div className="col-md-12 d-flex justify-content-center align-items-center gap-8">
                                            <div className={`d-flex justify-content-center align-items-center flex-column  rounded-circle 
                            `}
                                                style={{
                                                    backgroundColor: this.state.asNombreDeLignes.includes('all') ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asNombreDeLignes.includes('all') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                    width: '60px',
                                                    height: '60px',
                                                }}
                                                // onClick={() => { this.setState({ asNombreDeLignes: 'all' }) }}
                                                onClick={() => {
                                                    if (!this.state.asNombreDeLignes.includes('all')) {
                                                        this.setState({
                                                            asNombreDeLignes: ['all']
                                                        })
                                                    } else {
                                                        this.setState({
                                                            asNombreDeLignes: []
                                                        })
                                                    }
                                                }}>
                                                <h5 className='text-white m-0'>Tout</h5>
                                            </div>
                                            <div className={`d-flex justify-content-center align-items-center flex-column  rounded-circle 
                            `}
                                                style={{
                                                    backgroundColor: this.state.asNombreDeLignes.includes('1') ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asNombreDeLignes.includes('1') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                    width: '50px',
                                                    height: '50px',
                                                }}
                                                // onClick={() => { this.setState({ asNombreDeLignes: '1' }) }}>
                                                onClick={() => {
                                                    if (!this.state.asNombreDeLignes.includes('1')) {
                                                        if (this.state.asNombreDeLignes.includes('all')) {
                                                            this.setState(state => {
                                                                const asNombreDeLignesw = state.asNombreDeLignes.filter((el) => el !== 'all')
                                                                const asNombreDeLignes = [...asNombreDeLignesw, '1']
                                                                return {
                                                                    asNombreDeLignes,
                                                                }
                                                            })
                                                        } else {
                                                            this.setState(state => {
                                                                const asNombreDeLignes = [...state.asNombreDeLignes, '1']
                                                                return {
                                                                    asNombreDeLignes,
                                                                }
                                                            })
                                                        }
                                                    } else {
                                                        this.setState(state => {
                                                            const asNombreDeLignes = state.asNombreDeLignes.filter((el) => el !== '1')
                                                            return {
                                                                asNombreDeLignes,
                                                            }
                                                        })

                                                    }
                                                }}>
                                                <h3 className='text-white m-0'>1</h3>
                                            </div>
                                            <div className={`d-flex justify-content-center align-items-center flex-column  rounded-circle 
                            `}
                                                style={{
                                                    backgroundColor: this.state.asNombreDeLignes.includes('2') ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asNombreDeLignes.includes('2') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                    width: '50px',
                                                    height: '50px',
                                                }}
                                                // onClick={() => { this.setState({ asNombreDeLignes: '2' }) }}>
                                                onClick={() => {
                                                    if (!this.state.asNombreDeLignes.includes('2')) {
                                                        if (this.state.asNombreDeLignes.includes('all')) {
                                                            this.setState(state => {
                                                                const asNombreDeLignesw = state.asNombreDeLignes.filter((el) => el !== 'all')
                                                                const asNombreDeLignes = [...asNombreDeLignesw, '2']
                                                                return {
                                                                    asNombreDeLignes,
                                                                }
                                                            })
                                                        } else {
                                                            this.setState(state => {
                                                                const asNombreDeLignes = [...state.asNombreDeLignes, '2']
                                                                return {
                                                                    asNombreDeLignes,
                                                                }
                                                            })
                                                        }
                                                    } else {
                                                        this.setState(state => {
                                                            const asNombreDeLignes = state.asNombreDeLignes.filter((el) => el !== '2')
                                                            return {
                                                                asNombreDeLignes,
                                                            }
                                                        })

                                                    }
                                                }}>
                                                <h3 className='text-white m-0'>2</h3>
                                            </div>
                                            <div className={`d-flex justify-content-center align-items-center flex-column  rounded-circle 
                            `}
                                                style={{
                                                    backgroundColor: this.state.asNombreDeLignes.includes('3') ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asNombreDeLignes.includes('3') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                    width: '50px',
                                                    height: '50px',
                                                }}
                                                // onClick={() => { this.setState({ asNombreDeLignes: '3' }) }}>
                                                onClick={() => {
                                                    if (!this.state.asNombreDeLignes.includes('3')) {
                                                        if (this.state.asNombreDeLignes.includes('all')) {
                                                            this.setState(state => {
                                                                const asNombreDeLignesw = state.asNombreDeLignes.filter((el) => el !== 'all')
                                                                const asNombreDeLignes = [...asNombreDeLignesw, '3']
                                                                return {
                                                                    asNombreDeLignes,
                                                                }
                                                            })
                                                        } else {
                                                            this.setState(state => {
                                                                const asNombreDeLignes = [...state.asNombreDeLignes, '3']
                                                                return {
                                                                    asNombreDeLignes,
                                                                }
                                                            })
                                                        }
                                                    } else {
                                                        this.setState(state => {
                                                            const asNombreDeLignes = state.asNombreDeLignes.filter((el) => el !== '3')
                                                            return {
                                                                asNombreDeLignes,
                                                            }
                                                        })

                                                    }
                                                }}>
                                                <h3 className='text-white m-0'>3</h3>
                                            </div>
                                            <div className={`d-flex justify-content-center align-items-center flex-column  rounded-circle 
                            `}
                                                style={{
                                                    backgroundColor: this.state.asNombreDeLignes.includes('4') ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asNombreDeLignes.includes('4') ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                    width: '50px',
                                                    height: '50px',
                                                }}
                                                // onClick={() => { this.setState({ asNombreDeLignes: '4' }) }}>
                                                onClick={() => {
                                                    if (!this.state.asNombreDeLignes.includes('4')) {
                                                        if (this.state.asNombreDeLignes.includes('all')) {
                                                            this.setState(state => {
                                                                const asNombreDeLignesw = state.asNombreDeLignes.filter((el) => el !== 'all')
                                                                const asNombreDeLignes = [...asNombreDeLignesw, '4']
                                                                return {
                                                                    asNombreDeLignes,
                                                                }
                                                            })
                                                        } else {
                                                            this.setState(state => {
                                                                const asNombreDeLignes = [...state.asNombreDeLignes, '4']
                                                                return {
                                                                    asNombreDeLignes,
                                                                }
                                                            })
                                                        }
                                                    } else {
                                                        this.setState(state => {
                                                            const asNombreDeLignes = state.asNombreDeLignes.filter((el) => el !== '4')
                                                            return {
                                                                asNombreDeLignes,
                                                            }
                                                        })

                                                    }
                                                }}>
                                                <h3 className='text-white m-0'>4</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-6 row-sage-uve-inside">
                                        <div className="col-md-12 select-sage p-0 g-0">
                                            <label className="fs-6 form-label text-white">Technologie de turbine</label>
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                filterOption={(input, Option) =>
                                                    Option.children.toLowerCase().includes(input.toLowerCase())
                                                }
                                                allowClear
                                                placeholder='Technologie de turbine'
                                                style={{ width: '100%', overflow: 'auto', padding: '0', outline: 'none', backgroundColor: '#202531' }}
                                                optionFilterProp="label"
                                                size="small"
                                                value={this.state.asTechnologieDeTurbine}
                                                onChange={(selectedValue) => {
                                                    if (selectedValue[selectedValue.length - 1] === 'all') {
                                                        this.setState({ asTechnologieDeTurbine: ['all'] })
                                                    } else {
                                                        this.setState({ asTechnologieDeTurbine: selectedValue.filter((el) => el !== 'all') })
                                                    }
                                                }}
                                            >
                                                <Select.Option value='all' disabled={false}>Tout</Select.Option>
                                                {this.state.typeEquipementData.map((el, ind) => (
                                                    <Select.Option key={ind} value={el.value} disabled={false}>{el.label}</Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className="col-md-12 select-sage p-0">
                                            <label className="fs-6 form-label text-white">Voie de traitement des fumées</label>
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                allowClear
                                                filterOption={(input, Option) =>
                                                    Option.children.toLowerCase().includes(input.toLowerCase())
                                                }
                                                placeholder={'Voie de traitement des fumées'}
                                                style={{ width: '100%', overflow: 'auto', padding: '0', outline: 'none', backgroundColor: '#202531' }}
                                                optionFilterProp="label"
                                                size="small"
                                                value={this.state.asVoieDeTraitementDesFumees}
                                                onChange={(selectedValue) => {
                                                    if (selectedValue[selectedValue.length - 1] === 'all') {
                                                        this.setState({ asVoieDeTraitementDesFumees: ['all'] })
                                                    } else {
                                                        this.setState({ asVoieDeTraitementDesFumees: selectedValue.filter((el) => el !== 'all') })
                                                    }
                                                }}
                                            >
                                                <Select.Option value='all' disabled={false}>Tout</Select.Option>
                                                {this.state.traitementFumeeData.map((el, ind) => (
                                                    <Select.Option key={ind} value={el.value} disabled={false}>{el.label}</Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="row row-sage-uve-inside">
                                        <label className="fs-6 mb-4 form-label text-white p-0">Type de valorisation</label>
                                        <div className="col-md-12 d-flex justify-content-center align-items-center gap-5">
                                            <div className={`d-flex justify-content-center align-items-center p-4 rounded`}
                                                style={{
                                                    backgroundColor: this.state.asTypeDeValorisation === 'hydrogene' ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asTypeDeValorisation === 'hydrogene' ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    if (this.state.asTypeDeValorisation === 'hydrogene') {
                                                        this.setState({ asTypeDeValorisation: '' })
                                                    } else {
                                                        this.setState({ asTypeDeValorisation: 'hydrogene' })
                                                    }
                                                }
                                                }>
                                                <img src='/media/images/2023imgs/hydro.png' alt="a picture" width='35' />
                                            </div>
                                            <div className={`d-flex justify-content-center align-items-center p-4 rounded`}
                                                style={{
                                                    backgroundColor: this.state.asTypeDeValorisation === 'electric' ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asTypeDeValorisation === 'electric' ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    if (this.state.asTypeDeValorisation === 'electric') {
                                                        this.setState({ asTypeDeValorisation: '' })
                                                    } else {
                                                        this.setState({ asTypeDeValorisation: 'electric' })
                                                    }
                                                }
                                                }>
                                                <img src='/media/images/2023imgs/elec.png' alt="a picture" width='35' />
                                            </div>
                                            <div className={`d-flex justify-content-center align-items-center p-4 rounded`}
                                                style={{
                                                    backgroundColor: this.state.asTypeDeValorisation === 'thermique' ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asTypeDeValorisation === 'thermique' ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    if (this.state.asTypeDeValorisation === 'thermique') {
                                                        this.setState({ asTypeDeValorisation: '' })
                                                    } else {
                                                        this.setState({ asTypeDeValorisation: 'thermique' })
                                                    }
                                                }
                                                }>
                                                <img src='/media/images/2023imgs/thermique.png' alt="a picture" width='35' />
                                            </div>
                                            <div className={`d-flex justify-content-center align-items-center p-4 gap-2 rounded`}
                                                style={{
                                                    backgroundColor: this.state.asTypeDeValorisation === 'thermiqueANDelectric' ? '#f6ff001f' : 'transparent',
                                                    border: this.state.asTypeDeValorisation === 'thermiqueANDelectric' ? '1px solid #FFC700' : '1px solid #b0afad66',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    if (this.state.asTypeDeValorisation === 'thermiqueANDelectric') {
                                                        this.setState({ asTypeDeValorisation: '' })
                                                    } else {
                                                        this.setState({ asTypeDeValorisation: 'thermiqueANDelectric' })
                                                    }
                                                }
                                                }>
                                                <img src='/media/images/2023imgs/elec.png' alt="a picture" width='35' />
                                                <img src='/media/images/2023imgs/thermique.png' alt="a picture" width='35' />
                                            </div>
                                        </div>
                                    </div>
                                    {(this.state.asTypeDeValorisation !== 'electric' && this.state.asTypeDeValorisation !== '') && (
                                        <div className="row g-8 mb-3 row-sage-uve-inside">
                                            <div className="col-md-12 select-sage">
                                                <label className="fs-6 form-label text-white">Chaleur vendue (MWh/an)</label>
                                                <div className='d-flex justify-content-center align-items-center gap-6 mb-2'>:
                                                    <p className='text-white m-0' style={{ fontWeight: '600', fontSize: '15px' }}>{this.state.asChaleurVendueSite ? this.state.asChaleurVendueSite[0] : ''}</p>
                                                    <Slider
                                                        min={0}
                                                        max={1000000}
                                                        style={{ width: '90%' }}
                                                        range={{
                                                            draggableTrack: true,
                                                        }}
                                                        onChange={(value) => {
                                                            this.setState({ asChaleurVendueSite: value })

                                                        }}
                                                        value={this.state.asChaleurVendueSite}
                                                    />
                                                    <p className='text-white m-0' style={{ fontWeight: '600', fontSize: '15px' }}>{this.state.asChaleurVendueSite ? this.state.asChaleurVendueSite[1] : ''}</p>
                                                </div>
                                                <Checkbox
                                                    style={{ color: 'white', userSelect: 'none' }}
                                                    checked={this.state.asChaleurVendueSiteCheck} onChange={(e) => {
                                                        this.setState({ asChaleurVendueSiteCheck: e.target.checked })

                                                    }}>Inclure sites avec donnée non disponible</Checkbox>
                                            </div>
                                        </div>
                                    )}
                                    {(this.state.asTypeDeValorisation === 'electric' || this.state.asTypeDeValorisation === 'thermiqueANDelectric') && (
                                        <div className="row g-8 row-sage-uve-inside">
                                            <div className="col-md-12 select-sage">
                                                <label className="fs-6 form-label text-white">Electricité vendue site (MWh/an)</label>
                                                <div className='d-flex justify-content-center align-items-center gap-6 mb-2'>
                                                    <p className='text-white m-0' style={{ fontWeight: '600', fontSize: '15px' }}>{this.state.asElectriciteVendueSite ? this.state.asElectriciteVendueSite[0] : ''}</p>
                                                    <Slider
                                                        min={0}
                                                        max={1000000}
                                                        style={{ width: '90%' }}
                                                        range={{
                                                            draggableTrack: true,
                                                        }}
                                                        onChange={(value) => {
                                                            this.setState({ asElectriciteVendueSite: value })

                                                        }}
                                                        value={this.state.asElectriciteVendueSite}
                                                    />
                                                    <p className='text-white m-0' style={{ fontWeight: '600', fontSize: '15px' }}>{this.state.asElectriciteVendueSite ? this.state.asElectriciteVendueSite[1] : ''}</p>
                                                </div>
                                                <Checkbox
                                                    style={{ color: 'white', userSelect: 'none' }}
                                                    checked={this.state.asElectriciteVendueSiteCheck} onChange={(e) => {
                                                        this.setState({ asElectriciteVendueSiteCheck: e.target.checked })

                                                    }}>Inclure sites avec donnée non disponible</Checkbox>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </> :
                        null
                    }
                    <div className="d-flex justify-content-center align-items-center row-sage py-5" style={{ position: 'absoluste', bottom: 0, left: 0, right: 0 }}>
                        <button type="button" onClick={this.handleReset} className="btn py-2 fw-bold btn-outline-danger mr-4 fs-6">{global._trans("header", "resetSearch")}</button>
                        <button type="button" onClick={() => this.handleCallSearch()} className="btn btn-outline-primary py-2 fs-6">{global._trans("header", "actionsSearch")}</button>
                    </div>
                </div >
            </>
        )
    }
    render() {
        const { laodingSitesMap, listSitesMap, regionstListed, siteAlll } = this.props
        return (
            <>
                <div className={`h-100 w-75 position-relative w-100`}>
                    {(!laodingSitesMap || laodingSitesMap !== 0) && (
                        <div className="position-absolute bg-black transition d-flex p-3 rounded cursor-pointer header_shadow" onClick={this.handleDrawer}
                            style={{ zIndex: 1031, top: 20, ...(this.state.showDrawer ? { left: "510px" } : { left: "20px" }) }}>
                            <span className="material-icons" style={{ color: 'white' }}>{this.state.showDrawer ? "west" : "east"}</span>
                        </div>
                    )}
                    {laodingSitesMap === 0 &&
                        <div className="position-absolute bg-white header_shadow px-4 py-2 rounded"
                            style={{ zIndex: 1031, top: 25, marginLeft: "calc(50% - 115.5px)" }}>
                            <strong>{global._trans("map", "loadingSites")}</strong>
                        </div>
                    }
                    <div className="d-flex position-absolute" style={{ zIndex: 401, top: 20, right: 10 }}>
                        <div className="btn-group border border-primary" role="group" aria-label="Basic example" style={{ borderRadius: '7px' }}>
                            <button type="button" onClick={() => this.changeMapStyle(2)} className={`btn btn-sm btn-${this.state.currentStyle === 2 ? 'primary' : 'light'}`}>Street</button>
                            <button type="button" onClick={() => this.changeMapStyle(1)} className={`btn btn-sm btn-${this.state.currentStyle === 1 ? 'primary' : 'light'}`}>Satellite</button>
                            <button type="button" onClick={() => this.changeMapStyle(3)} className={`btn btn-sm btn-${this.state.currentStyle === 3 ? 'primary' : 'light'}`}>Light</button>
                            <button type="button" onClick={() => this.changeMapStyle(4)} className={`btn btn-sm btn-${this.state.currentStyle === 4 ? 'primary' : 'light'}`}>outdoors</button>
                            <button type="button" onClick={() => this.changeMapStyle(5)} className={`btn btn-sm btn-${this.state.currentStyle === 5 ? 'primary' : 'light'}`}>Dark</button>
                        </div>
                    </div>
                    <div className="d-flex position-absolute" style={{ zIndex: 401, top: 70, right: 10 }}>
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button"
                                style={{ borderRadius: '4px' }}
                                onClick={() => {
                                    this.map.flyTo({
                                        center: [-61.27238249999999, 16.1922065],
                                        essential: true
                                    })
                                }} className={`btn btn-sm btn-dark mx-3`}>Guadeloupe</button>
                            <button type="button"
                                style={{ borderRadius: '4px' }}
                                onClick={() => {
                                    this.map.flyTo({
                                        center: [55.5364801, -21.1307379],
                                        essential: true
                                    })
                                }} className={`btn btn-sm btn-dark`}>La réunion</button>
                        </div>
                    </div>
                    <div className="d-flex position-absolute flex-column boxbtnZoom" style={{ zIndex: 401, bottom: 20, right: 20 }}>
                        <button type="button" onClick={() => this.changeZoom('in')} className={`btnZoom`}>+</button>
                        <button type="button" onClick={() => this.changeZoom('out')} className={`btnZoom`}>-</button>
                    </div>
                    <div className='d-flex justify-content-between  h-100 overflow-hidden' style={{ maxHeight: "calc(100vh - 62px)", backgroundColor: '#202531' }}>
                        {this.state.listSide && (
                            <div className="pb-0 ps-0 pt-0 pe-1 position-relative" style={{ width: '500px', backgroundColor: '#202531', zIndex: 1032 }}>
                                <div className='d-flex justify-content-center align-items-center pt-8 px-6'>
                                    <div className="d-flex justify-content-start align-items-center" style={{ width: '95%' }}>
                                        <div className="d-flex justify-content-start align-items-center me-4" style={{ flex: 1, backgroundColor: '#F5F8FA', height: '44px', borderRadius: '0.475rem' }}>
                                            <span className="svg-icon svg-icon-3 svg-icon-gray-500 mr-2 ml-3">
                                                <Svg name="Search" />
                                            </span>
                                            <input type="text" onChange={(e) => this.setState({ search: e.target.value })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' || e.keyCode === 13) {
                                                        this.handleCallSearch()
                                                    }
                                                    if (e.key === '"Escape"' || e.keyCode === 27) {
                                                        this.handleReset()
                                                    }
                                                }}
                                                className="form-control form-control-solid border border-2 shadow-xs ps-3 inputGeneralSearch" name="search"
                                                value={this.state.search} placeholder={global._trans("header", "search")} id='' />
                                            <button type="button"
                                                onClick={() => {
                                                    this.handleReset()
                                                    // this.setState({ search: '' })
                                                    // this.props.history.push({ search: '' })
                                                    // this.props.fetchSitesMapNew({})
                                                }}
                                                className="btnDelete">
                                                <AiOutlineCloseCircle size={25} color='#202531' />
                                            </button>


                                        </div>
                                        <button type="button"
                                            onClick={() => this.handleCallSearch()}
                                            className="btn btn-outline-primary py-2 fs-6">{global._trans("header", "actionsSearch")}</button>
                                    </div>
                                </div>
                                <p className='my-0 px-6 mt-4' style={{ color: '#F4F7FF', padding: '', fontSize: '16px', fontWeight: '300' }}>{listSitesMap.length} résultats</p>
                                <hr className='my-5  mx-4 bg-white ' />
                                <div className='d-flex justify-content-between align-items-center mx-5'>
                                    <div className=''>
                                        {/* <button type='button' onClick={this.justUve} className='BtnFilter'>UVE</button>
                                        <button type='button' onClick={this.justGestionPrev} className='BtnFilter'>Gestion privée</button> */}
                                        <button type='button' onClick={() => {
                                            this.setState({
                                                showFIlter: this.state.showFIlter === 2 ? 1 : 2
                                            })
                                        }} className='BtnFilter'>
                                            <LuSettings2 className='mr-2' />
                                            {this.state.showFIlter === 2 ? 'Moins' : 'Filtres / Listes'}
                                        </button>
                                    </div>
                                    {this.state.showFIlter === 1 && (
                                        <div className='d-flex justify-content-end align-items-center' style={{ width: '200px' }}>
                                            <p className='text-white m-0 mr-2' style={{ fontSize: '14px', fontWeight: '500' }}>
                                                Sort by:
                                            </p>
                                            <select onChange={(val) => {
                                                this.handleCallSearch(val)
                                            }} name={'orderBY'} defaultValue={this.state.orderBY} className="orderBySelect">
                                                <option value="denomination">Dénomination</option>
                                                {this.orderByList.map((el) => {
                                                    return <option value={el.value} key={el.value}>{el.label}</option>
                                                })}
                                            </select>
                                        </div>
                                    )}
                                </div>
                                {this.state.showFIlter === 2 ?
                                    this.asUser()
                                    :
                                    <>
                                        <hr className='my-6  mx-4 bg-white ' />
                                        <div className="mt-8 pb-4" style={{ overflowY: 'auto', backgroundColor: '#202531', height: this.state.showFIlter !== 1 ? 'calc(100vh - 200px)' : 'calc(100vh - 280px)' }}>
                                            {this.state.sitesArray.length > 0 && regionstListed.length > 0 ?
                                                this.state.sitesArray.map((site, ind) => {
                                                    return (
                                                        <div className='d-flex justify-content-start align-items-center mb-5 px-0 text-white rounded me-2 card_hoverable'
                                                            key={site.id_site} onClick={() => this.openMarker(site, true)} style={{ userSelect: 'none' }}>
                                                            <div className='me-4 text-white linearGrayImage' style={{}}>
                                                                <img src={`${siteAlll.find((el) => el.id_site === site.id_site).default_image
                                                                    ? siteAlll.find((el) => el.id_site === site.id_site).default_image.url :
                                                                    siteAlll.find((el) => el.id_site === site.id_site).photos.length ?
                                                                        siteAlll.find((el) => el.id_site === site.id_site).photos[siteAlll.find((el) => el.id_site === site.id_site).photos.length - 1].url
                                                                        :
                                                                        "/media/factory.png"}`} alt="a picture" className='imgSideBar' />
                                                                <p className='text-white siteTypeSideBar' style={{ color: '#0E8F2A', fontSize: '16px', fontWeight: '700' }}>{site.iconType}</p>
                                                                <p className='text-white siteVilleSideBar' style={{ color: '#0E8F2A', fontSize: '16px', fontWeight: '800' }}>{site.city}</p>
                                                                <div className='text-white siteTypeIconSideBar' style={{ color: '#0E8F2A', fontSize: '16px', fontWeight: '800' }}>
                                                                    <div className={`markerSideBar 
                                                            ${site.iconType === 'TMB' ? 'markerTmb' : ''}
                                                            ${site.iconType === 'ISDND' ? 'markerIsdnd' : ''}
                                                            ${site.iconType === 'UVE' ? 'markerUve' : ''}
                                                            ${site.iconType === 'TRI' ? 'markerTri' : ''}
                                                            `} />
                                                                </div>
                                                            </div>
                                                            <div className='position-relative ps-3 pt-1 gridCrad align-items-center'
                                                                style={{ marginTop: '0px', flex: 1 }}>
                                                                {/* <button className="btn p-0 m-0 position-absolute hoverable"
                                                                    style={{ zIndex: 406, top: 0, right: '-5px', backgroundColor: '#1C1F2A', borderRadius: '50%', width: '35px', height: '35px' }}
                                                                    onClick={() => { }}>
                                                                    <HeartOutlined style={{ fontSize: '19px', color: 'white', margin: 0 }} />
                                                                </button> */}
                                                                <div className='text-white' style={{ marginRight: '30px' }}>
                                                                    <p className='mb-3 text-primary' style={{ fontSize: '14px', fontWeight: '700', color: '#374151' }}>{site.denomination}</p>
                                                                </div>
                                                                <div className='d-flex justify-content-start align-items-start gap-2 mb-0 '>
                                                                    <img src='/media/images/2023imgs/location.png' alt="a picture local" width='13' />
                                                                    <p className='text-white' style={{ color: '#6B7280', fontWeight: '300', fontSize: '12px', }}>{[site.adresse, site.postcode, site.city].filter(x => x).join(', ')}</p>
                                                                </div>
                                                                <p title={global._trans('map', 'client')} className='mb-1 text-white' style={{ fontSize: '14px', fontWeight: '500', color: '#374151' }}>
                                                                    {siteAlll.find((el) => el.id_site === site.id_site).client?.client ? siteAlll.find((el) => el.id_site === site.id_site).client.client[siteAlll.find((el) => el.id_site === site.id_site).client.client.dataIndex] : "-"}
                                                                </p>
                                                                <p title={global._trans('map', 'exploitant')} className='mb-1' style={{ fontSize: '14px', fontWeight: '500', color: '#fff' }}>
                                                                    {siteAlll.find((el) => el.id_site === site.id_site).exploitant?.client ? siteAlll.find((el) => el.id_site === site.id_site).exploitant.client[siteAlll.find((el) => el.id_site === site.id_site).exploitant.client.dataIndex] || siteAlll.find((el) => el.id_site === site.id_site).exploitant.client.denomination : "-"}
                                                                </p>
                                                                
                                                                <div className='d-flex justify-content-between align-items-center gap-2 my-2'>
                                                                    {siteAlll.find((el) => el.id_site === site.id_site).logo_exploitant &&
                                                                        <div className={``}>
                                                                            <img src={siteAlll.find((el) => el.id_site === site.id_site).logo_exploitant} className='px-2' alt="a picture" width="90" height="35" style={{ borderRadius: '8px', objectFit: 'contain', backgroundColor: 'white' }} />
                                                                        </div>}
                                                                    {siteAlll.find((el) => el.id_site === site.id_site).latest_contrat?.dateFin &&
                                                                        <p title={global._trans('map', 'contractEndDate')} className='mb-0' style={{ fontSize: '14px', fontWeight: '500', color: '#fff' }}>
                                                                            {siteAlll.find((el) => el.id_site === site.id_site).latest_contrat?.dateFin}
                                                                        </p>}
                                                                </div>

                                                                {site.iconType === 'UVE' &&
                                                                    <div className='d-flex justify-content-between align-items-center gap-2 my-2' style={{ overflowX: 'hidden' }}>
                                                                        {siteAlll.find((el) => el.id_site === site.id_site).tonnage && 
                                                                            <p title={global._trans('map', 'capaciteReglementaire')} className='m-0' style={{ fontSize: '16px', fontWeight: '600' }}>
                                                                            {addSpaceInNUmber(siteAlll.find((el) => el.id_site === site.id_site).tonnage)} T
                                                                            </p>}
                                                                        {(siteAlll.find((el) => el.id_site === site.id_site).types_valorisation && siteAlll.find((el) => el.id_site === site.id_site).types_valorisation.length > 0) &&
                                                                            <div className='d-flex justify-content-start align-items-center gap-2'>
                                                                                 {siteAlll.find((el) => el.id_site === site.id_site).types_valorisation.map((el, ind) => (
                                                                                        <div className='' key={ind}>
                                                                                            {el === 'hydrogene' && (
                                                                                                <img title={global._trans('map', 'hydrogeneValorisation')} src='/media/images/2023imgs/hydro.png' alt="a picture" className='' width={'30'} />
                                                                                            )}
                                                                                            {el === 'thermique' && (
                                                                                                <img title={global._trans('map', 'thermicValorisation')} src='/media/images/2023imgs/thermique.png' alt="a picture" width={'30'} className='' />
                                                                                            )}
                                                                                            {el === 'electric' && (
                                                                                                <img title={global._trans('map', 'electricValorisation')} src='/media/images/2023imgs/elec.png' alt="a picture" className='' width={'30'} />
                                                                                            )}
                                                                                        </div>
                                                                                    ))}
                                                                            </div>}
                                                                        <p className='m-0' style={{ fontSize: '16px', fontWeight: '600' }}>
                                                                            {siteAlll.find((el) => el.id_site === site.id_site).nb_lines} {siteAlll.find((el) => el.id_site === site.id_site).nb_lines === 1 ? '  Ligne' : '  Lignes'}
                                                                        </p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }) : null
                                            }
                                        </div>
                                    </>}
                            </div>
                        )}
                        <div id="map_sites" className=""
                            style={{
                                width: (this.state.listSide) ? 'calc(100vw - 500px)' : '100%',
                                paddingRight: (this.state.listSide && listSitesMap.length !== 0) ? '5px' : '0'
                            }}></div>
                    </div>
                </div >
            </>
        )
    }
}
function mapState(state) {
    const { sitesMapErrors, listSitesMap, laodingSitesMap, filterMap, regionstListed, siteAlll, groupsList, typeEquipement, traitementFumee, modeGestion, amobe } = state.map
    const { dataFetched } = state.common
    return { sitesMapErrors, listSitesMap, laodingSitesMap, dataFetched, filterMap, regionstListed, siteAlll, groupsList, typeEquipement, traitementFumee, modeGestion, amobe }
}
const connectedClass = connect(mapState, { fetchSitesMap, fetchDetailShare, AllLocaleInfo, fetchAllDepartemnts, fetchSitesMapNew, getGroupes })(Map)
export { connectedClass as Map }
