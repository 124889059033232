import { sessionsSlice,callTypes } from "./sessions.reducer";
import * as SessionRequests from "./sessions.crud";
const { actions } = sessionsSlice;
export const fetchSessions = QueryPramas => dispatch => {
    dispatch(actions.startCall({callType:callTypes.list}))
    return SessionRequests
        .fetchSessions(QueryPramas)
        .then(res => {
            if(res && res.data && res.data.ok){
                dispatch(actions.sessionsFetched({listSessions:res.data.sessions}))
            }else{
                dispatch(actions.catchError({errors:"fail to load sites",callType:callTypes.list}))
            }
        })
        .catch(err => {
            dispatch(actions.catchError({errors:"fail to load sites",callType:callTypes.list}))
        });
};

export const createSession = session => dispatch => {
    dispatch(actions.startCall({callType:callTypes.create}))
    return SessionRequests
        .createSession(session)
        .then(res => {
            if(res && res.data && res.data.ok){
                dispatch(actions.sessionsCreated({session:res.data.session}))
            }else{
                dispatch(actions.catchError({errors:null,callType:callTypes.create}))
            }
        })
        .catch(({message,errors}) => {
            if (message==="The given data was invalid.") {
                dispatch(actions.catchError({errors:errors,callType:callTypes.create}))
            } else {
                dispatch(actions.catchError({errors:null,callType:callTypes.create}))
            }
        });
};
export const updateSession = session => dispatch => {
    dispatch(actions.startCall({callType:callTypes.create}))
    return SessionRequests
        .updateSession(session)
        .then(res => {
            if(res && res.data && res.data.ok){
                dispatch(actions.sessionsCreated({session:res.data.session}))
            }else{
                dispatch(actions.catchError({errors:null,callType:callTypes.create}))
            }
        })
        .catch(({message,errors}) => {
            if (message==="The given data was invalid.") {
                dispatch(actions.catchError({errors:errors,callType:callTypes.create}))
            } else {
                dispatch(actions.catchError({errors:null,callType:callTypes.create}))
            }
        });
};
