export const Logout =(
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 171 171">
        <defs>
            <linearGradient x1="35.11913" y1="35.11913" x2="135.88088" y2="135.88088" gradientUnits="userSpaceOnUse"
                id="color-1_IwZ4mbmrC5gw_gr1">
                <stop offset="0" stopColor="#f44f5a"></stop>
                <stop offset="0.443" stopColor="#ee3d4a"></stop>
                <stop offset="1" stopColor="#e52030"></stop>
            </linearGradient>
        </defs>
        <g>
            <g>
                <path
                    d="M156.75,85.5c0,39.34781 -31.90219,71.25 -71.25,71.25c-39.34781,0 -71.25,-31.90219 -71.25,-71.25c0,-39.34781 31.90219,-71.25 71.25,-71.25c39.34781,0 71.25,31.90219 71.25,71.25z"
                    fill="url(#color-1_IwZ4mbmrC5gw_gr1)"></path>
                <path
                    d="M103.3125,48.48563v16.82213c0,1.58531 0.45956,3.16706 1.46419,4.39256c4.49587,5.472 6.71175,12.882 5.17275,20.79788c-1.89169,9.73275 -9.72919,17.57381 -19.46194,19.46194c-15.96,3.08512 -29.925,-9.05587 -29.925,-24.46013c0,-6.00638 2.13037,-11.514 5.6715,-15.8175c0.98681,-1.20056 1.4535,-2.75025 1.4535,-4.30706v-16.88981c0,-2.69325 -2.91769,-4.48519 -5.25113,-3.14212c-15.69994,9.0345 -25.60725,27.00019 -22.76081,46.96444c2.93194,20.56631 19.893,36.92531 40.53769,39.20888c27.94425,3.09581 51.59925,-18.696 51.59925,-46.01681c0,-17.17838 -9.35512,-32.16581 -23.24887,-40.1565c-2.337,-1.34663 -5.25113,0.44531 -5.25113,3.14213z"
                    fill="#000000" opacity="0.05"></path>
                <path
                    d="M105.09375,50.11369v15.25463c0,1.20056 0.38119,2.37975 1.14,3.30956c4.58494,5.63587 6.92906,13.16344 5.6145,21.24319c-1.78838,11.25037 -10.96894,20.29556 -22.23,21.96638c-16.58344,2.46169 -30.837,-10.28137 -30.837,-26.38744c0,-6.384 2.2515,-12.23363 5.98856,-16.82925c0.75169,-0.91912 1.13644,-2.08406 1.13644,-3.27038v-15.28312c0,-2.07338 -2.27644,-3.38081 -4.03988,-2.29425c-13.60162,8.41106 -22.14806,24.07537 -20.64825,41.5815c1.83469,21.4035 19.3515,38.89538 40.80131,40.46288c26.15944,2.01281 48.01181,-18.62475 48.01181,-44.36738c0,-15.89588 -8.322,-29.88225 -20.89406,-37.677c-1.76344,-1.09013 -4.04344,0.21375 -4.04344,2.29069z"
                    fill="#000000" opacity="0.07"></path>
                <path
                    d="M106.875,51.85575v13.59806c0,0.81581 0.29925,1.58888 0.81225,2.223c5.12644,6.36263 7.60594,14.93756 5.63944,24.07538c-2.34056,10.87275 -11.18625,19.551 -22.10175,21.6885c-18.2115,3.56606 -34.22494,-10.35262 -34.22494,-27.94069c0,-6.74737 2.37619,-12.93544 6.30919,-17.81963c0.513,-0.63769 0.81581,-1.41431 0.81581,-2.23369v-13.58738c0,-1.48912 -1.67794,-2.32988 -2.90344,-1.482c-11.40356,7.90162 -18.81356,21.1755 -18.46088,36.14869c0.52369,22.24069 18.81,40.82269 41.04,41.69194c24.34256,0.94762 44.44931,-18.582 44.44931,-42.71794c0,-14.54925 -7.32806,-27.39919 -18.468,-35.11913c-1.2255,-0.84787 -2.907,-0.02137 -2.907,1.47488z"
                    fill="#ffffff"></path>
                <path
                    d="M81.9375,78.375h7.125c3.93656,0 7.125,-3.18844 7.125,-7.125v-28.5c0,-3.93656 -3.18844,-7.125 -7.125,-7.125h-7.125c-3.93656,0 -7.125,3.18844 -7.125,7.125v28.5c0,3.93656 3.18844,7.125 7.125,7.125z"
                    fill="#000000" opacity="0.05"></path>
                <path
                    d="M81.9375,76.59375h7.125c2.94975,0 5.34375,-2.394 5.34375,-5.34375v-28.5c0,-2.94975 -2.394,-5.34375 -5.34375,-5.34375h-7.125c-2.94975,0 -5.34375,2.394 -5.34375,5.34375v28.5c0,2.94975 2.394,5.34375 5.34375,5.34375z"
                    fill="#000000" opacity="0.07"></path>
                <path
                    d="M89.0625,74.8125h-7.125c-1.9665,0 -3.5625,-1.596 -3.5625,-3.5625v-28.5c0,-1.9665 1.596,-3.5625 3.5625,-3.5625h7.125c1.9665,0 3.5625,1.596 3.5625,3.5625v28.5c0,1.9665 -1.596,3.5625 -3.5625,3.5625z"
                    fill="#ffffff"></path>
            </g>
        </g>
    </svg>
)