import React, { useState } from 'react';
import { fetchDetailShare, fetchAllDepartemnts } from '../../../modules/Map/map.actions'
import { Spinner } from 'react-bootstrap'
import { connect } from "react-redux";
import Mosaic from 'react-photo-mosaic';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { CaretRightOutlined, DownOutlined, LinkOutlined, MinusSquareFilled, MinusSquareTwoTone, PlusOutlined, PlusSquareFilled, StarOutlined, UpOutlined } from '@ant-design/icons';
import formatTelephone, { addSpaceInNUmber } from '../../../services/formatTelephone';
const SitesDetails = (props) => {
    const { idsite, idacesss } = props.match.params
    const [showOne, setshowOne] = React.useState(true)
    const [showTwo, setshowTwo] = React.useState(true)
    const [showThree, setshowThree] = React.useState(true)
    const [showFoor, setshowFoor] = React.useState(false)
    const [showFive, setshowFive] = React.useState(true)
    const [showSix, setshowSix] = React.useState(true)
    const [showSeven, setshowSeven] = React.useState(true)
    const [showHieght, setshowHieght] = React.useState(true)
    const [mosaicProps, setmosaicProps] = React.useState()

    const To_date = (date) => {
        const d = new Date(date)
        let month = `${d.getMonth() + 1}`
        let day = `${d.getDate()}`
        const year = d.getFullYear()
        if (month.length < 2) month = `0${month}`
        if (day.length < 2) day = `0${day}`
        const dateToReturn = [day, month, year].join('/')
        return dateToReturn
    }
    React.useEffect(() => {
        props.fetchDetailShare(idacesss, idsite)
        props.fetchAllDepartemnts()
    }, [])
    React.useEffect(() => {
        if (props.detailShare) {
            // console.log('🚀 ===> ', props.detailShare.infoTech)
            // props.detailShare.client
            setshowSix(props.detailShare.contracts.length > 0 ? true : false)
            setshowSeven(props.detailShare.files.length > 0 ? true : false)
            setshowHieght(props.detailShare.sitesSameClient.length > 0 ? true : false)
            setmosaicProps({
                images: props.detailShare.photos,
                mainImagePath: props.detailShare.photos[0],
                // mainImageIndex: 2, we choose to use either mainImagePath 
                width: 800,
                height: 500,
                maxBlocksInRow: 17,
                spaceBetween: 1,
                mainImageStyle: { opacity: 0.5 },
                // secondaryImageStyle: { ... },
                onClick: (e, url) => window.open(url, '_blank')
            })
        }
    }, [props.detailShare])

    React.useEffect(() => {
        if (!showFoor) {
            const detailsSitesPage = document.getElementById('detailsSitesPage')
            detailsSitesPage.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }, [showFoor]);
    return (
        <div className='w-100 pt-6' style={{ backgroundColor: 'white' }} id='detailsSitesPage'>
            {props.laodingDetail === 0 && <div style={{ height: 'calc(100vh - 61px)' }} className="position-absolute w-100 top-0 left-0 d-flex justify-content-center align-items-center flex-column">
                <p>Chargement les information de site ...</p>
                <Spinner animation="border" variant="primary" />
            </div>}
            {props.detailShare && (
                <div style={{ padding: props.detailShare.infoBase.categorieSite === 'UVE' ? '0px 40px 40px 80px' : '0px 80px', backgroundColor: 'white' }}>
                    {/* <h1 className='mb-7'>{props.detailShare.infoBase.denomination}</h1> */}
                    {/* <div className='photos d-flex justify-content-center align-items-center mb-10' >
                        <div className='me-2' style={{}}>
                            <img src="/media/factory.png" alt="a picture" width="500" height='400' className='rounded-left' />
                        </div>
                        <div className='d-flex justify-content-center align-items-center' style={{}}>
                            <div className='d-flex justify-content-center align-items-center flex-column me-2' style={{}}>
                                <img src="/media/factory.png" alt="a picture" width="250px" height='196' className=' mb-2' />
                                <img src="/media/factory.png" alt="a picture" width="250px" height='196' className='' />
                            </div>
                            <div className='d-flex justify-content-center align-items-center flex-column ' style={{}}>
                                <img src="/media/factory.png" alt="a picture" width="250px" height='196' className='rounded-right mb-2' />
                                <img src="/media/factory.png" alt="a picture" width="250px" height='196' className='rounded-right' />
                            </div>
                        </div>
                    </div> */}
                    <div className='d-flex justify-content-start align-items-center` position-relative' style={{ marginBottom: '0px', }}>
                        <div style={{ width: '100%' }} className='d-flex justify-content-start align-items-center '>
                            {props.detailShare.infoBase.categorieSite === 'TMB' ?
                                <div className='markerDetail markerTmb'></div>
                                :
                                props.detailShare.infoBase.categorieSite === 'ISDND' ?
                                    <div className='markerDetail markerIsdnd'></div>
                                    :
                                    props.detailShare.infoBase.categorieSite === 'UVE' ?
                                        <div className='markerDetail markerUve'></div>
                                        :
                                        <div className='markerDetail markerTri'></div>
                            }
                            <div className='ms-6'>
                                <h1 className='mb-4 m_title'>{props.detailShare.infoBase.denomination}</h1>
                                <h5 className='d-flex justify-content-start align-items-center' style={{ fontWeight: '500', marginBottom: '0' }}>
                                    {/* <StarOutlined style={{ fontSize: '20px', color: '#DE3151', margin: '0 10px' }} /> */}
                                    <span style={{ margin: '0 0 0 0px' }}>{props.detailShare.infoBase.categorieSite}</span>
                                    <CaretRightOutlined style={{ fontSize: '10px', color: '#000', margin: '0 10px' }} />
                                    {props.regionstListed && props.regionstListed.length > 0 && (
                                        props.regionstListed.find((el) => el.id_region === props.detailShare.allDate.region_siege).name_region
                                    )}
                                    <CaretRightOutlined style={{ fontSize: '10px', color: '#000', margin: '0 10px' }} />
                                    {props.departementListed && props.departementListed.length > 0 && (
                                        <span style={{ color: '#6B7280' }}>{props.departementListed.find((el) => el.id_departement === props.detailShare.allDate.departement_siege).name_departement}</span>
                                    )}
                                    <CaretRightOutlined style={{ fontSize: '10px', color: '#000', margin: '0 10px' }} />
                                    <span style={{ color: '#6B7280' }}>{props.detailShare.allDate.city}</span>
                                </h5>
                            </div>
                            {/* <div className=' d-flex justify-content-start align-items-center mb-2' style={{ margin: '0' }}>
                            <h4 className='mb-0 ' style={{ fontSize: '14px', fontWeight: '600', margin: '0' }}>
                                {props.detailShare.client.denominationLegale}<span className='ms-6' style={{ color: '#0E8F2A', fontSize: '15px', fontWeight: '500' }}>{props.detailShare.infoBase.categorieSite}</span>
                            </h4>
                        </div> */}
                        </div>
                    </div>
                    {props.detailShare.photos.length ?
                        // <div style={{ margin: '70px auto 100px auto', width: '70%', maxHeight: '550px' }}>
                        // {/* //     {props.detailShare.photos.length === 1 ?
                        //         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '600px', borderRadius: '5px', height: '600ppx' }}>
                        //             <img src={props.detailShare.photos[0]} alt={`Site pic`} width={'70%'} height={'600px'} style={{ borderRadius: '5px', margin: '0 auto', cursor: 'pointer', objectFit: 'contain', border: '0px solid #dcdcdc' }} onClick={() => window.open(props.detailShare.photos[0], '_blank')} className='siteImage' />
                        //         </div>
                        //         :
                        //         props.detailShare.photos.length === 2
                        //             ?
                        //             <div style={{ display: 'grid', justifyContent: 'start', alignItems: 'start', gridTemplateColumns: '1fr 1fr', gap: '20px', maxHeight: '600px', borderRadius: '5px', height: '600ppx' }}>
                        //                 <img src={props.detailShare.photos[0]} alt={`Site pic 1`}  style={{ borderRadius: '5px', margin: '0', cursor: 'pointer', objectFit: 'contain', border: '0px solid #dcdcdc' }} onClick={() => window.open(props.detailShare.photos[0], '_blank')} className='siteImage' />
                        //                 <img src={props.detailShare.photos[1]} alt={`Site pic 2`} width={'50%'} height={'300px'} style={{ borderRadius: '5px', margin: '0', cursor: 'pointer', objectFit: 'contain', border: '0px solid #dcdcdc' }} onClick={() => window.open(props.detailShare.photos[1], '_blank')} className='siteImage' />
                        //             </div>
                        //             :
                        //             props.detailShare.photos.length === 3
                        //                 ?
                        //                 <div style={{ display: 'grid', justifyContent: 'start', alignItems: 'start', gridTemplateColumns: '1fr 1fr', gap: '20px', maxHeight: '600px', borderRadius: '5px', height: '600ppx' }}>
                        //                     <img src={props.detailShare.photos[0]} alt={`Site pic 1`}  style={{ borderRadius: '5px', margin: '0', cursor: 'pointer', objectFit: 'contain', border: '0px solid #dcdcdc' }} onClick={() => window.open(props.detailShare.photos[0], '_blank')} className='siteImage' />
                        //                     <div style={{ display: 'grid', justifyContent: 'start', alignItems: 'start', gridTemplateColumns: '1fr', gap: '20px' }}>
                        //                         <img src={props.detailShare.photos[1]} alt={`Site pic 2`}  style={{ borderRadius: '5px', margin: '0', cursor: 'pointer', objectFit: 'contain', border: '0px solid #dcdcdc' }} onClick={() => window.open(props.detailShare.photos[1], '_blank')} className='siteImage' />
                        //                         <img src={props.detailShare.photos[2]} alt={`Site pic 3`}  style={{ borderRadius: '5px', margin: '0', cursor: 'pointer', objectFit: 'contain', border: '0px solid #dcdcdc' }} onClick={() => window.open(props.detailShare.photos[2], '_blank')} className='siteImage' />
                        //                     </div>
                        //                 </div>
                        //                 :
                        //                 <div style={{ display: 'grid', justifyContent: 'start', alignItems: 'start', gridTemplateColumns: '1fr 1fr', gap: '20px', maxHeight: '600px', borderRadius: '5px', height: '600ppx', overflowY: 'scroll', paddingRight: `5px` }}>
                        //                     <img src={props.detailShare.photos[0]} alt={`Site pic 1`}  style={{ borderRadius: '5px', margin: '0', cursor: 'pointer', objectFit: 'contain', border: '0px solid #dcdcdc' }} onClick={() => window.open(props.detailShare.photos[0], '_blank')} className='siteImage' />
                        //                     <div style={{ display: 'grid', justifyContent: 'start', alignItems: 'start', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                        //                         {props.detailShare.photos.map((photo, ind) => (
                        //                             ind !== 0 && (
                        //                                 <img src={photo} alt={`Site pic ${ind}`} key={ind} width={'100%'} height={'288px'} style={{ borderRadius: '5px', margin: '0', cursor: 'pointer', objectFit: 'contain', border: '0px solid #dcdcdc' }} onClick={() => window.open(photo, '_blank')} className='siteImage' />
                        //                             )
                        //                         ))}
                        //                     </div>
                        //                 </div>
                        //     } */}
                        // {/* <Carousel slide={false}> */}
                        // {/* <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px' }}>
                        //     {props.detailShare.photos.map((photo, ind) => {
                        //         return (
                        //             <img src={photo} alt={`Site pic ${ind}`} height={'80px'} width={'120px'} style={{ borderRadius: '0px', objectFit: 'fill', marginRight: '5px' }} onClick={() => window.open(photo, '_blank')} />
                        //         )
                        //     })}
                        // </div> */}
                        // <Carousel showArrows={true} showThumbs={false}>
                        //     {props.detailShare.photos.map((photo, ind) => {
                        //         return (
                        //             <div>
                        //                 <img src={photo} alt={`Site pic ${ind}`} height={'500px'} style={{ borderRadius: '5px', objectFit: 'fill' }} onClick={() => window.open(photo, '_blank')} className='siteImage' />
                        //                 <p className="legend">Photo {ind + 1}</p>
                        //             </div>
                        //             // <Carousel.Item key={ind}>
                        //             // {/* </Carousel.Item> */ }
                        //             // <img src={photo} alt={`Site pic ${ind}`} width={'100%'} height={'100%'} style={{ borderRadius: '5px', objectFit: 'fill' }} onClick={() => window.open(photo, '_blank')} className='siteImage' />
                        //         )
                        //     })}
                        // </Carousel>
                        // {/* </Carousel> */}

                        // </div>
                        <div style={{ margin: '70px auto 100px auto', marginLeft: '5px', }}>
                            {props.detailShare.photos.length === 1 ?
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '610px', borderRadius: '5px', height: '610px' }}>
                                    <img src={props.detailShare.photos[0]} alt={`Site pic`} width={'800px'} height={'610px'} style={{ borderRadius: '5px', margin: '0 auto', cursor: 'pointer', objectFit: 'contain', border: '0px solid #dcdcdc' }} onClick={() => window.open(props.detailShare.photos[0], '_blank')} className='siteImage' />
                                </div>
                                :
                                <div style={{ display: 'grid', justifyContent: 'start', alignItems: 'start', gridTemplateColumns: '800px 400px', gap: '10px', margin: 'auto', maxHeight: '610px', borderRadius: '5px', height: '610px' }}>
                                    <img src={props.detailShare.siteDD.default_image ? props.detailShare.siteDD.default_image.url : props.detailShare.photos[0]} alt={`Site pic 1`} width={'800px'} height={'610px'} style={{ borderRadius: '5px', margin: '0', cursor: 'pointer', border: '0px solid #dcdcdc' }} onClick={() => window.open(props.detailShare?.photos[0], '_blank')} className='siteImage' />
                                    <div style={{ display: 'grid', justifyContent: 'start', alignItems: 'start', gridTemplateColumns: '1fr 1fr', gap: '10px', maxHeight: '610px', overflowY: 'auto', width: '810px' }}>
                                        {props.detailShare.photos.map((photo, ind) => {
                                            return ((props.detailShare.siteDD.default_image) ?
                                                photo !== props.detailShare.siteDD.default_image.url &&
                                                (
                                                    <img src={photo}
                                                        alt={`Site pic 2`} width={'400px'} height={'300px'} style={{ borderRadius: '5px', margin: '0', cursor: 'pointer', border: '0px solid #dcdcdc' }}
                                                        onClick={() => window.open(photo, '_blank')} className='siteImage' />
                                                )
                                                :
                                                ind !== 0 &&
                                                (
                                                    <img src={photo}
                                                        alt={`Site pic 2`} width={'400px'} height={'300px'} style={{ borderRadius: '5px', margin: '0', cursor: 'pointer', border: '0px solid #dcdcdc' }}
                                                        onClick={() => window.open(photo, '_blank')} className='siteImage' />
                                                )
                                            )
                                        })}
                                        {/* {props.detailShare.photos.length === 3 ?
                                            <img src={props.detailShare?.photos[2] || '/media/factory.png'} alt={`Site pic 3`} width={'400px'} height={'300px'} style={{ borderRadius: '5px', margin: '0', cursor: 'pointer', border: '0px solid #dcdcdc' }} onClick={() => window.open(props?.detailShare?.photos[2], '_blank')} className='siteImage' />
                                            :
                                            null} */}
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div style={{ margin: '50px auto' }} />
                    }
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                        <div style={{ width: '60%' }}>
                            <div id='showOne' className='border border-right-0   px-6 py-6 rounded shadow' style={{ marginBottom: '50px' }}>
                                <h3 className='mb-4 d-flex justify-content-start align-items-center '>
                                    <img src="/media/logos/factory.png" alt="a picture s" width="50" height='50' />
                                    <p className='m-1-mine mb-0'>Informations administratives :</p>
                                    <p className="border border-top-0 border-right-0 border-left-0 hr-details" style={{ height: '2px', flex: '1', margin: '0 10px' }} />
                                    {showOne ?
                                        <MinusSquareTwoTone className='' style={{ fontSize: '28px', }} onClick={() => setshowOne(false)} />
                                        :
                                        <PlusSquareFilled className='' style={{ fontSize: '28px' }} onClick={() => setshowOne(true)} />
                                    }
                                </h3>
                                {showOne &&
                                    <div className='ms-1 TMP'>
                                        <div className='m-1-mine d-flex justify-content-start align-items-center' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ width: '180px' }}>Dénomination :</p>
                                            <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.infoBase.denomination}</p></div>
                                        <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ width: '180px' }}> Catégorie :</p>
                                            <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.infoBase.categorieSite}</p></div>
                                        <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ width: '180px' }}>Adresse :</p>
                                            <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.infoBase.adresse}</p></div>
                                        <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ width: '180px' }}>Région : </p>
                                            <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                {props.regionstListed && props.regionstListed.length > 0 && (
                                                    props.regionstListed.find((el) => el.id_region === props.detailShare.allDate.region_siege).name_region
                                                )}
                                            </p></div>
                                        <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ width: '180px' }}>Departement : </p>
                                            <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                {/* {props.detailShare.allDate.departement_siege} */}
                                                {props.departementListed && props.departementListed.length > 0 && (
                                                    props.departementListed.find((el) => el.id_departement === props.detailShare.allDate.departement_siege).name_departement
                                                )}
                                            </p></div>
                                        <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ width: '180px' }}>Téléphone :</p>
                                            <p className='text-dark m-0' style={{ fontWeight: '500' }}>{formatTelephone(props.detailShare.infoBase.telephoneStandrad)}</p></div>
                                        <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ width: '180px' }}>Site internet :</p>
                                            <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.infoBase.siteIntrnet}</p></div>
                                        <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ width: '180px' }}>Année de construction :</p>
                                            <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.infoBase.anneeCreation}</p></div>
                                        <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ width: '180px' }}>Mode de gestion :</p>
                                            <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.infoBase.modeGestion}</p>
                                        </div>
                                        <div className='m-1-mine d-flex justify-content-start' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ 'min-width': '180px' }}>BE AMO :</p>
                                            <p className='text-dark m-0' style={{ fontWeight: '500', 'flex-grow': 1 }}>{props.detailShare.infoBase.amobes_text}</p>
                                        </div>
                                        <div className='m-1-mine d-flex justify-content-start' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ 'min-width': '180px' }}>Commentaires :</p>
                                            <p className='text-dark m-0' style={{ fontWeight: '500', 'flex-grow': 1 }}>{props.detailShare.infoBase.comments}</p>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div id='showTwo' className='border border-right-0   px-6 py-6 rounded shadow' style={{ marginBottom: '50px' }}>
                                <h3 className='mb-4 d-flex justify-content-start align-items-center '>
                                    <img src="/media/logos/school.png" alt="a picture sokok" width="50" height='50' />
                                    <p className='m-1-mine mb-0'>Collectivités Territoriales :</p>
                                    <p className="border border-top-0 border-right-0 border-left-0 hr-details" style={{ height: '2px', flex: '1', margin: '0 10px' }} />
                                    {showTwo ?
                                        <MinusSquareTwoTone className='' style={{ fontSize: '28px', }} onClick={() => setshowTwo(false)} />
                                        :
                                        <PlusSquareFilled className='' style={{ fontSize: '28px' }} onClick={() => setshowTwo(true)} />
                                    }
                                </h3>
                                {showTwo &&
                                    <div className='ms-1'>
                                        {props.detailShare.client.typePersonMoral === 'Commune' && (
                                            <>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> État :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                        {Object.keys(props.detailShare.client).includes("state") && (<>
                                                            <i className={props.detailShare.client.state ? (props.detailShare.client.state === "A" ? "state-badge bg-success" : "state-badge bg-danger") : "state-badge bg-dark"}></i>
                                                            {props.detailShare.client.state ? (props.detailShare.client.state === "A" ? "Actif" : "Fermé") : "Indéfini"}
                                                        </>)}
                                                    </p>
                                                </div>

                                                {props.detailShare.client.logoA ? (
                                                    <div className='m-1-mine d-flex justify-content-start align-items-center my-4' style={{ color: '#6F6C90' }}>
                                                        <img src={`${props.detailShare.siteDD.client.client.logo[0].url}`} alt="a pict" width="150px" className='rounded' />
                                                    </div>
                                                )
                                                    : null
                                                    // <div className='m-1-mine d-flex justify-content-start align-items-center my-4' style={{ color: '#6F6C90' }}>
                                                    //     <p className='m-0'> Pas de logo</p>
                                                    // </div>
                                                }
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Catégorie :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>Commune</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Nom Commune :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.nomCommune}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Dénomination :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.usual_denomination}</p>
                                                </div>
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Dénomination   :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.denominationLegale}</p></div> */}
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Nature juridique :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.nature_juridique}</p></div> */}
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Siret :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.siret}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> INSEE :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.insee}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Adresse :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.adresse}, {props.detailShare.client.city}, {props.detailShare.client.country}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Code postal :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.postcode}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Région  :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                        {/* {props.departementListed && props.departementListed.length > 0 && (
                                                            <span style={{ color: '' }}>{props.departementListed.find((el) => el.id_departement === props.detailShare.client.region_siege).name_departement}</span>
                                                        )} */}
                                                        {props.detailShare.client.region_siege}
                                                        {/* {props.regionstListed && props.regionstListed.length > 0 && (
                                                            props.regionstListed.find((el) => el.id_region === props.detailShare.allDate.region_siege).name_region
                                                        )} */}
                                                    </p>
                                                </div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Département :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                        {/* {props.departementListed && props.departementListed.length > 0 && (
                                                            <span style={{ color: '' }}>{props.departementListed.find((el) => el.id_departement === props.detailShare.client.departement_siege).name_departement}</span>
                                                        )} */}
                                                        {props.detailShare.client.departement_siege}
                                                    </p>
                                                </div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Population :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{addSpaceInNUmber(props.detailShare.client.nombreHabitant)} ({props.detailShare.client.nombreHabitant_year})</p></div></>
                                        )}
                                        {(props.detailShare.client?.typePersonMoral === 'Epic' || props.detailShare.client?.typePersonMoral?.toLowerCase() === 'epci') && (
                                            <>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> État :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                        {Object.keys(props.detailShare.client).includes("state") && (<>
                                                            <i className={props.detailShare.client.state ? (props.detailShare.client.state === "A" ? "state-badge bg-success" : "state-badge bg-danger") : "state-badge bg-dark"}></i>
                                                            {props.detailShare.client.state ? (props.detailShare.client.state === "A" ? "Actif" : "Fermé") : "Indéfini"}
                                                        </>)}
                                                    </p>
                                                </div>
                                                {props.detailShare.client.logoA ? (
                                                    <div className='m-1-mine d-flex justify-content-start align-items-center my-4' style={{ color: '#6F6C90' }}>
                                                        <img src={`${props.detailShare.siteDD.client.client.logo[0].url}`} alt="a pict" width="150px" className='rounded' />
                                                    </div>
                                                )
                                                    : null
                                                    // <div className='m-1-mine d-flex justify-content-start align-items-center my-4' style={{ color: '#6F6C90' }}>
                                                    //     <p className='m-0'> Pas de logo</p>
                                                    // </div>
                                                }
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Catégorie :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>EPCI</p></div>
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Nom EPCI :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.nomEpic}</p></div> */}
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Nom Court :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.nom_court}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Dénomination :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.usual_denomination}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Nature juridique :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.nature_juridique}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Siret :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.siret}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Adresse :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.adresse}, {props.detailShare.client.city}, {props.detailShare.client.country}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Code postal :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.postcode}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Téléphone :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{formatTelephone(props.detailShare.client.telephoneStandard)}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Site internet :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.siteInternet}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Région :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                        {/* {props.departementListed && props.departementListed.length > 0 && (
                                                            <span style={{ color: '' }}>{props.departementListed.find((el) => el.id_departement === props.detailShare.client.region_siege).name_departement}</span>
                                                        )} */}
                                                        {/* {props.regionstListed && props.regionstListed.length > 0 && (
                                                            props.regionstListed.find((el) => el.id_region === props.detailShare.client.region_siege).name_region
                                                        )} */}
                                                        {props.detailShare.client.region_siege}

                                                    </p>
                                                </div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Département :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                        {/* {props.departementListed && props.departementListed.length > 0 && (
                                                            <span style={{ color: '' }}>{props.departementListed.find((el) => el.id_departement === props.detailShare.client.departement_siege).name_departement}</span> */}
                                                        {/* )} */}
                                                        {props.detailShare.client.departement_siege}

                                                    </p>
                                                </div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Population :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{addSpaceInNUmber(props.detailShare.client.nombreHabitant)} ({props.detailShare.client.nombreHabitant_year})</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Effectifs :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{addSpaceInNUmber(props.detailShare.client.effectifs)} ({props.detailShare.client.effectifs_year})</p></div></>
                                        )}
                                        {props.detailShare.client.typePersonMoral === 'Syndicat' && (
                                            <>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> État :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                        {Object.keys(props.detailShare.client).includes("state") && (<>
                                                            <i className={props.detailShare.client.state ? (props.detailShare.client.state === "A" ? "state-badge bg-success" : "state-badge bg-danger") : "state-badge bg-dark"}></i>
                                                            {props.detailShare.client.state ? (props.detailShare.client.state === "A" ? "Actif" : "Fermé") : "Indéfini"}
                                                        </>)}
                                                    </p>
                                                </div>
                                                {props.detailShare.client.logoA ? (
                                                    <div className='m-1-mine d-flex justify-content-start align-items-center my-4' style={{ color: '#6F6C90' }}>
                                                        <img src={`${props.detailShare.siteDD.client.client.logo[0].url}`} alt="a pict" width="150px" className='rounded' />
                                                    </div>
                                                )
                                                    : null
                                                    // <div className='m-1-mine d-flex justify-content-start align-items-center my-4' style={{ color: '#6F6C90' }}>
                                                    //     <p className='m-0'> Pas de logo</p>
                                                    // </div>
                                                }
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Catégorie :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>Syndicat</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Nom court :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.nomCourt}</p></div>
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Dénomination   :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.denominationLegale}</p></div> */}
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Dénomination :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.usual_denomination}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Nature juridique :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.nature_juridique}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Siret :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.siret}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> BE AMO :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.amobe}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Adresse :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.adresse}, {props.detailShare.client.city}, {props.detailShare.client.country}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Email Contact :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.email}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Téléphone :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{formatTelephone(props.detailShare.client.telephoneStandard)}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Site internet :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.siteInternet}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Population :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{addSpaceInNUmber(props.detailShare.client.nombreHabitant)} ({props.detailShare.client.nombreHabitant_year})</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Effectifs :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{addSpaceInNUmber(props.detailShare.client.effectifs)} ({props.detailShare.client.effectifs_year})</p></div>
                                            </>
                                        )}
                                        {props.detailShare.client.typePersonMoral === 'Societe' && (
                                            <>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> État :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                        {Object.keys(props.detailShare.client).includes("state") && (<>
                                                            <i className={props.detailShare.client.state ? (props.detailShare.client.state === "A" ? "state-badge bg-success" : "state-badge bg-danger") : "state-badge bg-dark"}></i>
                                                            {props.detailShare.client.state ? (props.detailShare.client.state === "A" ? "Actif" : "Fermé") : "Indéfini"}
                                                        </>)}
                                                    </p>
                                                </div>
                                                {props.detailShare.client.logoA ? (
                                                    <div className='m-1-mine d-flex justify-content-start align-items-center my-4' style={{ color: '#6F6C90' }}>
                                                        <img src={`${props.detailShare.siteDD.client.client.logo[0].url}`} alt="a pict" width="150px" className='rounded' />
                                                    </div>
                                                )
                                                    : null
                                                    // <div className='m-1-mine d-flex justify-content-start align-items-center my-4' style={{ color: '#6F6C90' }}>
                                                    //     <p className='m-0'> Pas de logo</p>
                                                    // </div>
                                                }
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Catégorie :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>Société</p></div>
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Dénomination  :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.denomination}</p></div> */}
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Dénomination :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.usual_denomination}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Nature juridique :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.nature_juridique}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Siret :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.siret}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Code Ape :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.codeape}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Adresse :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.adresse}, {props.detailShare.client.city}, {props.detailShare.client.country}</p></div>
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Email Contact :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.email}</p></div> */}
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Téléphone :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{formatTelephone(props.detailShare.client.telephoneStandard)}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Site internet :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.client.siteInternet}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Effectifs :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{addSpaceInNUmber(props.detailShare.client.effectifs_new)} ({props.detailShare.client.effectifs_year})</p></div>
                                                <div className='m-1-mine mt-4 d-flex justify-content-start align-items-center mt-5' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Groupe :</p>
                                                    <p className='text-dark m-0 gridCrad_p_dt' style={{ fontWeight: '500' }}>
                                                        {props.detailShare.siteDD.client.client.groupe &&
                                                            props.detailShare.siteDD.client.client.groupe.length > 0 ?
                                                            props.detailShare.siteDD.client.client.groupe.map((el) => {
                                                                return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={el.id_enemuration}>{el.value_enum}</code>
                                                            }) : '-'}
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                    </div>}
                            </div>
                            <div id='showThree' className='border border-right-0   px-6 py-6 rounded shadow' style={{ marginBottom: '50px' }}>
                                <h3 className='mb-4 d-flex justify-content-start align-items-center '>
                                    <img src="/media/logos/house.png" alt="a picture sokok" width="50" height='50' />
                                    <p className='m-1-mine mb-0'>Exploitant  :</p>
                                    <p className="border border-top-0 border-right-0 border-left-0 hr-details" style={{ height: '2px', flex: '1', margin: '0 10px' }} />
                                    {showThree ?
                                        <MinusSquareTwoTone className='' style={{ fontSize: '28px', }} onClick={() => setshowThree(false)} />
                                        :
                                        <PlusSquareFilled className='' style={{ fontSize: '28px' }} onClick={() => setshowThree(true)} />
                                    }
                                </h3>
                                {showThree &&
                                    <div className='ms-1'>
                                        {props.detailShare.company.typePersonMoral && props.detailShare.company.typePersonMoral === 'Commune' && (
                                            <>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> État :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                        {Object.keys(props.detailShare.company).includes("state") && (<>
                                                            <i className={props.detailShare.company.state ? (props.detailShare.company.state === "A" ? "state-badge bg-success" : "state-badge bg-danger") : "state-badge bg-dark"}></i>
                                                            {props.detailShare.company.state ? (props.detailShare.company.state === "A" ? "Actif" : "Fermé") : "Indéfini"}
                                                        </>)}
                                                    </p>
                                                </div>
                                                {props.detailShare.company.logoA ? (
                                                    <div className='m-1-mine d-flex justify-content-start align-items-center my-4' style={{ color: '#6F6C90' }}>
                                                        <img src={`${props.detailShare.siteDD.exploitant.client.logo[0].url}`} alt="a pict" width="150px" className='rounded' />
                                                    </div>
                                                )
                                                    : null
                                                    // <div className='m-1-mine d-flex justify-content-start align-items-center my-4' style={{ color: '#6F6C90' }}>
                                                    //     <p className='m-0'> Pas de logo</p>
                                                    // </div>
                                                }
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Catégorie :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>Commune</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Nom Commune :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.nom_court}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Dénomination :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.usual_denomination}</p></div>
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Dénomination   :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.denominationLegale}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Nature juridique :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.nature_juridique}</p></div> */}
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Siret :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.siret}</p></div>
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> INSEE :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.insee}</p></div> */}
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Adresse :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.adresse}, {props.detailShare.company.city}, {props.detailShare.company.country}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Code postal :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.postcode}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Population :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{addSpaceInNUmber(props.detailShare.company.nombreHabitant)} ({props.detailShare.company.nombreHabitant_year})</p></div>
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Région du siège :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                        {props.regionstListed && props.regionstListed.length > 0 && (
                                                            <span style={{ color: '' }}>{props.regionstListed.find((el) => el.id_region === props.detailShare.company.region_siege).name_region}</span>
                                                        )}
                                                    </p>
                                                </div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Département du siège :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                        {props.departementListed && props.departementListed.length > 0 && (
                                                            <span style={{ color: '' }}>{props.departementListed.find((el) => el.id_departement === props.detailShare.company.departement_siege).name_departement}</span>
                                                        )}
                                                    </p>
                                                </div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Population :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.nombreHabitant}</p></div> */}
                                            </>
                                        )}
                                        {props.detailShare.company.typePersonMoral && props.detailShare.company.typePersonMoral === 'Epic' && (
                                            <>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> État :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                        {Object.keys(props.detailShare.company).includes("state") && (<>
                                                            <i className={props.detailShare.company.state ? (props.detailShare.company.state === "A" ? "state-badge bg-success" : "state-badge bg-danger") : "state-badge bg-dark"}></i>
                                                            {props.detailShare.company.state ? (props.detailShare.company.state === "A" ? "Actif" : "Fermé") : "Indéfini"}
                                                        </>)}
                                                    </p>
                                                </div>
                                                {props.detailShare.company.logoA ? (
                                                    <div className='m-1-mine d-flex justify-content-start align-items-center my-4' style={{ color: '#6F6C90' }}>
                                                        <img src={`${props.detailShare.siteDD.exploitant.client.logo[0].url}`} alt="a pict" width="150px" className='rounded' />
                                                    </div>
                                                )
                                                    : null
                                                    // <div className='m-1-mine d-flex justify-content-start align-items-center my-4' style={{ color: '#6F6C90' }}>
                                                    //     <p className='m-0'> Pas de logo</p>
                                                    // </div>
                                                }
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Catégorie :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>EPCI</p></div>
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Nom EPCI :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.nomEpic}</p></div> */}
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Nom Court :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.nom_court}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Dénomination :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.usual_denomination}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Nature juridique :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.nature_juridique}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Siret :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.siret}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Adresse :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.adresse}, {props.detailShare.company.city}, {props.detailShare.company.country}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Code postal :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.postcode}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Téléphone :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{formatTelephone(props.detailShare.company.telephoneStandard)}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Site internet :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.siteInternet}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Population :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{addSpaceInNUmber(props.detailShare.company.nombreHabitant)} ({props.detailShare.company.nombreHabitant_year})</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Effectifs :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{addSpaceInNUmber(props.detailShare.company.effectifs)} ({props.detailShare.company.effectifs_year})</p></div>
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Région du siège :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                        {props.regionstListed && props.regionstListed.length > 0 && (
                                                            <span style={{ color: '' }}>{props.regionstListed.find((el) => el.id_region === props.detailShare.company.region_siege).name_region}</span>
                                                        )}
                                                    </p>
                                                </div> */}
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Département du siège :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                        {props.departementListed && props.departementListed.length > 0 && (
                                                            <span style={{ color: '' }}>{props.departementListed.find((el) => el.id_departement === props.detailShare.company.departement_siege).name_departement}</span>
                                                        )}
                                                    </p>
                                                </div> */}
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Population :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.nombreHabitant}</p>
                                                </div> */}
                                            </>
                                        )}
                                        {props.detailShare.company.typePersonMoral && props.detailShare.company.typePersonMoral === 'Syndicat' && (
                                            <>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> État :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                        {Object.keys(props.detailShare.company).includes("state") && (<>
                                                            <i className={props.detailShare.company.state ? (props.detailShare.company.state === "A" ? "state-badge bg-success" : "state-badge bg-danger") : "state-badge bg-dark"}></i>
                                                            {props.detailShare.company.state ? (props.detailShare.company.state === "A" ? "Actif" : "Fermé") : "Indéfini"}
                                                        </>)}
                                                    </p>
                                                </div>
                                                {props.detailShare.company.logoA ? (
                                                    <div className='m-1-mine d-flex justify-content-start align-items-center my-4' style={{ color: '#6F6C90' }}>
                                                        <img src={`${props.detailShare.siteDD.exploitant.client.logo[0].url}`} alt="a pict" width="150px" className='rounded' />
                                                    </div>
                                                )
                                                    : null
                                                    // <div className='m-1-mine d-flex justify-content-start align-items-center my-4' style={{ color: '#6F6C90' }}>
                                                    //     <p className='m-0'> Pas de logo</p>
                                                    // </div>
                                                }
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Catégorie :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>Syndicat</p></div>
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Nom court :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.nom_court}</p>
                                                </div> */}
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Dénomination  :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.denomination}</p></div> */}
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Dénomination :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.usual_denomination}</p></div>
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Nature juridique :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.nature_juridique}</p></div> */}
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Siret :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.siret}</p>
                                                </div> */}
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}> */}
                                                {/* <p className='m-0' style={{ width: '180px' }}> BE AMO :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.amobe}</p></div> */}
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Email :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.email}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Adresse :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.adresse}, {props.detailShare.company.city}, {props.detailShare.company.country}</p></div>
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Email Contact :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.email}</p></div> */}
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Téléphone :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{formatTelephone(props.detailShare.company.telephoneStandard)}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Site internet :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.siteInternet}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Population :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{addSpaceInNUmber(props.detailShare.company.nombreHabitant)} ({props.detailShare.company.nombreHabitant_year})</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Effectifs :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{addSpaceInNUmber(props.detailShare.company.effectifs)} ({props.detailShare.company.effectifs_year})</p></div>
                                            </>
                                        )}
                                        {props.detailShare.company.typeExploitant && props.detailShare.company.typeExploitant === 'Societe' && (
                                            <>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> État :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>
                                                        {Object.keys(props.detailShare.company).includes("state") && (<>
                                                            <i className={props.detailShare.company.state ? (props.detailShare.company.state === "A" ? "state-badge bg-success" : "state-badge bg-danger") : "state-badge bg-dark"}></i>
                                                            {props.detailShare.company.state ? (props.detailShare.company.state === "A" ? "Actif" : "Fermé") : "Indéfini"}
                                                        </>)}
                                                    </p>
                                                </div>
                                                {props.detailShare.company.logoA ? (
                                                    <div className='m-1-mine d-flex justify-content-start align-items-center my-4' style={{ color: '#6F6C90' }}>
                                                        <img src={`${props.detailShare.siteDD.exploitant.client.logo[0].url}`} alt="a pict" width="150px" className='rounded' />
                                                    </div>
                                                )
                                                    : null
                                                    // <div className='m-1-mine d-flex justify-content-start align-items-center my-4' style={{ color: '#6F6C90' }}>
                                                    //     <p className='m-0'> Pas de logo</p>
                                                    // </div> 
                                                }
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Catégorie :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>Société</p></div>
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Dénomination  :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.denomination}</p></div> */}
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Dénomination :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.usual_denomination}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Nature juridique :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.nature_juridique}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Siret :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.siret}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Code Ape :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.codeape}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Adresse :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.adresse}, {props.detailShare.company.city}, {props.detailShare.company.country}</p></div>
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Email Contact :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.email}</p></div> */}
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Téléphone :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{formatTelephone(props.detailShare.company.telephoneStandard)}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Site internet :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.siteInternet}</p></div>
                                                <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Effectifs :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{addSpaceInNUmber(props.detailShare.company.effectifs_new)} ({props.detailShare.company.effectifs_year})</p></div>
                                                <div className='m-1-mine mt-4 d-flex justify-content-start align-items-center mt-5' style={{ color: '#6F6C90' }}>
                                                    <p className='m-0' style={{ width: '180px' }}> Groupe :</p>
                                                    <p className='text-dark m-0 gridCrad_p_dt' style={{ fontWeight: '500' }}>
                                                        {props.detailShare.siteDD.exploitant.client.groupe &&
                                                            props.detailShare.siteDD.exploitant.client.groupe.length > 0 ?
                                                            props.detailShare.siteDD.exploitant.client.groupe.map((el) => {
                                                                return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={el.id_enemuration}>{el.value_enum}</code>
                                                            }) : '-'}
                                                    </p>
                                                </div>
                                                {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}> */}
                                                {/* <p className='m-0' style={{ width: '180px' }}> Population :</p>
                                                    <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.company.nombreHabitant}</p></div> */}
                                            </>
                                        )}
                                    </div >
                                }
                            </div >
                            <div id='showSix' className='border border-right-0   px-6 py-6 rounded shadow' style={{ marginBottom: '50px' }}>
                                <h3 className='mb-4 d-flex justify-content-start align-items-center '>
                                    <img src="/media/logos/contract.png" alt="a picture sokok" width="50" height='50' />
                                    <p className='m-1-mine mb-0'>Contrats   :</p>
                                    <p className="border border-top-0 border-right-0 border-left-0 hr-details" style={{ height: '2px', flex: '1', margin: '0 10px' }} />
                                    {showSix ?
                                        <MinusSquareTwoTone className='' style={{ fontSize: '28px', }} onClick={() => setshowSix(false)} />
                                        :
                                        <PlusSquareFilled className='' style={{ fontSize: '28px' }} onClick={() => setshowSix(true)} />
                                    }
                                </h3>
                                {showSix &&
                                    <div className='ms-1'>
                                        {props.detailShare.contracts && props.detailShare.contracts.length > 0 ?
                                            <>
                                                <table className='table_contrats'>
                                                    <thead>
                                                        <tr>
                                                            <td style={{ width: '30%' }}>CONTRACTANT</td>
                                                            <td style={{ width: '30%' }}>ACTEUR PUBLIC</td>
                                                            <td style={{ width: '20%' }}>MODE DE GESTION</td>
                                                            <td style={{ width: '10%' }}>DÉBUT DU CONTRAT</td>
                                                            <td style={{ width: '10%' }}>FIN DU CONTRAT</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {props.detailShare.contracts.map((el, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{el.contractant_denomination}</td>
                                                                    <td>{el.client_denomination}</td>
                                                                    <td>{el.site_modeGestion}</td>
                                                                    <td>{el.dateDebut}</td>
                                                                    <td>{el.dateFin}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>
                                            :
                                            <div className="alert alert-danger text-center" role="alert">
                                                <b>Aucun Contrat</b>
                                            </div>
                                        }
                                        {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ width: '180px' }}> Catégorie :</p>
                                            <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.siteDD.exploitant.typeExploitant}</p>
                                        </div>
                                        <div className='m-1-mine mt-4 d-flex justify-content-start align-items-center' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ width: '180px' }}> Groupe :</p>
                                            <p className='text-dark m-0 gridCrad_p' style={{ fontWeight: '500' }}>
                                                {props.detailShare.siteDD.exploitant.client.groupe &&
                                                    props.detailShare.siteDD.exploitant.client.groupe.length > 0 ?
                                                    props.detailShare.siteDD.exploitant.client.groupe.map((el) => {
                                                        return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={el.id_enemuration}>{el.value_enum}</code>
                                                    }) : '-'}
                                            </p>
                                        </div> */}
                                    </div >
                                }
                            </div >
                            <div id='showSeven' className='border border-right-0   px-6 py-6 rounded shadow' style={{ marginBottom: '50px' }}>
                                <h3 className='mb-4 d-flex justify-content-start align-items-center '>
                                    <img src="/media/logos/sign-document.png" alt="a picture sokok" width="50" height='50' />
                                    <p className='m-1-mine mb-0'>Documentation :</p>
                                    <p className="border border-top-0 border-right-0 border-left-0 hr-details" style={{ height: '2px', flex: '1', margin: '0 10px' }} />
                                    {showSeven ?
                                        <MinusSquareTwoTone className='' style={{ fontSize: '28px', }} onClick={() => setshowSeven(false)} />
                                        :
                                        <PlusSquareFilled className='' style={{ fontSize: '28px' }} onClick={() => setshowSeven(true)} />
                                    }
                                </h3>
                                {showSeven &&
                                    <div className='ms-1'>
                                        {props.detailShare.files && props.detailShare.files.length > 0 ?
                                            <>
                                                <table className='table_contrats'>
                                                    <thead>
                                                        <tr>
                                                            <td style={{ width: '50%' }}>Catégorie</td>
                                                            <td style={{ width: '50%' }}>Date</td>
                                                            <td style={{ width: '10%' }}>Fichier </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {props.detailShare.files.map((el, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{el.category.value_enum}</td>
                                                                    <td>{el.date}</td>
                                                                    <td style={{ fontWeight: '800' }}>
                                                                        <a href={el.name} target='_blank' rel="noreferrer" className='text-primary'>
                                                                            <LinkOutlined className='' style={{ fontSize: '25px' }} />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>
                                            :
                                            <div className="alert alert-danger text-center" role="alert">
                                                <b>Aucun fichier</b>
                                            </div>
                                        }
                                        {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ width: '180px' }}> Catégorie :</p>
                                            <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.siteDD.exploitant.typeExploitant}</p>
                                        </div>
                                        <div className='m-1-mine mt-4 d-flex justify-content-start align-items-center' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ width: '180px' }}> Groupe :</p>
                                            <p className='text-dark m-0 gridCrad_p' style={{ fontWeight: '500' }}>
                                                {props.detailShare.siteDD.exploitant.client.groupe &&
                                                    props.detailShare.siteDD.exploitant.client.groupe.length > 0 ?
                                                    props.detailShare.siteDD.exploitant.client.groupe.map((el) => {
                                                        return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={el.id_enemuration}>{el.value_enum}</code>
                                                    }) : '-'}
                                            </p>
                                        </div> */}
                                    </div >
                                }
                            </div >
                            <div id='showHieght' className='border border-right-0   px-6 py-6 rounded shadow' style={{ marginBottom: '50px' }}>
                                <h3 className='mb-4 d-flex justify-content-start align-items-center '>
                                    <img src="/media/logos/location-france.png" alt="a picture sokok" width="50" height='50' />
                                    <p className='m-1-mine mb-0'>Site de même collectivité :</p>
                                    <p className="border border-top-0 border-right-0 border-left-0 hr-details" style={{ height: '2px', flex: '1', margin: '0 10px' }} />
                                    {showHieght ?
                                        <MinusSquareTwoTone className='' style={{ fontSize: '28px', }} onClick={() => setshowHieght(false)} />
                                        :
                                        <PlusSquareFilled className='' style={{ fontSize: '28px' }} onClick={() => setshowHieght(true)} />
                                    }
                                </h3>
                                {showHieght &&
                                    <div className='ms-1'>
                                        {props.detailShare.sitesSameClient && props.detailShare.sitesSameClient.length > 0 ?
                                            <>
                                                <table className='table_contrats'>
                                                    <thead>
                                                        <tr>
                                                            <td style={{ width: '25%' }}>Dénomination </td>
                                                            <td style={{ width: '15%' }}>Catégorie</td>
                                                            <td style={{ width: '60%' }}>Adresse</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {props.detailShare.sitesSameClient.map((el, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td style={{ fontWeight: '800' }}>
                                                                        <a href={`/site/${el.id_site}/${el.id_access}`} target='_blank' rel="noreferrer" className='text-primary'>{el.denomination}</a>
                                                                    </td>
                                                                    <td>{el.categorieSite}</td>
                                                                    <td>{el.adresse}, {el.postcode}, {el.city}, {el.country}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>
                                            :
                                            <div className="alert alert-danger text-center" role="alert">
                                                <b>Aucun site</b>
                                            </div>
                                        }
                                        {/* <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ width: '180px' }}> Catégorie :</p>
                                            <p className='text-dark m-0' style={{ fontWeight: '500' }}>{props.detailShare.siteDD.exploitant.typeExploitant}</p>
                                        </div>
                                        <div className='m-1-mine mt-4 d-flex justify-content-start align-items-center' style={{ color: '#6F6C90' }}>
                                            <p className='m-0' style={{ width: '180px' }}> Groupe :</p>
                                            <p className='text-dark m-0 gridCrad_p' style={{ fontWeight: '500' }}>
                                                {props.detailShare.siteDD.exploitant.client.groupe &&
                                                    props.detailShare.siteDD.exploitant.client.groupe.length > 0 ?
                                                    props.detailShare.siteDD.exploitant.client.groupe.map((el) => {
                                                        return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={el.id_enemuration}>{el.value_enum}</code>
                                                    }) : '-'}
                                            </p>
                                        </div> */}
                                    </div >
                                }
                            </div >
                        </div>
                        <div className='position-absolutde endd-0 pb-10'
                            style={{ width: props.detailShare.infoBase.categorieSite === 'UVE' ? '38%' : '35%', top: '131px' }}>
                            <div className=' py-6 px-8 rounded  bg-white shadow-mine    '>
                                <h5 className='bg-dark px-2 py-6 rounded text-light text-center mb-6'>
                                    Informations techniques
                                </h5>
                                {props.detailShare.infoBase.categorieSite === 'TMB' && (
                                    <div className='TMBSitesShared'>
                                        <div className='rowGrid mb-4'>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Quantité de refus (t)</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.quantiteRefus}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>CSR produit (t)</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.CSRProduit}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Envoi pour préparation CSR (t)</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.envoiPreparation}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Tonnage annuel</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.tonnageAnnuel}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Capacité nominale</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.capaciteNominal}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Dernier constructeur connu</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.dernierConstruct}</p>
                                            </div>
                                            {showFoor && (
                                                <>
                                                    <div className='d-flex justify-content-between align-items-start'>
                                                        <p className='m-2' style={{ fontWeight: '500', }}>Type d'installations</p>
                                                        <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.typeInstallation}</p>
                                                    </div>
                                                    <div className='d-flex justify-content-start align-items-start mt-3 bg-light p-2 rounded'>
                                                        <p className='m-2' style={{ fontWeight: '500', width: '50%' }}>Types de déchets acceptés</p>
                                                        <div className='m-2 gridCrad_p' style={{ fontWeight: '700', flex: '1' }}>{props.detailShare.infoTech.typeDechetAccepter &&
                                                            props.detailShare.infoTech.typeDechetAccepter.length > 0 ?
                                                            props.detailShare.infoTech.typeDechetAccepter.map((el) => {
                                                                return <code className='bg-black text-light rounded p-2 m-1' key={el.id_enemuration}>{el.value_enum}</code>
                                                            }) : '-'}</div>
                                                    </div>
                                                    <div className='d-flex justify-content-start align-items-start mt-3 bg-light p-2 rounded'>
                                                        <p className='m-2' style={{ fontWeight: '500', width: '50%' }}>Technologie</p>
                                                        <div className='m-2 gridCrad_p' style={{ fontWeight: '700', flex: '1' }}>{props.detailShare.infoTech.technologie &&
                                                            props.detailShare.infoTech.technologie.length > 0 ?
                                                            props.detailShare.infoTech.technologie.map((el) => {
                                                                return <code className='bg-black text-light rounded p-2 m-1' key={el.id_enemuration}>{el.value_enum}</code>
                                                            }) : '-'}</div>
                                                    </div>
                                                    <div className='d-flex justify-content-start align-items-start mt-3 bg-light p-2 rounded'>
                                                        <p className='m-2' style={{ fontWeight: '500', width: '50%' }}>Valorisation énergétique</p>
                                                        <div className='m-2 gridCrad_p' style={{ fontWeight: '700', flex: '1' }}>{props.detailShare.infoTech.valorisationEnergitique &&
                                                            props.detailShare.infoTech.valorisationEnergitique.length > 0 ?
                                                            props.detailShare.infoTech.valorisationEnergitique.map((el) => {
                                                                return <code className='bg-black text-light rounded p-2 m-1' key={el.id_enemuration}>{el.value_enum}</code>
                                                            }) : '-'}</div>
                                                        {/* <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.valorisationEnergitique}</p>Array */}

                                                    </div>
                                                    <div className='d-flex justify-content-start align-items-start mt-3 bg-light p-2 rounded'>
                                                        <p className='m-2' style={{ fontWeight: '500', width: '50%' }}>Activités*</p>
                                                        <div className='m-2 gridCrad_p' style={{ fontWeight: '700', flex: '1' }}>{props.detailShare.infoTech.autreActivite &&
                                                            props.detailShare.infoTech.autreActivite.length > 0 ?
                                                            props.detailShare.infoTech.autreActivite.map((el) => {
                                                                return <code className='bg-black text-light rounded p-2 m-1' key={el.id_enemuration}>{el.value_enum}</code>
                                                            }) : '-'}</div>

                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-start mt-4'>
                                                        <p className='m-2' style={{ fontWeight: '500', }}>Commentaires</p>
                                                        <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoBase.comments}</p>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <hr />
                                        <h3 className='btnPlusDetailsother d-flex justify-content-between align-items-center' type='button' id='' onClick={() => {
                                            setshowFoor(!showFoor)
                                        }}>
                                            {showFoor ? 'Moins' : 'Plus'} de détails
                                            {showFoor ? <UpOutlined style={{ fontSize: '15px', margin: '0 0 0 5px' }} /> : <DownOutlined style={{ fontSize: '15px', margin: '0 0 0 5px' }} />}
                                        </h3>
                                    </div>
                                )}
                                {props.detailShare.infoBase.categorieSite === 'TRI' && (
                                    <div className='TRISitesShared'>
                                        <div className='rowGrid mb-4'>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Capacité horaire Tonnes/h</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.capaciteHoraire}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Capacité nominale (T/an)</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.capaciteNominale}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Capacité réglementaire</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.capaciteReglementaire}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Dernier constructeur connu</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.dernierConstructeur}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Date d'extension</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.dateExtension}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Date mise en service</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.miseEnService}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Extension</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.extension}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {props.detailShare.infoBase.categorieSite === 'UVE' && (
                                    <div className='UVESitesShared'>
                                        <h3 className='mx-2 mt-2 mb-4 text-primary text-center'>Informations techniques générales</h3>
                                        <div className='rowGrid mb-4'>
                                            <div className='d-flex justify-content-between align-items-start mb-1'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>
                                                    Type de déchets recus
                                                </p>
                                                <p className='m-2 gridCrad_p' style={{ fontWeight: '700' }}>
                                                    {props.detailShare.infoTech.infos.typeDechetRecus &&
                                                        props.detailShare.infoTech.infos.typeDechetRecus.length > 0 ?
                                                        props.detailShare.infoTech.infos.typeDechetRecus.map((el, ind) => {
                                                            return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={ind}>{el}</code>
                                                        }) : '-'}
                                                </p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start mb-1'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Installations complémentaires</p>
                                                <p className='m-2 gridCrad_p' style={{ fontWeight: '700' }}>
                                                    {props.detailShare.infoTech.infos.installationComplementair &&
                                                        props.detailShare.infoTech.infos.installationComplementair.length > 0 ?
                                                        props.detailShare.infoTech.infos.installationComplementair.map((el, ind) => {
                                                            return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={ind}>{el}</code>
                                                        }) : '-'}
                                                </p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Capacité technique </p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{addSpaceInNUmber(props.detailShare.infoTech.infos.capacite)} (t/an)</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Capacité règlementaire de l’AP</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{addSpaceInNUmber(props.detailShare.infoTech.infos.tonnageReglementaireAp)} (t/an)</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Vide de four</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{addSpaceInNUmber(props.detailShare.infoTech.infos.videFour)} (t/an)</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Ligne(s) en service</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.lines.reduce((total, line) => {
                                                    if (line.line_in_use && line.line_in_use === 'oui') {
                                                        return total + 1;
                                                    }
                                                    return total;
                                                }, 0)
                                                }</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Types de valorisation énergétique</p>
                                                <p className='m-2 gridCrad_p' style={{ fontWeight: '700' }}>
                                                    {props.detailShare.infoTech.valorisations.blocks &&
                                                        props.detailShare.infoTech.valorisations.blocks.length > 0 ?
                                                        props.detailShare.infoTech.valorisations.blocks.map((el, ind) => {
                                                            return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={ind}>
                                                                {el.type === 'electric' && 'Electrique'}
                                                                {el.type === 'thermique' && 'Thermique'}
                                                                {el.type === 'hydrogene' && 'Hydrogene'}
                                                            </code>
                                                        }) : '-'}
                                                </p>
                                            </div>
                                            {/* <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Commentaires usine</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoBase.comments}</p>
                                            </div> */}
                                            {showFoor && (
                                                <div className=''>
                                                    <hr className='mb-8' />
                                                    <h3 className='mx-2 mt-2 mb-7 text-primary text-center position-relative'>Lignes d'incinération</h3>
                                                    {props.detailShare.infoTech.lines && props.detailShare.infoTech.lines.length > 0 && (
                                                        props.detailShare.infoTech.lines.map((el, indx) => (
                                                            <div className={`${!el.line_in_use || el.line_in_use === "oui" ? "bg-warning-mine" : "bg-danger-mine"} rounded p-2 my-4 position-relative`} key={indx}>
                                                                <div className='d-flex justify-content-between align-items-start mt-6'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Date mise en service</p>
                                                                    <p className='m-2' style={{ fontWeight: '700' }}>{el.miseEnService}</p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Date revamping</p>
                                                                    <p className='m-2' style={{ fontWeight: '700' }}>{el.revampingDate}</p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-start mb-6'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Date arret ligne</p>
                                                                    <p className='m-2' style={{ fontWeight: '700' }}>{el.arretDate}</p>
                                                                </div>
                                                                <h4 className={`text-underline mb-2 mt-1 text-center position-absolute text-dark py-2 px-6 ${!el.line_in_use || el.line_in_use === "oui" ? "bg-primary" : "bg-danger-light"}`}
                                                                    style={{
                                                                        top: '-15px', left: '0', right: '0', margin: '0 auto', width: '100%',
                                                                        borderTopLeftRadius: '5px', borderTopRightRadius: '5px'
                                                                    }}>{el.line_name ? el.line_name : `Ligne-${indx + 1}`}</h4>
                                                                <h4 className='text-underline mb-2 text-center'>Four-Chaudiere</h4>
                                                                <hr className='mt-0 mx-10' />
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Capacité</p>
                                                                    <p className='m-2' style={{ fontWeight: '700' }}>{el.capacite} (t/h)</p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>PCI</p>
                                                                    <p className='m-2' style={{ fontWeight: '700' }}>{el.pci} (kcal/kg)</p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Type de four</p>
                                                                    <p className='m- gridCrad_p' style={{ fontWeight: '700' }}>
                                                                        {el.typeFours &&
                                                                            el.typeFours.length > 0 ?
                                                                            el.typeFours.map((el, ind) => {
                                                                                return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={ind}>{el}</code>
                                                                            }) : '-'}
                                                                    </p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Constructeur four</p>
                                                                    <p className='m-2' style={{ fontWeight: '700' }}>{el.constructeurInstallation}</p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Type de chaudière</p>
                                                                    <p className='m-2 gridCrad_p' style={{ fontWeight: '700' }}>
                                                                        {el.typeChaudiere &&
                                                                            el.typeChaudiere.length > 0 ?
                                                                            el.typeChaudiere.map((el, ind) => {
                                                                                return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={ind}>{el}</code>
                                                                            }) : '-'}
                                                                    </p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Constructeur Chaudière</p>
                                                                    <p className='m-2' style={{ fontWeight: '700' }}>{el.constructeurChaudiere}</p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Débit Vapeur</p>
                                                                    <p className='m-2' style={{ fontWeight: '700' }}>{el.debitVapeur} (t/h)</p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Pression chaudière</p>
                                                                    <p className='m-2' style={{ fontWeight: '700' }}>{el.cycleVapeurPression} (bar)</p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Température chaudière</p>
                                                                    <p className='m-2' style={{ fontWeight: '700' }}>{el.cycleVapeurTemp}  (°C)</p>
                                                                </div>
                                                                <h4 className='text-underline mb-2 mt-10 text-center'>Traitement des fumées</h4>
                                                                <hr className='mt-0 mx-10' />
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Voie de traitement des fumées</p>
                                                                    <p className='m-2 gridCrad_p' style={{ fontWeight: '700' }}>
                                                                        {el.traitementFumee &&
                                                                            el.traitementFumee.length > 0 ?
                                                                            el.traitementFumee.map((el, ind) => {
                                                                                return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={ind}>{el}</code>
                                                                            }) : '-'}
                                                                    </p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Equipement de process TF</p>
                                                                    <p className='m-2 gridCrad_p' style={{ fontWeight: '700' }}>
                                                                        {el.equipeProcessTF &&
                                                                            el.equipeProcessTF.length > 0 ?
                                                                            el.equipeProcessTF.map((el, ind) => {
                                                                                return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={ind}>{el}</code>
                                                                            }) : '-'}
                                                                    </p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Réactifs traitement des fumées</p>
                                                                    <p className='m-2 gridCrad_p' style={{ fontWeight: '700' }}>
                                                                        {el.reactif &&
                                                                            el.reactif.length > 0 ?
                                                                            el.reactif.map((el, ind) => {
                                                                                return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={ind}>{el}</code>
                                                                            }) : '-'}
                                                                    </p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Type traitement des oxydes d'azote (NOx)</p>
                                                                    <p className='m-2 gridCrad_p' style={{ fontWeight: '700' }}>
                                                                        {el.traitementNOX &&
                                                                            el.traitementNOX.length > 0 ?
                                                                            el.traitementNOX.map((el, ind) => {
                                                                                return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={ind}>{el}</code>
                                                                            }) : '-'}
                                                                    </p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Réactifs DENOX</p>
                                                                    <p className='m-2 gridCrad_p' style={{ fontWeight: '700' }}>
                                                                        {el.reactifNOX &&
                                                                            el.reactifNOX.length > 0 ?
                                                                            el.reactifNOX.map((el, ind) => {
                                                                                return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={ind}>{el}</code>
                                                                            }) : '-'}
                                                                    </p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Installations complémentaires</p>
                                                                    <p className='m-2 gridCrad_p' style={{ fontWeight: '700' }}>
                                                                        {el.installationComplementair &&
                                                                            el.installationComplementair.length > 0 ?
                                                                            el.installationComplementair.map((el, ind) => {
                                                                                return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={ind}>{el}</code>
                                                                            }) : '-'}
                                                                    </p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-start'>
                                                                    <p className='m-2' style={{ fontWeight: '500', }}>Commentaire traitement des fumées</p>
                                                                    <p className='m-2' style={{ fontWeight: '700' }}>{el.commentTraitementFumee}</p>
                                                                </div>
                                                            </div>
                                                        )))}



                                                    <hr className='my-8' />
                                                    <h3 className='mx-2 mt-2 mb-4 text-primary text-center'>Informations communes valorisation énergétique</h3>
                                                    <div className='d-flex justify-content-between align-items-start mb-1'>
                                                        <p className='m-2' style={{ fontWeight: '500', }}>Type valorisation</p>
                                                        <p className='m-2 gridCrad_p' style={{ fontWeight: '700' }}>
                                                            {props.detailShare.infoTech.valorisations.valorisationTypes &&
                                                                props.detailShare.infoTech.valorisations.valorisationTypes.length > 0 ?
                                                                props.detailShare.infoTech.valorisations.valorisationTypes.map((el, ind) => {
                                                                    return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={ind}>
                                                                        {el === 'electric' && 'Electrique'}
                                                                        {el === 'thermique' && 'Thermique'}
                                                                        {el === 'hydrogene' && 'Hydrogene'}
                                                                    </code>
                                                                }) : '-'}
                                                        </p>
                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-start'>
                                                        <p className='m-2' style={{ fontWeight: '500', }}>Agrégateur - acheteur électricité</p>
                                                        <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.valorisations.agregateurElectrique}</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-start'>
                                                        <p className='m-2' style={{ fontWeight: '500', }}>Performance énergétique(Pe) {props.detailShare.infoTech.valorisations.performenceEnergetiqueDate && '(' + props.detailShare.infoTech.valorisations.performenceEnergetiqueDate + ")"}</p>
                                                        <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.valorisations.performenceEnergetique} %</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-start'>
                                                        <p className='m-2' style={{ fontWeight: '500', }}>Performance énergétique(R1) {props.detailShare.infoTech.valorisations.performenceEnergetiqueR1Date && '(' + props.detailShare.infoTech.valorisations.performenceEnergetiqueR1Date + ")"}</p>
                                                        <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.valorisations.performenceEnergetiqueR1} %</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-start'>
                                                        <p className='m-2' style={{ fontWeight: '500', }}>Electricité vendue</p>
                                                        <p className='m-2' style={{ fontWeight: '700' }}>{addSpaceInNUmber(props.detailShare.infoTech.valorisations.electriciteVendue)} (MWh/an)</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-start'>
                                                        <p className='m-2' style={{ fontWeight: '500', }}>Chaleur vendue</p>
                                                        <p className='m-2' style={{ fontWeight: '700' }}>{addSpaceInNUmber(props.detailShare.infoTech.valorisations.chaleurVendue)} (MWh/an)</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-start'>
                                                        <p className='m-2' style={{ fontWeight: '500', }}>Quantité H2 vendue</p>
                                                        <p className='m-2' style={{ fontWeight: '700' }}>{addSpaceInNUmber(props.detailShare.infoTech.valorisations.H2Vendue)} (t/an)</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-start'>
                                                        <p className='m-2' style={{ fontWeight: '500', }}>Informations complémentaires</p>
                                                        <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.valorisations.informationComplementaire}</p>
                                                    </div>
                                                    <hr className='my-8' />
                                                    <h3 className='mx-2 mt-2 mb-4 text-primary text-center'>Valorisation énergétique</h3>
                                                    {
                                                        props.detailShare.infoTech.valorisations.blocks && props.detailShare.infoTech.valorisations.blocks.length > 0 && (
                                                            props.detailShare.infoTech.valorisations.blocks.map((el, indx) => (
                                                                <div className='bg-warning-mine p-2 my-3 position-relative' key={indx}>
                                                                    {/* <h4 className='text-underline mb-2 mt-4 text-center'>Valorisation {ind + 1} :</h4> */}
                                                                    <h4 className='text-underline mb-2 mt-4 text-center position-absolute text-dark py-2 px-6 bg-primary'
                                                                        style={{
                                                                            top: '-15px', left: '0', right: '0', margin: '0 auto', width: '100%',
                                                                            borderTopLeftRadius: '5px', borderTopRightRadius: '5px'
                                                                        }}>Valorisation-{indx + 1}</h4>
                                                                    {/* <hr className='mt-0 mx-10' /> */}
                                                                    <div className='d-flex justify-content-between align-items-start mt-10'>
                                                                        <p className='m-2' style={{ fontWeight: '500', }}>Type</p>
                                                                        <p className='m-2' style={{ fontWeight: '700' }}>
                                                                            {el.type === 'electric' && 'Electrique'}
                                                                            {el.type === 'thermique' && 'Thermique'}
                                                                            {el.type === 'hydrogene' && 'Hydrogene'}
                                                                        </p>
                                                                    </div>
                                                                    <div className='d-flex justify-content-between align-items-start'>
                                                                        <p className='m-2' style={{ fontWeight: '500', }}>Nom</p>
                                                                        <p className='m-2' style={{ fontWeight: '700' }}>{el.name}</p>
                                                                    </div>
                                                                    <div className='d-flex justify-content-between align-items-start'>
                                                                        <p className='m-2' style={{ fontWeight: '500', }}>Date mise en service</p>
                                                                        <p className='m-2' style={{ fontWeight: '700' }}>{el.miseEnService}</p>
                                                                    </div>
                                                                    {el.type === 'thermique' && (
                                                                        <>
                                                                            <div className='d-flex justify-content-between align-items-start'>
                                                                                <p className='m-2' style={{ fontWeight: '500', }}>RCU / INDUSTRIEL</p>
                                                                                <p className='m-2' style={{ fontWeight: '700' }}>{el.RCUIndustirel}</p>
                                                                            </div>
                                                                            <div className='d-flex justify-content-between align-items-start'>
                                                                                <p className='m-2' style={{ fontWeight: '500', }}>Client</p>
                                                                                <p className='m-2 gridCrad_p' style={{ fontWeight: '700' }}>
                                                                                    {el.client &&
                                                                                        el.client.length > 0 ?
                                                                                        el.client.map((el, ind) => {
                                                                                            return <code className='bg-dark text-light rounded p-2 m-1-mine' style={{ fontSize: '10px' }} key={ind}>{el}</code>
                                                                                        }) : '-'}
                                                                                </p>
                                                                            </div>
                                                                            <div className='d-flex justify-content-between align-items-start'>
                                                                                <p className='m-2' style={{ fontWeight: '500', }}>Puissance Installée</p>
                                                                                <p className='m-2' style={{ fontWeight: '700' }}>{el.puissanceInstallee} (MW)</p>
                                                                            </div>
                                                                            <div className='d-flex justify-content-between align-items-start'>
                                                                                <p className='m-2' style={{ fontWeight: '500', }}>Chaleur vendue</p>
                                                                                <p className='m-2' style={{ fontWeight: '700' }}>{addSpaceInNUmber(el.chaleurVendue)} (MWh/an)</p>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    {el.type === 'electric' && (
                                                                        <>
                                                                            <div className='d-flex justify-content-between align-items-start'>
                                                                                <p className='m-2' style={{ fontWeight: '500', }}>Type équipement</p>
                                                                                <p className='m-2' style={{ fontWeight: '700' }}>{el.typeEquipement}</p>
                                                                            </div>
                                                                            <div className='d-flex justify-content-between align-items-start'>
                                                                                <p className='m-2' style={{ fontWeight: '500', }}>Marque équipement</p>
                                                                                <p className='m-2' style={{ fontWeight: '700' }}>{el.marqueEquipement}</p>
                                                                            </div>
                                                                            <div className='d-flex justify-content-between align-items-start'>
                                                                                <p className='m-2' style={{ fontWeight: '500', }}>Puissance Installée</p>
                                                                                <p className='m-2' style={{ fontWeight: '700' }}>{el.puissanceInstallee} (MW)</p>
                                                                            </div>
                                                                            <div className='d-flex justify-content-between align-items-start'>
                                                                                <p className='m-2' style={{ fontWeight: '500', }}>Electricité vendue</p>
                                                                                <p className='m-2' style={{ fontWeight: '700' }}>{addSpaceInNUmber(el.electriciteVendue)} (MWh/a)</p>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    {el.type === 'hydrogene' && (
                                                                        <>
                                                                            <div className='d-flex justify-content-between align-items-start'>
                                                                                <p className='m-2' style={{ fontWeight: '500', }}>Marque équipement</p>
                                                                                <p className='m-2' style={{ fontWeight: '700' }}>{el.marqueEquipement}</p>
                                                                            </div>
                                                                            <div className='d-flex justify-content-between align-items-start'>
                                                                                <p className='m-2' style={{ fontWeight: '500', }}>Puissance électrolyseur</p>
                                                                                <p className='m-2' style={{ fontWeight: '700' }}>{el.puissanceElectrolyseur} (MW)</p>
                                                                            </div>
                                                                            <div className='d-flex justify-content-between align-items-start'>
                                                                                <p className='m-2' style={{ fontWeight: '500', }}>Quantité H2 vendue</p>
                                                                                <p className='m-2' style={{ fontWeight: '700' }}>{el.H2Vendue} (t/an)</p>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            ))
                                                        )
                                                    }
                                                </div>
                                            )}
                                        </div>
                                        <hr />
                                        <h3 className='btnPlusDetailsother d-flex justify-content-between align-items-center' type='button' id='' onClick={() => {
                                            setshowFoor(!showFoor)
                                        }}>
                                            {showFoor ? 'Moins' : 'Plus'} de détails
                                            {showFoor ? <UpOutlined style={{ fontSize: '15px', margin: '0 0 0 5px' }} /> : <DownOutlined style={{ fontSize: '15px', margin: '0 0 0 5px' }} />}
                                        </h3>
                                    </div>
                                )}
                                {props.detailShare.infoBase.categorieSite === 'ISDND' && (
                                    <div className='ISDNDSitesShared'>
                                        <div className='rowGrid mb-4'>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Capacité nominale</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.capaciteNominale}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Capacité restante</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.capaciteRestante}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Capacité réglementaire</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.capaciteReglementaire}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Projet d'extension ?</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>
                                                    {props.detailShare.infoTech.projetExtension === true && 'Oui'}
                                                    {props.detailShare.infoTech.projetExtension === false && 'Non'}
                                                </p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Date d'extension</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.dateExtension}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Date d'ouverture</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.dateOuverture}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Date de fermeture</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.dateFermeture}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-start'>
                                                <p className='m-2' style={{ fontWeight: '500', }}>Date de fermeture prévisionnelle</p>
                                                <p className='m-2' style={{ fontWeight: '700' }}>{props.detailShare.infoTech.dateFermeturePrev}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>




                    <div className='d-flex justify-content-end align-items-center mt-4'>
                        <p style={{ fontSize: '15px', fontWeight: '600' }}>Date Mise à jour : <span style={{ margin: '0 5px', fontWeight: '800' }}>{To_date(props.detailShare.siteDD.updated_at)}</span></p>
                    </div>
                    {/* <div id='showFive' className='border border-right-0   px-6 py-6 rounded shadow' style={{ width: '60%', marginBottom: '50px' }}>
                    <h3 className='mb-4 d-flex justify-content-between align-items-center '>
                        <p className='m-1-mine'>Données contractuelles   :</p>
                        {showFive ?
                            <MinusSquareTwoTone className='' style={{ fontSize: '28px', }} onClick={() => setshowFive(false)} />
                            :
                            <PlusSquareFilled className='' style={{ fontSize: '28px' }} onClick={() => setshowFive(true)} />
                        }
                    </h3>
                    {showFive && (
                        <div className='ms-1'>
                            <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>Début: <span className='text-dark ms-4' style={{ fontWeight: '500'}}>{props.detailShare.share.start}</span></p>
                            <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>Fin: <span className='text-dark ms-4' style={{ fontWeight: '500'}}>{props.detailShare.share.end}</span></p>
                            <div className='m-1-mine d-flex justify-content-start align-items-center ' style={{ color: '#6F6C90' }}>Contractants 1 et 2: <span className='text-dark ms-4' style={{ fontWeight: '500'}}>X and X</span></p>
                        </div>
                    )}
                </div> */}
                </div >
            )
            }
        </div >
    )
}

function mapState(state) {
    const { laodingDetail, detailShare, sitesMapErrors, regionstListed, departementListed } = state.map
    return { laodingDetail, detailShare, sitesMapErrors, regionstListed, departementListed }
}

const connectedClass = connect(mapState, { fetchDetailShare, fetchAllDepartemnts })(SitesDetails)
export { connectedClass as SitesDetails }