import { mapSlice,callTypesP as callTypes } from "./map.reducer";
import * as MapRequests from "./map.crud";
const { actions } = mapSlice;
export const fetchSitesMap = (lat,lang,QueryParams) => dispatch => {
    dispatch(actions.startCall({callType:callTypes.listSites}))
    return MapRequests
        .fetchSitesMap(lat,lang,QueryParams)
        .then(res => {
            if(res && res.data && res.data.ok){
                dispatch(actions.sitesFetched({listSites:res.data,configs:{
                    lat:lat,
                    lang:lang,
                    QueryParams:QueryParams
                }}))
            }else{
                dispatch(actions.catchError({errors:"fail to load sites",callType:callTypes.listSites}))
            }
        })
        .catch(err => {
            dispatch(actions.catchError({errors:"fail to load sites",callType:callTypes.listSites}))
        });
};
export const getGroupes = () => dispatch => {
    dispatch(actions.startCall({callType:callTypes.listSites}))
    return MapRequests
        .getGroupes()
        .then(res => {
            if(res && res.data && res.data.ok){
                dispatch(actions.groupsListed(res.data))
            }else{
                dispatch(actions.catchError({errors:"fail to load sites",callType:callTypes.listSites}))
            }
        })
        .catch(err => {
            dispatch(actions.catchError({errors:"fail to load sites",callType:callTypes.listSites}))
        });
};
export const fetchSitesMapNew = (QueryParams) => dispatch => {
    dispatch(actions.startCall({callType:callTypes.listSites}))
    return MapRequests
        .fetchSitesMapNew(QueryParams)
        .then(res => {
            if(res && res.data && res.data.ok){
                dispatch(actions.sitesFetched({listSites:res.data,configs:{
                    QueryParams:QueryParams
                }}))
            }else{
                dispatch(actions.catchError({errors:"fail to load sites",callType:callTypes.listSites}))
            }
        })
        .catch(err => {
            dispatch(actions.catchError({errors:"fail to load sites",callType:callTypes.listSites}))
        });
};
export const fetchDetailShare = (idAccess,idSite) => dispatch => {
    dispatch(actions.startCall({callType:callTypes.detailShare}))
    return MapRequests
        .fetchDetailShare(idAccess,idSite)
        .then(res => {
            if(res && res.data && res.data.ok){
                dispatch(actions.detailFetched({detail:res.data.data}))
            }else{
                dispatch(actions.catchError({errors:"fail to load sites",callType:callTypes.detailShare}))
            }
        })
        .catch(err => {
            dispatch(actions.catchError({errors:"fail to load sites",callType:callTypes.detailShare}))
        });
};
export const fetchAllDepartemnts = (idAccess,idSite) => dispatch => {
    dispatch(actions.startCall({callType:callTypes.departementList}))
    return MapRequests
        .fetchAllDepartemnts(idAccess,idSite)
        .then(res => {
            if(res && res.data && res.data.ok){
                dispatch(actions.departementListed({detail:res.data.data}))
            }else{
                dispatch(actions.catchError({errors:"fail to load sites",callType:callTypes.departementList}))
            }
        })
        .catch(err => {
            dispatch(actions.catchError({errors:"fail to load sites",callType:callTypes.departementList}))
        });
};
