import React from "react";
import {
    Route,
    Switch,
    Redirect,
    Link
} from 'react-router-dom';
import { connect } from "react-redux";
import { Header } from '../Pages/HeaderTop'
import { Map, LoginPage, CheckUser, AccountPreview, UserSessions, Logout, UserProfile, SitesDetails, SharedDataList, ContactPage } from '../Pages'
import { Alert } from 'react-bootstrap'
import { SageUserProfile } from "../Pages/UserAccount/SageUserProfile";
// import { Alert } from 'react-bootstrap'
function Routes(props) {
    return (
        <>
            {props.authToken ?
                (props.authToken && props.user && typeof props.user === "object" && props.location.pathname != '/contact') ?
                    <div className="w-100 h-100 d-flex flex-column" >
                        <Header {...props} />
                        <div className="w-100 d-flex position-relative" style={{ height: "calc(100% - 50px)", marginTop:62 }}>
                            <Switch>
                                <Route path="/site/:idsite/:idacesss" component={SitesDetails} />
                                <Route exact path="/account" component={SageUserProfile} />
                                {/* <Route path="/account/sessions" component={UserSessions} /> */}
                                {/* <Route path="/account/profile" component={UserProfile} /> */}
                                {/* <Route path="/account/profile" component={SageUserProfile} /> */}
                                {/* <Route path="/account/shared" component={SharedDataList} /> */}
                                {/* <Route path="/account">
                                    {(routeProps) => <AccountPreview {...routeProps} user={props.user} />}
                                </Route> */}
                                <Route path="/contact" component={ContactPage} />
                                <Route path="/logout" component={Logout} />
                                <Route path="/" exact={true} component={Map} />
                                <Redirect from="/account/*" to="/account" />
                                <Redirect from="*" to="/" />
                            </Switch>
                        </div>
                        {props.user.init_password &&
                            <div className="fixed-bottom" id="alert_password">
                                <Alert variant="warning" className="mb-0 py-3 rounded-0 shadow">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span className="fw-boldest fs-5">{global._trans("auth", "actionRequired")} : <strong className="fs-6">{global._trans("auth", "messageSec")}</strong></span>
                                        <Link to="/account/profile/password" className="btn btn-sm fs-6 btn-primary shadow">
                                            {global._trans('auth', 'changePassword')}
                                        </Link>
                                    </div>
                                </Alert>
                            </div>}
                    </div> :
                    <Switch>
                        <Route path="/contact" component={ContactPage} />
                        <Route path="/" component={CheckUser} />
                    </Switch>
                :
                <Switch>
                    <Route path="/login" component={LoginPage} />
                    <Route path="/contact" component={ContactPage} />
                    <Redirect from="*" to="/login" />
                </Switch>}
        </>
    )
}
function mapState(state) {
    const { user, authToken } = state.auth
    return { user, authToken }
}
const connectedClass = connect(mapState, {})(Routes);
export { connectedClass as Routes }