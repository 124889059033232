import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updatePassword } from '../../../../modules/Auth/auth.crud'
import { actions } from '../../../../modules/Auth/auth.reducer'
import { Spinner } from 'react-bootstrap'
export const PasswordInfo = (props) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({ password: "", cPassword: "", currentPassword: "" })
    const [errors, setErrors] = useState({})
    const [loadingCreate, setLoading] = useState(null)
    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(0);
        updatePassword(state)
            .then(res => {
                if (res && res.data && res.data.ok && res.data.data === "Password updated") {
                    setLoading(1);
                    dispatch(actions.setUser({ ...props.user, init_password: null }))
                } else {
                    setLoading(2);
                }
            })
            .catch(({ message, errors }) => {
                setLoading(2)
                setErrors(errors)
            })
    }
    return (
        <div className="px-3 position-relative">
            {(loadingCreate === 0) && <div className="position-absolute t-0 l-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: 10, background: "#ffffff40" }}>
                <Spinner animation="border" variant="primary" />
            </div>}
            <div className="py-3">
                <div className="align-items-start flex-column mb-1 py-2">
                    <h3 className="fw-bolder text-dark m-0">Modifier votre mot de passe</h3>
                </div>
            </div>
            <div className="separator separator-solid"></div>
            <div className="form mt-5 " style={{ width: "60%" }}>
                {(loadingCreate === 1) && <div className="alert alert-success" role="alert">
                    <span className="fs-4 fw-bolder">Mot de passe modifié avec succès</span>
                </div>}
                <form method="post" onSubmit={handleSubmit}>
                    <div className="row mb-8">
                        <div className="form-group col">
                            <label className="fs-4 fw-bold mb-2">{global._trans("auth", "currentPassword")}</label>
                            <input className="form-control form-control-lg form-control-solid" type="password" value={state.currentPassword} onChange={handleChange} name="currentPassword" placeholder={global._trans("auth", "currentPassword")} />
                            {(loadingCreate === 2 && errors) && <span className="form-text fs-6 ps-2 d-block text-danger">{errors.currentPassword}</span>}
                        </div>
                    </div>
                    <div className="row mb-8">
                        <div className="form-group col">
                            <label className="fs-4 fw-bold mb-2">{global._trans("auth", "newPassword")}</label>
                            <input className="form-control form-control-lg form-control-solid" type="password" value={state.password} onChange={handleChange} name="password" placeholder={global._trans("auth", "newPassword")} />
                            {(loadingCreate === 2 && errors) && <span className="form-text fs-6 ps-2 d-block text-danger">{errors.password}</span>}
                        </div>
                    </div>
                    <div className="row mb-8">
                        <div className="form-group col">
                            <label className="fs-4 fw-bold mb-2">{global._trans("auth", "confirmPassword")}</label>
                            <input className="form-control form-control-lg form-control-solid" type="password" value={state.cPassword} onChange={handleChange} name="cPassword" placeholder={global._trans("auth", "confirmPassword")} />
                            {(loadingCreate === 2 && errors) && <span className="form-text fs-6 ps-2 d-block text-danger">{errors.cPassword}</span>}
                        </div>
                    </div>
                    <div className="d-flex justify-content-start mb-5">
                        <button className="btn btn-primary fw-bold">Enregistrer</button>
                    </div>
                </form>
            </div>
        </div>
    )
}