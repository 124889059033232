import React, { Component } from 'react'
import { ItemCard } from './component'
import { UserAccount, UserGroupe, Map, Logout, List } from '../../../assets/icons'
class AccountPreview extends Component {
    render() {
        console.log(this.props)
        return (
            <div className="h-100 mx-auto mt-10" style={{ width: "49%" }}>
                <div className="">
                    <h1 className="fw-bolder text-dark mb-0">Compte</h1>
                </div>
                <div className="my-4">
                    <div className="row">
                        <ItemCard icon={UserAccount} action="/account/profile" title={`Infos personnelles`} description={`Fournissez des renseignements personnels et indiquez comment nous pouvons vous joindre`} />
                        {this.props.user.typeuser === "UserPremieume" && <ItemCard icon={UserGroupe} action="/account/sessions" title={`Votre sessions`} description={`Gérer votre team et créer des session utilisateur pour permet de utiliser les donner partager par plusieurs utilisateur`} />}
                    </div>
                    <div className="row">
                        <ItemCard icon={Map} action="/" title={`Map sites`} description={`Accéder aux carte de la France pour pouvoir visualiser les diffèrent site vous disposez`} />
                        <ItemCard icon={List} action="/account/shared" title={`Données partagées`} description={`Consultez la liste des données partagées et non partagées classées par catégorie de site.`} />
                    </div>
                    <div className="row">
                        <ItemCard icon={Logout} action="/logout" title={`Déconnexion`} description={`Quittez votre session en cours et vous pourrez vous connecter à tout moment`} />
                    </div>
                </div>
            </div>
        )
    }
}
export { AccountPreview }